(function () {
  customerTransactions.$inject = ["ADMIN_URL"];
  angular.module('userApp').directive('customerTransactions', customerTransactions);

  function customerTransactions(ADMIN_URL) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/customer/customer-transactions.html',
      scope: {
        transactions: '='
      },
      link: function link(scope, elem, attrs) {
        scope.currencySettings = smooth.currencySettings;

        scope.viewItems = function ($index) {
          $('.items-container-' + $index).slideToggle();
        };
      }
    };
  }
})();