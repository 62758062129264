var UUIDjs = require('uuid-js'),
    moment = require('moment-timezone'),
    datetimepicker = require('eonasdan-bootstrap-datetimepicker'); // TODO: replace with bootstrap-datepicker


(function () {
  datePicker.$inject = ["ADMIN_URL", "$timeout", "$rootScope"];
  angular.module('userApp').directive('datePicker', datePicker);

  function datePicker(ADMIN_URL, $timeout, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/date-picker.html',
      scope: {
        daysAvailable: '=?',
        dateChanged: '=',
        setDate: '=?',
        index: '=?'
      },
      link: function link(scope, elem, attrs) {
        scope.$watch('setDate', function () {
          if (!scope.setDate) {
            $('#' + scope.calendarID).data('DateTimePicker') && $('#' + scope.calendarID).data('DateTimePicker').clear();
          }
        });

        var init = function init() {
          var locale = $rootScope.calendarSettings && $rootScope.calendarSettings.locale || 'en_us';
          scope.calendarID = UUIDjs.create(4).toString();
          var timeZone = $rootScope.userInfo && $rootScope.userInfo.timezone ? $rootScope.userInfo.timezone : 'Europe/London';
          $timeout(function () {
            var options = {
              format: 'MMMM Do YYYY',
              focusOnShow: false,
              useCurrent: false,
              locale: moment.locale(locale),
              widgetPositioning: {
                vertical: 'bottom'
              },
              timeZone: timeZone
            };
            if (scope.setDate) options.date = scope.setDate;

            if (typeof scope.daysAvailable !== 'undefined') {
              options.enabledDates = [];
            }

            $('#' + scope.calendarID).datetimepicker(options);
            $('#' + scope.calendarID).on('dp.change', function (event) {
              event.preventDefault();
              var date = !!event.date && event.date.format('YYYY-MM-DD');
              var index = scope.index || 0;
              scope.dateChanged(date, index);
              return false;
            });
          }, 0);
        }; // End init


        init();
        scope.$watch('daysAvailable', updatePickerDates);

        function updatePickerDates() {
          if (!scope.daysAvailable || !scope.daysAvailable.length) {
            return;
          }

          var momentDates = [];
          scope.daysAvailable.forEach(function (date) {
            momentDates.push(moment(date));
          }); // NB: $timeout is necessary

          $timeout(function () {
            $('.sb-datepicker').data("DateTimePicker").enabledDates(momentDates).minDate(moment(momentDates[0]).hours(0).minutes(0).seconds(0)).maxDate(moment(scope.daysAvailable[scope.daysAvailable.length - 1]));
          }, 0);
        }
      }
    };
  }
})();