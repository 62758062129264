(function () {
  appointmentInfo.$inject = ["ADMIN_URL", "$http"];
  angular.module('userApp').directive('appointmentInfo', appointmentInfo);

  function appointmentInfo(ADMIN_URL, $http) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/appointments/appointment-info.html',
      scope: {
        appointment: '=',
        bookingForm: '='
      },
      link: function link(scope, elem, attrs) {}
    };
  }
})();