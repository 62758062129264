(function () {
  FormsController.$inject = ["API_URL", "$http", "$scope", "$timeout", "$rootScope"];
  angular.module('userApp').controller('FormsController', FormsController);

  function FormsController(API_URL, $http, $scope, $timeout, $rootScope) {
    var form = this,
        orgID = $rootScope.userInfo.orgid;
    form.throbberControl = $scope.throbber;
    var _forms = {};

    form.changeBookingForm = function () {
      $rootScope.throbber.show();
      $http.get(API_URL + '/api/v1/settings/forms').then(function (response) {
        $rootScope.throbber.hide();
        var data = response.data;

        if (!data.success) {
          $rootScope.throbber.showError(data.msg);
          return;
        }

        _forms = data.forms;
        form.eugdpr = _forms.eugdpr;
        form.bookingForm = _forms.booking ? _forms.booking.default : {};
        form.registrationForm = _forms.registration;

        if (_forms.booking && _forms.booking[form.bookingService]) {
          form.bookingForm = _forms.booking[form.bookingService];
        } else {
          form.bookingForm = [];
        }
      }, function (error) {
        $scope.throbber.showError(error.statusMessage);
      });
    };

    form.init = function () {
      $scope.throbber.show();
      form.bookingService = 'default';
      $http.get(API_URL + '/api/v1/settings/forms').then(function (response) {
        var data = response.data;

        if (!data.success) {
          $scope.throbber.showError(data.msg);
          return;
        }

        _forms = data.forms;
        $scope.throbber.hide();
        form.gdprInit = _forms.eugdpr;
        form.bookingForm = _forms.booking ? _forms.booking.default : {};
        form.registrationForm = _forms.registration;
      }, function (error) {
        $scope.throbber.showError(error.statusMessage);
      }); // Need service list

      $http.get(API_URL + '/api/v1/services/' + orgID).then(function (response) {
        var data = response.data;

        if (!data.success) {
          $scope.throbber.showError(data.msg);
          return;
        }

        $scope.throbber.hide();
        form.services = data.services;
      }, function (error) {
        $scope.throbber.showError(error.statusMessage);
      });
    }; // End init
    // Make sure all directives are compiled before initialising.


    $timeout(function () {
      form.init();
    });

    form.submitEUGDPR = function () {
      $rootScope.throbber.show();
      $http.put(API_URL + '/api/v1/settings/forms/settings/' + _forms._id, {
        formupdate: {
          eugdpr: form.eugdpr
        }
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          return $rootScope.throbber.showError(response.msg);
        }

        _forms = response.form;
        form.eugdpr = _forms.eugdpr;
        $rootScope.throbber.hide('EUGDPR updated');
      });
    };
  }
})();