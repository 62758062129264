buySubscriptionModal.$inject = ["ADMIN_URL"];
angular.module('userApp').directive('buySubscriptionModal', buySubscriptionModal);

function buySubscriptionModal(ADMIN_URL) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/subscriptions/buy-subscription-modal.html',
    scope: {
      showModal: '=',
      subscriptionTypes: '=',
      activationSuccess: '='
    },
    link: function link(scope, elem, attrs) {
      scope.showModal = function (chosenSubscription) {
        scope.chosenSubscription = scope.subscriptionTypes[chosenSubscription];
        scope.initComponent();
        $('.buy-subscription-modal').modal();
      };
    }
  };
}

;