angular.module('directive.durationControl', []).directive('durationControl', ["ADMIN_URL", "$timeout", function (ADMIN_URL, $timeout) {
  return {
    restrict: 'EA',
    scope: {
      totalseconds: '=',
      durationZero: '=' // Validation message triggered by external controller

    },
    templateUrl: '/templates/directives/duration-control.html',
    link: function link(scope, elem, attrs) {
      scope.durationhours = '0';
      scope.durationminutes = '0';
      var initialised = false;
      scope.$watch('totalseconds', function () {
        if (!initialised && scope.totalseconds) {
          init();
          initialised = true;
        }
      });

      var init = function init() {
        if (parseInt(scope.totalseconds) !== 0) {
          scope.durationhours = Math.floor(scope.totalseconds / 3600).toString();
          scope.durationminutes = (scope.totalseconds % 3600 / 60).toString();
        } else {
          scope.durationhours = '0';
          scope.durationminutes = '0';
        }
      };

      scope.userTyping = function (control) {
        if (control === 'hours' && scope.durationhours === '0') {
          scope.durationhours = '';
        } else if (control === 'minutes' && scope.durationminutes === '0') {
          scope.durationminutes = '';
        }
      };

      scope.validateDuration = function () {
        scope.durationhours = scope.durationhours || '';
        scope.durationminutes = scope.durationminutes || '';
        scope.durationhours = scope.durationhours.replace(/\D/g, '');
        scope.durationminutes = scope.durationminutes.replace(/\D/g, '');

        if (scope.durationhours.length > 2) {
          scope.durationhours = scope.durationhours.slice(0, 2);
        }

        if (scope.durationminutes.length > 2) {
          scope.durationminutes = scope.durationhours.slice(0, 2);
        }

        if (scope.durationminutes && parseInt(scope.durationminutes) > 59) {
          scope.durationInvalid = true;
          scope.durationminutes = '59';
          $timeout(function () {
            scope.durationInvalid = false;
          }, 3000);
          return;
        }

        if (scope.durationhours && parseInt(scope.durationhours) > 23) {
          scope.durationInvalid = true;
          scope.durationhours = '23';
          $timeout(function () {
            scope.durationInvalid = false;
          }, 3000);
          return;
        } // Everything valid. Calculate total duration minutes


        scope.totalseconds = (scope.durationhours ? parseInt(scope.durationhours) * 3600 : 0) + (scope.durationminutes ? parseInt(scope.durationminutes * 60) : 0);
      };
    }
  };
}]);