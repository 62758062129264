(function () {
  copyScheduleModal.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope"];
  angular.module('userApp').directive('copyScheduleModal', copyScheduleModal);

  function copyScheduleModal(ADMIN_URL, API_URL, $http, $q, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/scheduling/copy-schedule-modal.html',
      scope: {
        showCopyModal: '=',
        shifts: '=',
        resources: '=',
        resourceId: '@'
      },
      link: function link(scope, elem, attrs) {
        scope.showCopyModal = function () {
          scope.resourceShifts = [];
          scope.selectedShifts = [];
          scope.form = {};
          scope.resourceId = scope.resourceId && scope.resourceId !== '0' ? scope.resourceId : null; // Need the start and end times for the shift

          for (var day in scope.shifts) {
            for (var i = 0; i < scope.shifts[day].length; i++) {
              var newShift = angular.copy(scope.shifts[day][i]);
              var start = newShift.timeslots[0].start,
                  end = newShift.timeslots[newShift.timeslots.length - 1].end;
              newShift.niceStart = start;
              newShift.niceEnd = end;
              newShift.day = day;
              scope.resourceShifts.push(newShift);
            }
          } // Don't list the current resource in the resources dropdown


          scope.availableResources = [];
          scope.resources.forEach(function (resource) {
            if (resource._id !== scope.resourceId) {
              scope.availableResources.push(resource);
            }
          });
          scope.form.resourceid = '0';
          $('.copy-shift-modal').modal('show');
        };

        scope.copyShifts = function () {
          var resourceID = scope.form.resourceID === '0' ? null : scope.form.resourceID,
              promises = [];
          scope.selectedShifts.forEach(function (shift) {
            shift.days = [shift.day];
            shift.start = shift.niceStart;
            shift.end = shift.niceEnd;

            if (resourceID) {
              shift.resourceid = resourceID;
              shift.hasresource = true;
            } else {
              delete shift.resourceid;
              shift.hasresource = false;
            }

            shift.serviceid = shift.serviceid._id;
            delete shift._id;
            promises.push($http.post(API_URL + '/api/v1/shift', {
              newshift: shift
            }));
          });
          $rootScope.throbber.show();
          $q.all(promises).then(function (replies) {
            var success = true,
                message;
            replies.forEach(function (reply) {
              if (!reply.data.success) {
                success = false;
                message = reply.data.msg;
              }
            });

            if (!success) {
              $rootScope.throbber.showError(message);
              return;
            }

            $('.copy-shift-modal').modal('hide');
            $rootScope.throbber.hide('Shifts copied successfully');
          }, function (error) {
            $rootScope.throbber.showError(error.errorMessage);
          });
        };

        scope.selectCopyShift = function ($index, shift) {
          scope.resourceShifts[$index].selected = true;
          scope.selectedShifts.push(shift);
        };

        scope.unselectCopyShift = function ($index, shiftID) {
          scope.resourceShifts[$index].selected = false;

          for (var i = 0; i < scope.selectedShifts.length; i++) {
            if (scope.selectedShifts[i]._id == shiftID) {
              scope.selectedShifts.splice(i, 1);
              break;
            }
          }
        };
      }
    };
  }
})();