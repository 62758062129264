(function () {
  addFormUnit.$inject = ["API_URL", "$http", "$rootScope"];
  angular.module('directive.addFormUnit', []).directive('addFormUnit', addFormUnit);

  function addFormUnit(API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        form: '=',
        formUrl: '@',
        addingUnit: "="
      },
      templateUrl: '/templates/directives/add-form-unit.html',
      link: function link(scope, elem, attrs) {
        scope.field = {};
        scope.field.type = 'text';

        scope.saveField = function () {
          $rootScope.throbber.show();
          var newForm = scope.form.length ? angular.copy(scope.form) : [],
              newField = scope.field;
          newForm.push(newField);
          $http.put(API_URL + '/api/v1/settings/forms' + scope.formUrl, {
            form: newForm
          }).then(function (response) {
            var data = response.data;

            if (!data.success) {
              $rootScope.throbber.showError(data.msg);
              return;
            }

            scope.form = data.form;
            scope.field.label = '';
            scope.field.type = 'text';
            scope.field.required = false;
            $rootScope.throbber.hide();
            scope.cancel();
          }, function (error) {
            $rootScope.throbber.showError(error.statusMessage);
          });
        };

        scope.cancel = function () {
          scope.addingUnit = false;
          scope.field = {};
          scope.field.type = 'text';
        };
      }
    };
  }
})();