(function () {
  customerSearch.$inject = ["ADMIN_URL", "$q"];
  angular.module('userApp').directive('customerSearch', customerSearch);

  function customerSearch(ADMIN_URL, $q) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/customer/customer-search.html',
      scope: {
        customers: '=',
        getCustomers: '='
      },
      link: function link(scope, elem, attrs) {
        scope.filters = {};
        scope.paginationObject = {
          perpage: 10
        };

        scope.doSearch = function (page) {
          var params = {
            getappointments: true,
            page: page || 0
          };
          var deferred = $q.defer();

          if (scope.filters.search) {
            params.firstname = scope.filters.search;
            params.lastname = scope.filters.search;
            params.email = scope.filters.search;
          }

          if (scope.sortBy) {
            params.sortby = scope.sortBy;
            params.direction = scope.direction;
          }

          scope.getCustomers(params).then(function (paginationObject) {
            scope.paginationObject = $.extend(scope.paginationObject, paginationObject);
            deferred.resolve(scope.paginationObject);
          });
          return deferred.promise;
        };

        scope.doSearch();

        scope.doSortBy = function (sortItem) {
          scope.sortBy = sortItem;

          if (scope.direction) {
            scope.direction = scope.direction == 'asc' ? 'desc' : 'asc';
          } else {
            scope.direction = 'desc';
          }

          scope.doSearch();
        };
      }
    };
  }
})();