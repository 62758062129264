var newWindow = function newWindow() {
  var win = null;

  smooth.newWindow = function (type, windowUrl, title, width, height) {
    smooth.windowType = type;
    var title = title || '';
    var width = width || parseInt(screen.width * 0.4),
        height = height || parseInt(screen.height * 0.8);
    var winl = parseInt((screen.width - width) / 2),
        wint = parseInt((screen.height - height) / 2);
    if (winl < 0) winl = 0;
    if (wint < 0) wint = 0;
    var settings = 'height=' + height + ',';
    settings += 'width=' + width + ',';
    settings += 'top=' + wint + ',';
    settings += 'left=' + winl + ',';
    settings += 'resizable,scrollbars';
    win = window.open(windowUrl, title, settings);
    win.window.focus();
    var interval = window.setInterval(function () {
      try {
        if (win == null || win.closed) {
          window.clearInterval(interval); // Need to trigger event in Angular from here

          smooth.miniBrowserClosed();
        }
      } catch (e) {}
    }, 1000);
    /*win.onbeforeunload = function () {
          if (smooth.windowtype === 'mailchimp') {
            window.getMailchimpLists();
        } else {
            window.handleGoogleResult()
        }
      }*/
  };
  /*smooth.newWindow = function () {
        var w = screen.width * 0.4,
              h = screen.height * 0.6;
        var winl = (screen.width - w) / 2;
      var wint = (screen.height - h) / 2;
        if (winl < 0) winl = 0;
      if (wint < 0) wint = 0;
        var settings = 'height=' + h + ',';
      settings += 'width=' + w + ',';
      settings += 'top=' + wint + ',';
      settings += 'left=' + winl + ',';
      settings += 'resizable,scrollbars,status,toolbar';
      win = window.open(AUTHORIZE_URI, '', settings);
      win.window.focus();
        var interval = window.setInterval(function () {
            try {
                if (win == null || win.closed) {
                  window.clearInterval(interval);
                    // Need to trigger event in Angular from here
                  smooth.miniBrowserClosed();
                }
          }
          catch (e) {
          }
      }, 1000);
    }*/

};

module.exports = newWindow;