upcomingClasses.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "$timeout", "$sce", "$location"];
angular.module('userApp').directive('upcomingClasses', upcomingClasses);

function upcomingClasses(ADMIN_URL, API_URL, $http, $q, $rootScope, $timeout, $sce, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/appointments/upcoming-classes.html',
    scope: {},
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber,
          userInfo = $rootScope.userInfo;
      var initialised = false;

      scope.init = function () {
        initialised = true;
        scope.filters = {
          serviceid: '0',
          resourceid: '0'
        };
        scope.paginationObject = {
          page: 0,
          perpage: '10'
        };
        var orgid = userInfo.orgid._id ? userInfo.orgid._id : userInfo.orgid;
        scope.isOwner = userInfo.roles.find(function (role) {
          return role.type === 'OWNER';
        });
        scope.isAgent = userInfo.roles.find(function (role) {
          return role.type === 'AGENT';
        });
        scope.isResource = userInfo.roles.find(function (role) {
          return role.type === 'RESOURCE';
        });
        var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get("".concat(API_URL, "/api/v1/services/").concat(orgid)), $http.get(API_URL + '/api/v1/customer')];
        $q.all(promises).then(function (replies) {
          scope.resources = replies[0].data.resources;
          scope.services = replies[1].data.services;
          scope.customers = replies[2].data.customers;
          scope.getClasses();
        }).catch(function (err) {
          console.error(err.statusText);
        });
      };

      $rootScope.$watch('userInfo', function () {
        if ($rootScope.userInfo && !initialised) scope.init();
      });
      scope.init();

      scope.getClasses = function () {
        var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var params = {};
        params.page = typeof page !== 'undefined' ? parseInt(page) : scope.paginationObject.page;
        params.perpage = scope.paginationObject.perpage || 10;

        if (scope.filters.serviceid !== '0') {
          params.serviceid = scope.filters.serviceid;
        }

        if (scope.filters.resourceid !== '0') {
          params.resourceid = scope.filters.resourceid;
        }

        if (scope.emailTo && scope.emailTo.length) {
          params.emailto = scope.emailTo;
        }

        params.startdate = scope.filters.startdate || moment().hour(0).minute(0).format('YYYY-MM-DD');
        throbber.show();
        var deferred = $q.defer();
        var options = {
          params: params
        };

        if (scope.topdf) {
          $http.defaults.headers.common['content-type'] = 'application/pdf';
          options.responseType = 'arraybuffer';
        }

        $http.get(API_URL + '/api/v1/class', options).then(function (_ref) {
          var response = _ref.data;

          if (scope.topdf) {
            throbber.hide();
            var file = new Blob([data], {
              type: 'application/pdf'
            }),
                fileURL = URL.createObjectURL(file);
            deferred.resolve(fileURL);
            scope.topdf = false;
          } else {
            throbber.hide();

            if (!response.success) {
              throbber.showError('Sorry but there was an error with this request: ' + response.msg);
              return;
            }

            scope.paginationObject.count = parseInt(response.count);
            scope.paginationObject.page = parseInt(response.page);
            scope.paginationObject.perpage = params.perpage;
            scope.classes = response.classes;
            deferred.resolve();
          }
        });
        return deferred.promise;
      };

      scope.changeItemsToShow = function () {
        // Always start at the first page if this changes.
        scope.getClasses(0);
      };

      scope.emailAppointments = function () {
        scope.sendingEmail = true;
        scope.getClasses().then(function (response) {
          scope.sendingEmail = false;
          scope.emailSuccess = true;
          scope.emailTo = '';
          $timeout(function () {
            scope.emailSuccess = false;
          }, 2000);
        });
      };

      scope.printAppointments = function () {
        window.print();
      };

      scope.getPDF = function () {
        scope.topdf = true;
        scope.getClasses().then(function (response) {
          window.open(response);
        });
      };

      scope.dateFromChanged = function (date) {
        scope.filters.startdate = date;
        scope.getClasses();
      };

      scope.removeDateFilter = function () {
        delete scope.filters.startdate;
        scope.getClasses();
      };

      scope.removeFilter = function (item) {
        scope.filters[item] = '0';
        scope.getClasses();
      };

      scope.toggleStudents = function ($event, classID) {
        $event.preventDefault();
        $('.' + classID + ' > td').toggleClass('toggled');
      };
    }
  };
}