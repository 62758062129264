(function () {
  comentManager.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
  angular.module('userApp').directive('commentManager', comentManager);

  function comentManager(ADMIN_URL, API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/customer/comment-manager.html',
      scope: {
        comments: '=',
        entity: '@',
        entityId: '@'
      },
      link: function link(scope, elem, attrs) {
        scope.editingComment = false;

        scope.addComment = function () {
          var newComment = {
            commentBy: $rootScope.userInfo.firstname + ' ' + $rootScope.userInfo.lastname,
            time: moment().toDate(),
            text: ''
          };
          scope.comments.push(newComment);
          scope.comments[scope.comments.length - 1].editing = true;
          scope.editingComment = true;
          $('.comment-list').animate({
            scrollTop: $('.comment-list').prop("scrollHeight")
          }, 1000);
        };

        scope.editComment = function ($index, edit) {
          scope.comments[$index].editing = edit;
          scope.editingComment = edit;
        };

        scope.saveComment = function () {
          $rootScope.throbber.show();

          for (var i = 0; i < scope.comments.length; i++) {
            delete scope.comments[i].editing;
          }

          var params = {};
          params[scope.entity] = {
            comments: scope.comments
          };
          $http.put(API_URL + '/api/v1/' + scope.entity + '/' + scope.entityId, params).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
              return;
            }

            scope.comments = response[scope.entity].comments;
            scope.editingComment = false;
          });
        };

        scope.deleteComment = function ($index) {
          if (!confirm('Are you sure you want to delete this comment?')) {
            return;
          }

          scope.comments.splice($index, 1);
          $rootScope.throbber.show();
          var params = {};
          params[scope.entity] = {
            comments: scope.comments
          };
          $http.put(API_URL + '/api/v1/' + scope.entity + '/' + scope.entityId, params).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
              return;
            }

            scope.comments = response[scope.entity].comments;
            scope.editingComment = false;
          });
        };
      }
    };
  }
})();