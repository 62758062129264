import _objectSpread from "@babel/runtime/helpers/objectSpread";

// Google recaptcha
var getRecaptchaToken = function getRecaptchaToken() {
  return new Promise(function (resolve, reject) {
    grecaptcha.execute('6Ldq-K8UAAAAAJtC6lVsC9Rc-FJXd8UNDphcYtav', {
      action: 'homepage'
    }).then(function (token) {
      resolve(token);
    });
  });
};

angular.module('userApp').controller('OauthLoginController', ["AuthService", "$rootScope", "$window", "$timeout", "$location", "$http", "API_URL", function (AuthService, $rootScope, $window, $timeout, $location, $http, API_URL) {
  var log = this,
      queryParams = $location.search();
  var client_id = queryParams.client_id,
      partner_id = queryParams.partner_id,
      redirect_url = queryParams.redirect_url;
  log.paramsValid = false;

  if (!client_id || !redirect_url || !partner_id) {
    return log.errorMessage = 'client_id, partner_id and redirect_url query parameters are required.';
  }

  if (redirect_url === 'demo') {
    log.demoMessage = ['Starting oauth user flow in demo (unsecure) mode...'];
  }

  log.integration = _objectSpread({}, queryParams);
  log.paramsValid = true;
  log.user = {};

  log.userLogin = function () {
    log.retryPassword = false;
    getRecaptchaToken().then(function (captchatoken) {
      AuthService.oauthAuthenticate(log.user, captchatoken, partner_id).then(function (response) {
        if (!response.success) {
          log.errorMessage = response.msg;
          $timeout(function () {
            log.errorMessage = '';
          }, 4000);
          return;
        } // Success.


        var access_token = response.access_token; // We're going to demo how to get a bearer token

        if (redirect_url === 'demo') {
          log.demoMessage.push("login ok.");
          log.demoMessage.push("access_token ".concat(response.access_token, " received."));
          log.demoMessage.push('retrieving bearer token with request:');
          var requestUri = "".concat(API_URL, "/oauth2/token?access_token=").concat(access_token, "&client_secret=fa59280981232915c4cda777f241cdcc4edf6070ff2b0b6601&redirect_url=demo");
          log.demoMessage.push(requestUri);
          $http.get(requestUri).then(function (response) {
            log.demoMessage.push("Request ok.");
            log.demoMessage.push("Received bearer token ".concat(response.data.bearer_token));
          }).catch(function (err) {
            log.errorMessage = "Sorry but an error occurred: ".concat(err.message, ". Please try this request again.");
            $timeout(function () {
              log.errorMessage = '';
            }, 3000);
          });
          return;
        }

        log.loginSuccess = true;
        $timeout(function () {
          log.loginSuccess = false;
          $window.location.href = "".concat(redirect_url, "?access_token=").concat(access_token).concat(partner_id ? '&partner_id=' + partner_id : '');
        }, 2000);
      }).catch(function (err) {
        log.errorMessage = err.message;
        $timeout(function () {
          log.errorMessage = '';
        }, 4000);
      });
    });
  };
  /*function post(path, params, method) {
      method = method || "post"; // Set method to post by default if not specified.
        // The rest of this code assumes you are not using a library.
      // It can be made less wordy if you use one.
      var form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("action", path);
        for(var key in params) {
          if(params.hasOwnProperty(key)) {
              var hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", key);
              hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
          }
      }
        document.body.appendChild(form);
      form.submit();
  }*/

}]);