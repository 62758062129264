(function () {
  timeslotContainer.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope"];
  angular.module('userApp').directive('timeslotContainer', timeslotContainer);

  function timeslotContainer(ADMIN_URL, API_URL, $http, $q, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        shift: '=',
        controller: '=controllerScope',
        // Parent controller scope
        timeslot: '=',
        apptsToMove: '=',
        addBooking: '=',
        viewAppointments: '=',
        showWaitlist: '='
      },
      templateUrl: ADMIN_URL + '/templates/directives/timeslot-container.html',
      link: function link(scope, elem, attrs) {
        var _shift = scope.shift;
        scope.calendarSettings = scope.controller.settings.calendarSettings;

        scope.makeBooking = function (timeslot) {
          var appointment = {
            resourceid: _shift.resourceid,
            serviceid: _shift.serviceid,
            service: _shift.service,
            resource: _shift.resource,
            shiftid: _shift._id,
            isworkshop: _shift.isworkshop,
            start: timeslot.start,
            end: timeslot.end
          }; // scope.addBooking is defined in the addAppointment directive

          scope.addBooking(appointment, _shift);
        };

        scope.moveAppointments = function () {
          if (!scope.apptsToMove && scope.apptsToMove.length) {
            return;
          }

          var oldAppointments = scope.apptsToMove.slice(); // resourceid needs to be populated

          var promises = [],
              newAppointment = {
            serviceid: _shift.serviceid,
            service: _shift.service,
            resourceid: _shift.resourceid,
            shiftid: _shift._id,
            hasresource: _shift.hasresource,
            start: scope.timeslot.start,
            end: scope.timeslot.end
          }; // Populate resourceid

          if (newAppointment.hasresource) {
            newAppointment.resourceid = scope.controller.resources.find(function (resource) {
              return resource._id === _shift.resourceid;
            });
          }

          var googleAppointments = [newAppointment]; // Update each of the appointments and create a promise for the update

          for (var i = 0; i < oldAppointments.length; i++) {
            newAppointment.customerid = oldAppointments[i].customerid._id;
            var oldAppointment = oldAppointments[i],
                appointmentID = oldAppointments[i]._id; // serviceid's must be populated

            if (!(oldAppointment.serviceid && oldAppointment.serviceid._id)) {
              oldAppointment.service = scope.controller.services.find(function (service) {
                return service._id === oldAppointment.serviceid;
              });
            } // Populate resourceid


            if (oldAppointment.hasresource) {
              oldAppointment.resourceid = scope.controller.resources.find(function (resource) {
                return resource._id === oldAppointment.resourceid;
              });
            } // Queue the api calls to actually move the appointments


            promises.push($http.put(API_URL + '/api/v1/appointments/manage/move/' + appointmentID, {
              newAppointment: newAppointment
            })); // Now queue the call to update google calendar - only add unique appointments

            var found = !!googleAppointments.find(function (appt) {
              if (appt.start === oldAppointment.start && appt.end === oldAppointment.end && appt.serviceid === oldAppointment.serviceid && appt.resourceid === oldAppointment.resourceid) {
                return oldAppointment;
              }
            });

            if (!found) {
              googleAppointments.push(oldAppointment);
            }
          }

          $q.all(promises).then(function (results) {
            var success = true;
            results.forEach(function (reply) {
              var response = reply.data;

              if (!response.success) {
                success = false;
              }
            });

            if (!success) {
              alert('Sorry but there was an error with this request. Please try again or contact support@smoothbook.co for help');
              return;
            } // Now update google calendar. The api will figure out whether this is configured or not.


            $http.put(API_URL + '/api/v1/appointments/manage/updategoogle', {
              appointments: googleAppointments
            }).then(function (reply) {});
            scope.apptsToMove = null;
            $rootScope.movingAppointments = null;
            scope.controller.movingAppts = [];
            scope.controller.init();
          }).catch(function (error) {
            $rootScope.throbber.showError("We're very sorry but an error occurred with this request. Please contact support@smoothbook.co if this problem persists.");
          });
        };
      }
    };
  }
})();