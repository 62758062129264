(function () {
  EditUserController.$inject = ["API_URL", "$http", "$q", "$rootScope", "$routeParams", "$timeout"];
  AddUserController.$inject = ["API_URL", "$http", "$rootScope", "$timeout", "$location"];
  angular.module('userApp').controller('AddUserController', AddUserController).controller('EditUserController', EditUserController);

  function AddUserController(API_URL, $http, $rootScope, $timeout, $location) {
    var user = this,
        throbber = $rootScope.throbber;

    user.init = function () {
      user.addingUser = true;
      user.roles = smooth.roles;
      throbber.show();
      $http.get(API_URL + '/api/v1/resource').then(function (reply) {
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          return;
        }

        user.resources = response.resources;
        $timeout(function () {
          $('.choose-resource').select2();
        }, 0);
      });
    };

    user.init();

    user.saveUser = function () {
      if (!user.selectedRoles) {
        user.errorMessage = 'Please select at least one role for the user';
        $timeout(function () {
          user.errorMessage = '';
        }, 2000);
        return;
      }

      throbber.show();
      delete user.form._id;
      user.form.roles = [];

      for (var i = 0; i < user.roles.length; i++) {
        if (user.selectedRoles === user.roles[i].type) {
          user.form.roles.push(user.roles[i]);
        }
      }

      $http.post(API_URL + '/api/v1/user', {
        userinfo: user.form
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          return;
        }

        throbber.hide('User created successfully');
        $timeout(function () {
          $location.path('/settings/users');
        }, 2000);
      });
    };
  }

  function EditUserController(API_URL, $http, $q, $rootScope, $routeParams, $timeout) {
    var user = this,
        userID = $routeParams.userid,
        throbber = $rootScope.throbber;
    user.form = {};

    user.init = function () {
      throbber.show();
      user.roles = smooth.roles;
      var promises = [$http.get(API_URL + '/api/v1/user/' + userID), $http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/user/')];
      $q.all(promises).then(function (replies) {
        throbber.hide();
        var success = true,
            message = '';
        replies.forEach(function (reply) {
          var response = reply.data;

          if (!response.success) {
            message = reply.data.msg;
            success = false;
          }
        });

        if (!success) {
          throbber.showError('Sorry but there was an error with this request: ' + message);
          return;
        }

        user.form = replies[0].data.user;
        user.resources = replies[1].data.resources;
        user.users = replies[2].data.users;
        $timeout(function () {
          $('.choose-resource').select2();
        }, 0);
        user.selectedRoles = [];

        for (var i = 0; i < user.roles.length; i++) {
          if (user.form.roles[0].type === user.roles[i].type) {
            user.selectedRoles = user.form.roles[0].type;
          }
        }
      });
    };

    user.init();

    user.saveUser = function () {
      if (!user.selectedRoles) {
        user.errorMessage = 'Please select at least one role for the user';
        $timeout(function () {
          user.errorMessage = '';
        }, 2000);
        return;
      }

      throbber.show();
      delete user.form._id;
      user.form.roles = [];
      var selectedOwner = false;

      for (var i = 0; i < user.roles.length; i++) {
        if (user.selectedRoles === user.roles[i].type) {
          if (user.roles[i].type === 'OWNER') {
            selectedOwner = true;
          }

          user.form.roles.push(user.roles[i]);
        }
      }

      var owners = user.users.filter(function (thisUser) {
        for (var i = 0; i < thisUser.roles.length; i++) {
          return thisUser.roles[i].type === 'OWNER';
        }
      }); // if (owners.length <= 1 && user.form.roles[0].type !== 'OWNER' ){
      //     throbber.showError('Your organisation must have at least one owner. You cannot delete the last owner');
      //     return;
      // }

      $http.put(API_URL + '/api/v1/user/' + userID, {
        userinfo: user.form
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          return;
        }

        throbber.hide('User updated successfully');
        user.form = response.user;
        user.selectedRoles = [];

        for (var i = 0; i < user.roles.length; i++) {
          if (user.form.roles[0].type === user.roles[i].type) {
            user.selectedRoles = user.form.roles[0].type;
          }
        }
      });
    };

    user.changeRoles = function () {};
  }
})();