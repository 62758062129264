(function () {
  CustomersController.$inject = ["$q", "$http", "API_URL", "$rootScope"];
  angular.module('userApp').controller('CustomersController', CustomersController);

  function CustomersController($q, $http, API_URL, $rootScope) {
    var cust = this,
        throbber = $rootScope.throbber;

    cust.getCustomers = function (params) {
      var params = params || {
        getappointments: true
      };
      cust.paginationObject = {
        count: 0,
        page: 0
      };
      params.page = params.page || 0;
      params.perpage = params.perpage || 10;
      throbber.show();
      var deferred = $q.defer();
      $http.get(API_URL + '/api/v1/customer', {
        params: params
      }).then(function (reply) {
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          deferred.resolve(cust.paginationObject);
          return;
        }

        cust.customers = response.customers;
        cust.paginationObject.count = parseInt(response.count);
        cust.paginationObject.page = parseInt(response.page);
        deferred.resolve(cust.paginationObject);
      });
      return deferred.promise;
    };

    cust.customerSaved = function () {
      // Customer saved through directive. Close modal and update list.
      cust.getCustomers();
      $('.add-customer-modal').modal('hide');
    };

    cust.deleteCustomer = function (customerID) {
      if (!confirm('Please confirm that you would like to delete this customer. ' + 'This action will delete all information, appointments, credits and credit history for this customer and cannot be undone!')) {
        return;
      }

      $http.delete(API_URL + '/api/v1/customer/' + customerID).then(function (response) {
        cust.init();
      });
    };
  }
})();