(function () {
  couponManager.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout"];
  angular.module('userApp').directive('couponManager', couponManager);

  function couponManager(ADMIN_URL, API_URL, $http, $rootScope, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/settings/coupon-manager.html',
      scope: {
        package: '='
      },
      link: function link(scope, elem, attrs) {
        scope.couponusestype = "unlimited";
        scope.numCoupons = '1';
        scope.useNumbers = 1;
        scope.couponexpirytype = 'none';

        scope.saveCoupons = function () {
          var newCodes = scope.couponList.split(','),
              newCoupons = JSON.parse(JSON.stringify(scope.package.coupons)); // Copy existing coupons

          newCodes.forEach(function (code) {
            var coupon = {
              code: code,
              uses: scope.couponusestype == 'limited' ? scope.couponUses : null,
              used: 0,
              expiry: scope.couponexpirytype == 'date' ? scope.couponExpiry : null
            };
            newCoupons.push(coupon);
          });
          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/package/' + scope.package._id, {
            package: {
              coupons: newCoupons
            }
          }).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but there was an error with this query: ' + response.msg);
              return;
            }

            scope.package = response.package;
          });
        };

        scope.deleteAll = function () {
          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/package/' + scope.package._id, {
            package: {
              coupons: []
            }
          }).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but there was an error with this query: ' + response.msg);
              return;
            }

            scope.package = response.package;
          });
        };

        scope.generateCoupons = function () {
          scope.couponList = '';

          for (var i = 0; i < scope.numCoupons; i++) {
            var code = randomString(6, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
            scope.couponList += code;

            if (i < scope.numCoupons - 1) {
              scope.couponList += ', ';
            }
          }
        };

        scope.deleteCoupon = function ($index) {
          var coupons = JSON.parse(JSON.stringify(scope.package.coupons));
          coupons.splice($index, 1);
          $http.put(API_URL + '/api/v1/package/' + scope.package._id, {
            package: {
              coupons: coupons
            }
          }).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but there was an error with this query: ' + response.msg);
              return;
            }

            scope.package = response.package;
          });
        };

        scope.launchCouponModal = function () {
          $('.coupon-modal', elem).modal('show');
        };

        function randomString(length, chars) {
          var result = '';

          for (var i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
          }

          return result;
        }

        function initPicker() {
          $timeout(function () {
            var options = {
              format: 'MMMM Do YYYY',
              useCurrent: false,
              minDate: moment()
            };
            $('.sb-coupon-datepicker').datetimepicker(options);
            $('.sb-coupon-datepicker').on('dp.change', function (event) {
              event.preventDefault();
              scope.couponExpiry = event.date.format('YYYY-MM-DD');
              return false;
            });
          }, 0);
        }

        initPicker();
      }
    };
  }
})();