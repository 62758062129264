upcomingAppointments.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "$timeout", "$sce", "$location"];
angular.module('userApp').directive('upcomingAppointments', upcomingAppointments);

function upcomingAppointments(ADMIN_URL, API_URL, $http, $q, $rootScope, $timeout, $sce, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/appointments/upcoming-appointments.html',
    scope: {},
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber,
          userInfo = $rootScope.userInfo;
      var initialised = false;

      scope.init = function () {
        initialised = true;
        scope.filters = {
          gethtml: true
        };
        scope.filters.startdate = moment().hour(0).minute(0).format('YYYY-MM-DD');
        scope.paginationObject = {
          page: 0,
          perpage: '10'
        };
        var orgid = userInfo.orgid._id ? userInfo.orgid._id : userInfo.orgid;
        var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get("".concat(API_URL, "/api/v1/services/").concat(orgid)), $http.get(API_URL + '/api/v1/customer')];
        $q.all(promises).then(function (replies) {
          scope.resources = replies[0].data.resources;
          scope.services = replies[1].data.services;
          scope.customers = replies[2].data.customers;
          scope.getAppointments();
        }).catch(function (err) {
          console.error(err.statusText);
        });
      };

      $rootScope.$watch('userInfo', function () {
        if ($rootScope.userInfo && !initialised) scope.init();
      }); // scope.init();

      scope.getAppointments = function (page) {
        var params = scope.filters;
        params.htmltype = 'appointment';
        params.page = typeof page !== 'undefined' ? parseInt(page) : scope.paginationObject.page;
        params.perpage = scope.paginationObject.perpage || 10; // Assign filters from the url if they exist

        var filters = $location.search();
        scope.filters.serviceid = scope.filters.serviceid || filters.serviceid || '0';

        if (scope.filters.serviceid !== '0') {
          params.serviceid = scope.filters.serviceid;
        }

        scope.filters.resourceid = scope.filters.resourceid || filters.resourceid || '0';

        if (scope.filters.resourceid !== '0') {
          params.resourceid = scope.filters.resourceid;
        }

        scope.filters.customerid = scope.filters.customerid || filters.customer || '0';

        if (scope.filters.customerid !== '0') {
          params.customerid = scope.filters.customerid;
        }

        if (scope.emailTo && scope.emailTo.length) {
          params.emailto = scope.emailTo;
        }

        throbber.show();
        var deferred = $q.defer();
        var options = {
          params: params
        };

        if (scope.topdf) {
          $http.defaults.headers.common['content-type'] = 'application/pdf';
          options.responseType = 'arraybuffer';
        }

        $http.get(API_URL + '/api/v1/appointment', options).then(function (reply) {
          if (scope.topdf) {
            throbber.hide();
            var file = new Blob([reply.data], {
              type: 'application/pdf'
            }),
                fileURL = URL.createObjectURL(file);
            deferred.resolve(fileURL);
            scope.topdf = false;
          } else {
            throbber.hide();
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but there was an error with this request: ' + response.msg);
              return;
            }

            scope.paginationObject.count = parseInt(response.count);
            scope.paginationObject.page = parseInt(response.page);
            scope.paginationObject.perpage = params.perpage;
            scope.appointments = response.appointments;
            scope.appointmentsTable = $sce.trustAsHtml(response.html);
            var filters = {};

            if (scope.filters.serviceid !== '0') {
              filters.serviceid = scope.filters.serviceid;
            }

            if (scope.filters.resourceid !== '0') {
              filters.resourceid = scope.filters.resourceid;
            }

            if (scope.filters.customerid !== '0') {
              filters.customerid = scope.filters.customerid;
            } // $location.search(filters);


            deferred.resolve();
          }
        });
        return deferred.promise;
      };

      scope.changeItemsToShow = function () {
        // Always start at the first page if this changes.
        scope.getAppointments(0);
      };

      scope.emailAppointments = function () {
        scope.sendingEmail = true;
        scope.getAppointments().then(function (response) {
          scope.sendingEmail = false;
          scope.emailSuccess = true;
          scope.emailTo = '';
          $timeout(function () {
            scope.emailSuccess = false;
          }, 2000);
        });
      };

      scope.printAppointments = function () {
        window.print();
      };

      scope.getPDF = function () {
        scope.topdf = true;
        scope.getAppointments().then(function (response) {
          window.open(response);
        });
      };

      scope.dateFromChanged = function (date) {
        scope.filters.startdate = date;
        scope.filters.enddate = moment(date, 'YYYY-MM-DD').add(24, 'hours').format('YYYY-MM-DD');
        scope.getAppointments();
      };

      scope.removeDateFilter = function () {
        scope.filters.startdate = moment().format('YYYY-MM-DD');
        delete scope.filters.enddate;
        scope.getAppointments();
      };

      scope.removeFilter = function (item) {
        scope.filters[item] = '0';
        scope.getAppointments();
      };
    }
  };
}