import * as momentlocale from 'moment/min/moment-with-locales.min';

(function () {
  OrganisationSettingsController.$inject = ["API_URL", "$http", "$rootScope", "$timeout"];
  angular.module('userApp').controller('OrganisationSettingsController', OrganisationSettingsController);

  function OrganisationSettingsController(API_URL, $http, $rootScope, $timeout) {
    var org = this;
    org.locales = momentlocale.locales();
    org.timezones = smooth.timezones;

    org.init = function () {
      $rootScope.throbber.show();
      $http.get(API_URL + '/api/v1/settings/organisation').then(function (reply) {
        $rootScope.throbber.hide();
        var response = reply.data;

        if (!response.success) {
          $rootScope.throbber.showError('Sorry but there was a problem with this request: ' + response.msg);
          return;
        }

        org.organisation = response.organisation;

        if (!org.organisation.social) {
          org.organisation.social = {
            emails: true,
            confirmationpage: true
          };
        }

        $timeout(function () {
          $('.select-dropdown').select2();
        }, 0);
      });
    }; // End init


    org.init();

    org.saveSettings = function () {
      $rootScope.throbber.show();
      $http.put(API_URL + '/api/v1/settings/organisation/', {
        organisation: org.organisation
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          $rootScope.throbber.showError('Sorry but there was an error with this request: ' + response.msg);
          return;
        }

        $rootScope.throbber.hide('Settings updated');
        org.organisation = response.organisation;
      }, function (error) {
        $rootScope.throbber.showError('Sorry but there was an error with this request: ' + error.errorMessage);
      });
    };
  }
})();