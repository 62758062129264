(function () {
  ViewCustomerController.$inject = ["API_URL", "$http", "$q", "$routeParams", "$rootScope", "$location"];
  angular.module('userApp').controller('ViewCustomerController', ViewCustomerController);

  function ViewCustomerController(API_URL, $http, $q, $routeParams, $rootScope, $location) {
    var cust = this;
    user = $rootScope.userInfo;
    cust.customerID = $routeParams.customerid;
    cust.userIsOwner = $rootScope.userIsOwner;

    cust.init = function () {
      cust.viewing = true;
      var promises = [$http.get(API_URL + '/api/v1/customer/' + cust.customerID), $http.get(API_URL + '/api/v1/settings/forms'), $http.get(API_URL + '/api/v1/package/customer/' + cust.customerID)];
      $rootScope.throbber.show();
      $q.all(promises).then(function (responses) {
        $rootScope.throbber.hide();

        for (var i = 0; i < responses.length; i++) {
          var response = responses[i].data;

          if (!response.success) {
            $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
            return;
          }
        }

        cust.customer = responses[0].data.customer;
        cust.transactions = responses[0].data.transactions;
        cust.forms = responses[1].data.forms;
        cust.customerPackages = responses[2].data.customerpackages;
        cust.regoForm = cust.forms.registration;

        if (cust.regoForm && cust.regoForm.length && !cust.customer.reginfo) {
          cust.customer.reginfo = {};
        }
      }, function (error) {
        $rootScope.throbber.showError('Sorry but an error occurred: ' + error.msg);
      });
    };

    cust.init();

    cust.deleteCustomer = function () {
      if (!confirm('This action will permanently delete all of this customer\'s information and appointments. This action cannot be undone. Continue?')) {
        return;
      }

      $rootScope.throbber.show();
      $http.delete(API_URL + '/api/v1/customer/' + cust.customer._id).then(function (response) {
        $rootScope.throbber.hide('Customer successfully deleted');
        $location.path('/customers');
      });
    };
  }
})();