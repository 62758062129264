(function () {
  appointmentViewer.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$timeout"];
  angular.module('userApp').directive('appointmentViewer', appointmentViewer);

  function appointmentViewer(ADMIN_URL, API_URL, $http, $q, $timeout) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/appointment-viewer.html',
      replace: true,
      scope: {
        viewAppointment: '=',
        listScope: '='
      },
      link: function link(scope, elem, attrs) {
        scope.viewAppointment = function (appointmentID) {
          scope.viewing = true;
          scope.listScope.showEditButton = true;
          scope.listScope.showSaveButton = false;
          var promises = [$http.get(API_URL + '/api/v1/appointment/' + appointmentID), $http.get(API_URL + '/api/v1/settings/forms')];
          $q.all(promises).then(function (responses) {
            responses.forEach(function (reply) {
              var response = reply.data;

              if (!response.success) {
                scope.errorMessage = response.msg;
                $timeout(function () {
                  scope.errorMessage = '';
                }, 3000);
                return;
              }

              if (response.appointment) {
                scope.appointment = response.appointment;
                scope.customer = response.appointment.customerid;
              }

              if (response.forms) {
                scope.forms = response.forms;

                _getForms();
              }
            });
          }); // End all
        }; // End viewAppointment


        scope.listScope.saveDetails = function () {
          if (!scope.customer.firstname || !scope.customer.email) return;
          scope.customer.reginfo = Object(scope.regoData);
          var appointment = {
            bookinginfo: scope.bookingData,
            comments: scope.appointment.comments
          }; // Add any new comments

          if (scope.appointment.newComment && scope.appointment.newComment.length) {
            appointment.comments.push(scope.appointment.newComment);
            scope.appointment.newComment = '';
          } // Update customer details as well as booking info


          var promises = [$http.put(API_URL + '/api/v1/customer/' + scope.appointment.customerid._id, {
            customer: scope.customer
          }), $http.put(API_URL + '/api/v1/appointment/' + scope.appointment._id, {
            appointment: appointment
          })];
          $q.all(promises).then(function (responses) {
            var success = true;
            responses.forEach(function (reply) {
              var response = reply.data;

              if (!response.success) {
                success = false;
                scope.errorMessage = response.msg;
                $timeout(function () {
                  scope.errorMessage = '';
                }, 3000);
              }
            });

            if (success) {
              scope.successMessage = true;
              scope.listScope.cancelEdit();
              $timeout(function () {
                scope.successMessage = false;
              }, 2000);
            }
          });
        };

        scope.listScope.editAppointment = function () {
          scope.viewing = false;
          scope.listScope.showEditButton = false;
          scope.listScope.showSaveButton = true;
        };

        scope.listScope.cancelEdit = function () {
          scope.viewing = true;
          scope.listScope.showEditButton = true;
          scope.listScope.showSaveButton = false;
        };

        scope.deleteComment = function (index) {
          var comment = scope.appointment.comments[index];
          $http.delete(API_URL + '/api/v1/comments/' + scope.appointment._id + '/' + index).then(function (reply) {
            scope.appointment.comments.splice(index, 1);
          });
        };

        var _getForms = function _getForms() {
          if (scope.forms.booking && scope.forms.booking[scope.appointment.serviceid]) {
            scope.bookingForm = scope.forms.booking[scope.appointment.serviceid];
          } else if (scope.forms.booking && scope.forms.booking['default']) {
            scope.bookingForm = scope.forms.booking['default'];
          }

          scope.bookingData = scope.appointment.bookinginfo;
          scope.regoForm = scope.forms.registration;
          scope.regoData = scope.appointment.customerid.reginfo;
        };
      }
    };
  }
})();