(function () {
  codeCopier.$inject = ["ADMIN_URL", "$http", "$timeout"];
  angular.module('userApp').directive('codeCopier', codeCopier);

  function codeCopier(ADMIN_URL, $http, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/code-copier.html',
      scope: {
        code: '='
      },
      link: function link(scope, elem, attrs) {
        $timeout(function () {
          $('.code-copier textarea').focus(function () {
            this.select();
          }).mouseup(function () {
            return false;
          });
        }, 0);
      }
    };
  }
})();