EmailsController.$inject = ["$scope", "API_URL", "$http", "$timeout"];

function initAccordion() {
  $('.collapse').collapse('hide');
  $('.accordion-title').on('click', function (ev) {
    ev.preventDefault();
    $(this).closest('.accordion-container').find('.panel-collapse').slideToggle(); // $('.panel-collapse').collapse('hide');
    // $(this).closest('.accordion-container').find('.panel-collapse').collapse('show');
  });
}

function initTabs() {
  $('.tab-select').on('click', function (ev) {
    ev.preventDefault();
    var $parentContainer = $(this).closest('.tab-container');
    $parentContainer.find('.nav-tabs > li').removeClass('active');
    $parentContainer.find('.tab-pane').removeClass('active');
    var panelClass = $(this).attr('id');
    $parentContainer.find('.' + panelClass).addClass('active');
    $(this).closest('li').addClass('active');
  });
}

angular.module('userApp').controller('EmailsController', EmailsController);

function EmailsController($scope, API_URL, $http, $timeout) {
  var email = this;
  email.form = {};

  email.init = function () {
    $http.get(API_URL + '/api/v1/settings/emails').then(function (response) {
      email.form.emails = response.data.emailSettings.emails;
      email.form.settings = response.data.emailSettings.settings;
      email.confirmInitText = email.form.emails.confirmation.customer.text;
      email.resourceConfirmInitText = email.form.emails.confirmation.resource.text;
      email.ownerConfirmInitText = email.form.emails.confirmation.owner.text;
      email.workshopConfirmInitText = email.form.emails.workshopconfirmation.customer.text;
      email.resourceWorkshopConfirmInitText = email.form.emails.workshopconfirmation.resource.text;
      email.ownerWorkshopConfirmInitText = email.form.emails.workshopconfirmation.owner.text;
      email.customerChangeInitText = email.form.emails.change.customer.text;
      email.resourceChangeInitText = email.form.emails.change.resource.text;
      email.ownerChangeInitText = email.form.emails.change.owner.text;
      email.customerDeleteInitText = email.form.emails.delete.customer.text;
      email.resourceDeleteInitText = email.form.emails.delete.resource.text;
      email.ownerDeleteInitText = email.form.emails.delete.owner.text;
      email.customerWorkshopDeleteInitText = email.form.emails.workshopdelete.customer.text;
      email.resourceWorkshopDeleteInitText = email.form.emails.workshopdelete.resource.text;
      email.ownerWorkshopDeleteInitText = email.form.emails.workshopdelete.owner.text;
      email.customerMembershipActivatedInitText = email.form.emails.membershipactivated.customer.text;
      email.ownerMembershipActivatedInitText = email.form.emails.membershipactivated.owner.text;
      email.customerMembershipRenewedInitText = email.form.emails.membershiprenewed.customer.text;
      email.ownerMembershipRenewedInitText = email.form.emails.membershiprenewed.owner.text;
      email.customerMembershipCancelledInitText = email.form.emails.membershipcancelled.customer.text;
      email.ownerMembershipCancelledInitText = email.form.emails.membershipcancelled.owner.text;
      email.reminderInitText = email.form.emails.reminder.customer.text;
      email.followupInitText = email.form.emails.followup.customer.text;
      email.resourceSummaryInitText = email.form.emails.summary.resource.text;
      email.ownerSummaryInitText = email.form.emails.summary.owner.text;
      email.zoomInitText = response.data.emailSettings.zoominstructions;
    }, function (error) {
      $scope.throbber.showError(error.statusText);
    });
  };

  email.init();

  email.saveEmails = function () {
    $scope.throbber.show();
    $http.put(API_URL + '/api/v1/settings/emails', {
      emailSettings: email.form
    }).then(function (response) {
      $scope.throbber.hide('Successfully saved');
      email.form.emails = response.data.emailSettings.emails;
      email.form.settings = response.data.emailSettings.settings;
    }, function (error) {
      $scope.throbber.showError(error.statusText);
    });
  }; // Set up accordion and tabs


  $(function () {
    initAccordion();
    initTabs();
  });
}