(function () {
  bugWidget.$inject = ["ADMIN_URL", "API_URL", "$http", "$timeout"];
  angular.module('userApp').directive('bugWidget', bugWidget);

  function bugWidget(ADMIN_URL, API_URL, $http, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/bug-widget.html',
      scope: true,
      link: function link(scope, elem, attrs) {
        scope.launchWidget = function () {
          $('.bug-widget-modal').modal('show');
        };

        scope.sendMessage = function () {
          if (!scope.message.length) {
            return;
          }

          scope.loading = true;
          $http.post(API_URL + '/misc/sendfeedback', {
            message: scope.message
          }).then(function (reply) {
            scope.loading = false;
            scope.successMessage = true;
            $timeout(function () {
              scope.successMessage = false;
              $('.bug-widget-modal').modal('hide');
              scope.message = '';
            }, 2000);
          });
        };
      }
    };
  }
})();