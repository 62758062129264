(function () {
  formBuilder.$inject = ["ADMIN_URL"];
  angular.module('directive.form-builder', []).directive('formBuilder', formBuilder);

  function formBuilder(ADMIN_URL) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/form-builder.html',
      scope: {
        formItems: '=',
        formData: '=',
        viewing: '=?'
      },
      link: function link(scope, elem, attrs) {}
    };
  }
})();