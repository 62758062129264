(function () {
  angular.module('userApp').service('SchedulingService', SchedulingService);

  function SchedulingService() {
    var that = this,
        SECONDS_IN_HOUR = 3600,
        SECONDS_IN_DAY = 86400; // Take the shifts for a resource and turn them into a data object that can be displayed on the scheduling page.

    this.groupShifts = function (shifts) {
      // 1. Take the shift. 
      // 2. Figure out which day of the week it belongs to
      // 3. Get timeslots for each shift
      var newShifts = {};
      shifts.forEach(function (shift) {
        if (newShifts[shift.day]) {
          newShifts[shift.day].push(shift);
        } else {
          newShifts[shift.day] = [shift];
        }
      });
      return newShifts;
    }; // End getSchedule
    // Get start and end times for each timeslot in a shift - not timezone dependent


    this.getTimeslots = function (shift) {
      var duration = shift.duration,
          gap = shift.gap || 0,
          timeslots = [];

      for (var i = shift.start; i < shift.end; i += duration + gap) {
        var start = i,
            end = i + duration;
        timeslots.push({
          start: this.offsetToTime(start),
          end: this.offsetToTime(end)
        });
      }

      return timeslots;
    };

    this.offsetToTime = function (offset) {
      // We know the day, get the time of day.
      var time = offset % SECONDS_IN_DAY,
          hour = Math.floor(time / SECONDS_IN_HOUR),
          minutes = String(Math.floor(time % SECONDS_IN_HOUR / 60));
      minutes = minutes.length < 2 ? '0' + minutes : minutes;
      return hour + ':' + minutes;
    }; // Transform services list from array to object for quicker lookup


    this.servicesTransform = function (services) {
      var serviceObject = {};
      services.forEach(function (service) {
        serviceObject[service._id] = service;
      });
      return serviceObject;
    };
  }
})();