(function () {
  addResource.$inject = ["ADMIN_URL", "API_URL", "$http", "$location"];
  angular.module('userApp').directive('addResource', addResource);

  function addResource(ADMIN_URL, API_URL, $http, $location) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/add-resource.html',
      replace: true,
      scope: {
        workshopController: '=?',
        resources: '=',
        showModal: '=',
        updateParent: '='
      },
      link: function link(scope, elem, attrs) {
        scope.form = {};
        scope.listResources = true;

        scope.toggleDisplay = function (listResources) {
          scope.listResources = listResources;
        };

        scope.saveResource = function () {
          $http.post(API_URL + '/api/v1/resource', {
            newresource: scope.form
          }).then(function (response) {
            response = response.data;

            if (response.success) {
              $('#add-resource-modal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              scope.workshopController && scope.updateParent(response.resource);
              !scope.workshopController && $location.path('/scheduling/weekly/' + response.resource._id, false);
              return;
            }

            if (response.toomanyresources) return scope.tooManyResources = true;
            scope.errorMessage = "Sorry but an error occurred: ".concat(response.msg);
          }, function (response) {
            console.log(response);
          });
        }; // End saveService


        scope.showModal = function () {
          scope.form = {};
          scope.listResources = true;
          $('#add-resource-modal').modal('show');
        };

        scope.changeLocation = function (path) {
          $('#add-resource-modal', elem).modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $location.path(path);
        };
      }
    };
  }
})();