import _objectSpread from "@babel/runtime/helpers/objectSpread";

var configFile = require('../config/index.js'),
    env = ENVIRONMENT,
    org = ORGANISATION;

var moment = require('moment-timezone'),
    mixpanel = require('mixpanel-browser');

window.smooth = _objectSpread({}, configFile.environments[env][org], {
  roles: configFile.roles,
  timezones: configFile.timezones
});
angular.module('userApp', [// External libraries
'ngRoute', 'angular-jwt', 'ngCookies', 'MainController', 'ngFileUpload', 'ui.sortable', 'ngSanitize', // Filters
'filter.smoothdate', 'filter.smoothtime', 'filter.currencyFilter', // Services
'service.timeSettings', 'service.calendarService', // Directives
'directive.durationControl', 'directive.throbber', 'directive.tooltip', 'directive.imageManager', 'directive.schedule-manager', 'directive.addCustomer', 'directive.form-manager', 'directive.addFormUnit', 'directive.add-appointment', 'directive.form-builder', 'directive.days-closed-chooser', 'directive.days-chooser', 'directive.ngSummernote', 'directive.workshop-days-chooser']).config(["$routeProvider", function ($routeProvider) {
  $routeProvider.when('/register', {
    templateUrl: smooth.admin_url + '/templates/auth/register.html',
    controller: 'RegisterController',
    controllerAs: 'reg'
  }).when('/login', {
    templateUrl: smooth.admin_url + '/templates/auth/login.html',
    controller: 'LoginController',
    controllerAs: 'log'
  }).when('/oauth2/authorize', {
    templateUrl: smooth.admin_url + '/templates/auth/oauth-login.html',
    controller: 'OauthLoginController',
    controllerAs: 'log'
  }).when('/account', {
    templateUrl: smooth.admin_url + '/templates/account.html',
    controller: 'AccountController',
    controllerAs: 'acc'
  }).when('/subscribe', {
    templateUrl: smooth.admin_url + '/templates/subscribe.html',
    controller: 'SubscribeController',
    controllerAs: 'sub'
  }).when('/eugdp', {
    templateUrl: smooth.admin_url + '/templates/eugdp.html',
    controller: 'EugdpController',
    controllerAs: 'eugdp'
  }).when('/transaction-fee-consent', {
    templateUrl: smooth.admin_url + '/templates/transaction-fee-consent.html',
    controller: 'TransactionFeeController',
    controllerAs: 'tran'
  }).when('/forgot-password/:backtooauth?', {
    templateUrl: smooth.admin_url + '/templates/auth/forgot-password.html',
    controller: 'ForgotPasswordController',
    controllerAs: 'fog'
  }).when('/reset-password/:resetkey', {
    templateUrl: smooth.admin_url + '/templates/auth/reset-password.html',
    controller: 'ResetPasswordController',
    controllerAs: 'res'
  }).when('/dashboard/:dotour?', {
    templateUrl: smooth.admin_url + '/templates/dashboard.html?v=1',
    controller: 'DashboardController',
    controllerAs: 'dash'
  }).when('/appointments/manage/:resourceid?', {
    templateUrl: smooth.admin_url + '/templates/manage-appointments.html',
    controller: 'ManageAppointmentsController',
    controllerAs: 'man'
  }).when('/appointments/view', {
    templateUrl: smooth.admin_url + '/templates/view-appointments.html',
    controller: 'ViewAppointmentsController',
    controllerAs: 'view',
    reloadOnSearch: false
  }).when('/appointments/export', {
    templateUrl: smooth.admin_url + '/templates/export-appointments.html',
    controller: 'ExportAppointmentsController',
    controllerAs: 'view',
    reloadOnSearch: false
  }).when('/customers', {
    templateUrl: smooth.admin_url + '/templates/customers.html',
    controller: 'CustomersController',
    controllerAs: 'cust'
  }).when('/customer/:customerid', {
    templateUrl: smooth.admin_url + '/templates/view-customer.html',
    controller: 'ViewCustomerController',
    controllerAs: 'cust'
  }).when('/settings', {
    templateUrl: smooth.admin_url + '/templates/settings/settings.html?v=1.0',
    controller: 'SettingsController',
    controllerAs: 'set'
  }).when('/settings/organisation', {
    templateUrl: smooth.admin_url + '/templates/settings/organisation-settings.html',
    controller: 'OrganisationSettingsController',
    controllerAs: 'org'
  }).when('/settings/calendar', {
    templateUrl: smooth.admin_url + '/templates/settings/calendar-settings.html',
    controller: 'CalendarSettingsController',
    controllerAs: 'cal'
  }).when('/settings/forms', {
    templateUrl: smooth.admin_url + '/templates/settings/forms.html',
    controller: 'FormsController',
    controllerAs: 'form'
  }).when('/settings/emails', {
    templateUrl: smooth.admin_url + '/templates/settings/emails.html',
    controller: 'EmailsController',
    controllerAs: 'email'
  }).when('/settings/charging', {
    templateUrl: smooth.admin_url + '/templates/settings/charging.html?v=1',
    controller: 'ChargingController',
    controllerAs: 'charge'
  }).when('/settings/daysclosed', {
    templateUrl: smooth.admin_url + '/templates/settings/days-closed.html',
    controller: 'DaysClosedController',
    controllerAs: 'day'
  }).when('/settings/apis', {
    templateUrl: smooth.admin_url + '/templates/settings/settings-apis.html',
    controller: 'ApisController',
    controllerAs: 'api'
  }).when('/settings/resources-services', {
    templateUrl: smooth.admin_url + '/templates/settings/resources-services.html',
    controller: 'ResourcesServicesController',
    controllerAs: 'res'
  }).when('/settings/daysoff/:type/:typeid', {
    templateUrl: smooth.admin_url + '/templates/settings/days-off.html',
    controller: 'DaysOffController',
    controllerAs: 'day'
  }).when('/settings/translations', {
    templateUrl: smooth.admin_url + '/templates/settings/translations.html',
    controller: 'TranslationsController',
    controllerAs: 'tran'
  }).when('/settings/users', {
    templateUrl: smooth.admin_url + '/templates/settings/user-access.html',
    controller: 'UserAccessController',
    controllerAs: 'user'
  }).when('/settings/users/add-user', {
    templateUrl: smooth.admin_url + '/templates/edit-user.html',
    controller: 'AddUserController',
    controllerAs: 'user'
  }).when('/settings/users/edit-user/:userid', {
    templateUrl: smooth.admin_url + '/templates/edit-user.html',
    controller: 'EditUserController',
    controllerAs: 'user'
  }).when('/settings/gdpr', {
    templateUrl: smooth.admin_url + '/templates/settings/settings-gdpr.html',
    controller: 'GDPRController',
    controllerAs: 'gdpr'
  }).when('/setup/', {
    templateUrl: smooth.admin_url + '/templates/settings/setup.html',
    controller: 'SetupController',
    controllerAs: 'set'
  }).when('/setup/integration', {
    templateUrl: smooth.admin_url + '/templates/calendar-integration.html?v=1.0',
    controller: 'CalendarIntegrationController',
    controllerAs: 'int'
  }).when('/addresource', {
    templateUrl: smooth.admin_url + '/templates/editresource.html',
    controller: 'AddResourceController',
    controllerAs: 'res'
  }).when('/editresource/:resourceid/:created?', {
    templateUrl: smooth.admin_url + '/templates/editresource.html',
    controller: 'EditResourceController',
    controllerAs: 'res'
  }).when('/addservice', {
    templateUrl: smooth.admin_url + '/templates/editservice.html',
    controller: 'AddServiceController',
    controllerAs: 'serv'
  }).when('/editservice/:serviceid/:created?', {
    templateUrl: smooth.admin_url + '/templates/editservice.html',
    controller: 'EditServiceController',
    controllerAs: 'serv'
  }).when('/list-schedules', {
    templateUrl: smooth.admin_url + '/templates/list-schedules.html',
    controller: 'ListSchedulesController',
    controllerAs: 'shed'
  }).when('/scheduling/weekly/:resourceid?', {
    templateUrl: smooth.admin_url + '/templates/weekly-scheduling.html',
    controller: 'WeeklySchedulingController',
    controllerAs: 'shed'
  }).when('/scheduling/workshops/', // List all events and workshops for all resources
  {
    templateUrl: smooth.admin_url + '/templates/list-workshops.html',
    controller: 'ListWorkshopsController',
    controllerAs: 'work'
  }).when('/scheduling/workshops/new', // Add new workshop
  {
    templateUrl: smooth.admin_url + '/templates/workshop-scheduling.html',
    controller: 'AddWorkshopController',
    controllerAs: 'work'
  }).when('/scheduling/workshops/:workshopid', // Edit existing workshop
  {
    templateUrl: smooth.admin_url + '/templates/workshop-scheduling.html',
    controller: 'EditWorkshopController',
    controllerAs: 'work'
  }).when('/superlogin', {
    templateUrl: smooth.admin_url + '/templates/auth/super-login.html',
    controller: 'SuperLoginController',
    controllerAs: 'log'
  }).when('/discourse/sso', {
    templateUrl: smooth.admin_url + '/templates/auth/discourse/sso.html',
    controller: 'DiscourseSSOController',
    controllerAs: 'dis'
  }).otherwise({
    redirectTo: '/dashboard'
  });
}]).constant('ADMIN_URL', smooth.admin_url).constant('BASE_URL', smooth.base_url).constant('API_URL', smooth.api_url).constant('CALENDAR_URL', smooth.calendar_url).constant('ORGANISATION', smooth.organisation).value('moment', window.moment) // Monitor all requests and responses, check for and assign JWT token.
.factory('AuthIntercept', ["$window", "$rootScope", "$location", "ADMIN_URL", "$q", "jwtHelper", function ($window, $rootScope, $location, ADMIN_URL, $q, jwtHelper) {
  var authInterceptor = {
    request: function request(req) {
      var token = localStorage.getItem('jwt-token');
      $rootScope.chargingEnabled = window.smooth.charging; // Append token to header if it exists. Currently occurs on
      // every request.

      if (token) {
        // Get user info from the token and store globally.
        var payload = jwtHelper.decodeToken(token); // Check for token expiry.
        // If the system is charging and the user has no subscription info, force them to create a new token.

        if (moment.unix(payload.exp).isBefore(moment())) {
          localStorage.removeItem('jwt-token');
          delete $rootScope.userInfo;
          return req;
        }

        $rootScope.userInfo = payload.user;
        $rootScope.userInfo.timezone = payload.timezone;
        $rootScope.userInfo.subscription = !!payload.subscription && payload.subscription;
        $rootScope.premiumSubRequired = window.smooth.charging && payload.subscription && payload.subscription.id === 'BASIC'; // Whether or not to enable free features

        req.headers['x-access-token'] = token;
      }

      return req;
    },
    response: function response(res) {
      // Token sent from server. Store it and update user info from jwt payload
      if (res.data.success && res.data.token) {
        localStorage.setItem('jwt-token', res.data.token); // Get user info from the token and store globally.

        var payload = jwtHelper.decodeToken(res.data.token);
        $rootScope.userInfo = payload.user;
        $rootScope.userInfo.timezone = payload.timezone;
        $rootScope.userInfo.subscription = !!payload.subscription && payload.subscription;
        $rootScope.premiumSubRequired = window.smooth.charging && payload.subscription && payload.subscription.id === 'BASIC'; // Whether or not to enable free features
      }

      return res;
    },
    responseError: function responseError(rejection) {
      if (rejection.status === 401 && !$location.path().match(/\/oauth2\/authorize/) && !$location.path().match(/\/discourse\/sso/) && ($location.path() !== '/login' || rejection.data.msg === 'Invalid token')) {
        // Remove existing token
        localStorage.removeItem('jwt-token');
        window.location = ADMIN_URL + '/#/login';
        return;
      }

      return $q.reject(rejection);
    }
  };
  return authInterceptor;
}]).config(["$httpProvider", "$locationProvider", function ($httpProvider, $locationProvider) {
  $httpProvider.interceptors.push("AuthIntercept");
  $locationProvider.hashPrefix('');
}]) // This function is helpful for global settings and settings specific to the page / context.
.run(["$rootScope", "$location", "jwtHelper", "SubscriptionService", function ($rootScope, $location, jwtHelper, SubscriptionService) {
  $rootScope.$on('$routeChangeSuccess', function (e, current, pre) {
    var location = $location.path(),
        token = localStorage.getItem('jwt-token'); // Google analytics

    ga('set', 'page', location);
    ga('send', 'pageview');
    $rootScope.authorising = false;

    if (token) {
      // This will update the JWT token with the info for the new
      // subscription
      SubscriptionService.checkSubscription(); // Get user info from the token and store globally.

      var payload = jwtHelper.decodeToken(token);

      if (moment.unix(payload.exp).isBefore(moment())) {
        localStorage.removeItem('jwt-token');
        delete $rootScope.userInfo;
        $location.path('/login');
        return;
      }

      $rootScope.userInfo = payload.user;
      $rootScope.userInfo.timezone = payload.timezone;
      $rootScope.userInfo.subscription = payload.subscription;

      try {
        var role = payload.user.roles[0];
        $rootScope.userIsOwner = role.type === 'OWNER';
        $rootScope.userIsAgent = role.type === 'AGENT';
      } catch (err) {
        console.error("Error getting user role");
      } // If EUGDPR consent has not been obtained, redirect to eugdp page in all cases


      if (!$rootScope.userInfo.eugdprconsent) {
        return $location.path('/eugdp');
      }

      if (window.smooth.transaction_fee && !payload.transactionfeeconsent) {
        return $location.path('/transaction-fee-consent');
      }

      try {
        // Track page view in Mixpanel
        if (smooth.env === 'production' && moment($rootScope.userInfo.createdAt).isAfter(moment('2020-07-09'))) {// mixpanel.track("Page View", {"Path": location});
        }
      } catch (err) {
        console.error(err.message);
      } // If the user is authenticated, redirect them to the home page


      if (location === '/login' || location === '/register') {
        $location.path('/dashboard');
      } // Unless this is the login / registration / forgot / change password page, a JWT token must always be present.

    } else if (location !== '/login' && location !== '/register' && !location.match(/\/forgot-password/) && location !== '/superlogin' && !location.match(/\/discourse\/sso/) && !location.match(/\/oauth2\/authorize/) && !location.match(/\/reset-password/) && location !== '/') {
      $location.path('/login');
    } else {
      $rootScope.authorising = true;
    }
  }); // Soft page change

  var original = $location.path;

  $location.path = function (path, reload) {
    if (reload && reload === false) {
      var lastRoute = $route.current;
      var un = $rootScope.$on('$locationChangeSuccess', function () {
        $route.current = lastRoute;
        un();
      });
    }

    return original.apply($location, [path]);
  }; // Useful function


  $rootScope.safeApply = function safeApply(operation) {
    var phase = this.$root.$$phase;

    if (phase !== '$apply' && phase !== '$digest') {
      this.$apply(operation);
      return;
    }

    if (operation && typeof operation === 'function') operation();
  };
}]);