manageCustomerPackages.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout", "$location"];
datetimepicker = require('eonasdan-bootstrap-datetimepicker');
angular.module('userApp').directive('manageCustomerPackages', manageCustomerPackages);

function manageCustomerPackages(ADMIN_URL, API_URL, $http, $rootScope, $timeout, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/customer/manage-customer-packages.html',
    scope: {
      customerPackages: '=',
      customerId: '@'
    },
    link: function link(scope, elem, attrs) {
      scope.showDeleted = false;
      scope.$watch('customerPackages', function () {
        if (scope.customerPackages && scope.customerPackages.length) {
          getPackagesStatus(scope.customerPackages);
        }
      });
      scope.form = {};

      var init = function init() {
        var user = $rootScope.userInfo; // Resources are not allowed to give / manage packages

        scope.userIsResource = user.roles[0].type === 'RESOURCE'; // Get list of available packages

        $http.get(API_URL + '/api/v1/package').then(function (reply) {
          var response = reply.data;

          if (!response.success) {
            $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
            return;
          }

          scope.packages = response.packages;
          scope.form.chosenPackage = '0';
        });
      };

      init();

      function getPackagesStatus(packages) {
        scope.hasDeleted = false;
        scope.hasActive = false;

        for (var i = 0; i < packages.length; i++) {
          if (packages[i].deleted) {
            packages[i].status = 'deleted';
            packages[i].active = false;
            scope.hasDeleted = true;
          } else if (packages[i].expiry && moment(packages[i].expiry).isBefore(moment().hour(0).minute(0))) {
            packages[i].status = 'expired';
            packages[i].active = false;
            scope.hasDeleted = true;
          } else {
            packages[i].status = 'active';
            packages[i].active = true;
            scope.hasActive = true;
          } // Now set expiry with locale


          if (packages[i].expiry) {
            packages[i].formattedExpiry = moment(packages[i].expiry).format('ll');
          }
        }
      }

      scope.deletePackage = function (packageID) {
        if (!confirm('This will delete all credit that this customer has with this package. Continue?')) {
          return;
        }

        $http.delete(API_URL + '/api/v1/package/customer/' + packageID).then(function (reply) {
          var response = reply.data;

          if (!response.success) {
            scope.errorMessage = "Sorry but an error occurred with this request: " + response.msg;
            $timeout(function () {
              scope.errorMessage = '';
            }, 2000);
            return;
          } // Now update the customer package list


          $http.get(API_URL + '/api/v1/package/customer/' + scope.customerId).then(function (reply) {
            var response = reply.data;

            if (!response.success) {}

            scope.customerPackages = response.customerpackages;
            getPackagesStatus(scope.customerPackages);
          });
        });
      };

      scope.adjustCredit = function ($index, up) {
        var existingPackage = scope.customerPackages[$index],
            updatePackage = {
          credits: existingPackage.credits
        };
        updatePackage.credits = up ? updatePackage.credits + 1 : updatePackage.credits - 1;

        if (updatePackage.credits < 0) {
          updatePackage.credits = 0;
        }

        scope.adjustLoading = true;
        $http.put(API_URL + '/api/v1/package/customer/' + scope.customerPackages[$index]._id, {
          customerpackage: updatePackage
        }).then(function (reply) {
          var response = reply.data;
          scope.adjustLoading = false;

          if (!response.success) {
            scope.errorMessage = 'Sorry but there was an error with this request: ' + response.msg;
            $timeout(function () {
              scope.errorMessage = '';
            }, 2000);
            return;
          } // Now update the customer package list


          $http.get(API_URL + '/api/v1/package/customer/' + scope.customerId).then(function (reply) {
            var response = reply.data;

            if (!response.success) {}

            scope.customerPackages = response.customerpackages;
            getPackagesStatus(scope.customerPackages);
          });
        });
      };

      scope.giveChosenPackage = function () {
        var chosenPackage = JSON.parse(JSON.stringify(scope.packages[scope.form.chosenPackage]));
        var newPackage = {
          packageid: chosenPackage._id
        };
        $http.post(API_URL + '/api/v1/package/customer/' + scope.customerId, {
          customerpackage: newPackage
        }).then(function (reply) {
          var response = reply.data;

          if (!response.success) {
            $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
            return;
          } // Now update the customer package list


          $http.get(API_URL + '/api/v1/package/customer/' + scope.customerId).then(function (reply) {
            var response = reply.data;

            if (!response.success) {}

            scope.customerPackages = response.customerpackages;
            getPackagesStatus(scope.customerPackages);
            $('.give-package-modal').modal('hide');
          });
        });
      };

      scope.showHistory = function (history) {
        for (var i = 0; i < history.length; i++) {
          history[i].friendlytime = moment(history[i].time).format('l LT');
        }

        scope.packageHistory = history;
        $('.package-history-modal').modal('show');
      };

      scope.launchModal = function () {
        $('.give-package-modal').modal('show');
      };

      scope.gotoChargingPage = function () {
        $('.give-package-modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $location.path('/settings/charging');
      };

      scope.editCustomerPackageExpiry = function (date, index) {
        var existingPackage = scope.customerPackages[index],
            updatePackage = {
          expiry: date
        };
        scope.adjustLoading = true;
        var throbber = $rootScope.throbber;
        throbber.show();
        $http.put(API_URL + '/api/v1/package/customer/' + scope.customerPackages[index]._id, {
          customerpackage: updatePackage
        }).then(function (reply) {
          var response = reply.data;
          scope.adjustLoading = false;

          if (!response.success) {
            throbber.hide();
            scope.errorMessage = 'Sorry but there was an error with this request: ' + response.msg;
            $timeout(function () {
              scope.errorMessage = '';
            }, 2000);
            return;
          }

          throbber.hide('Package expiry updated'); // Now update the customer package list

          $http.get(API_URL + '/api/v1/package/customer/' + scope.customerId).then(function (reply) {
            var response = reply.data;

            if (!response.success) {}

            scope.customerPackages = response.customerpackages;
            getPackagesStatus(scope.customerPackages);
          });
        });
      };
    }
  };
}