addPackage.$inject = ["ADMIN_URL", "API_URL", "$http", "$timeout", "$rootScope"];

require('eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js');

angular.module('userApp').directive('addPackage', addPackage);

function addPackage(ADMIN_URL, API_URL, $http, $timeout, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/add-package.html',
    replace: true,
    scope: {
      parent: '=controllerScope',
      servicesList: '@services',
      currency: '='
    },
    link: function link(scope, elem, attrs) {
      scope.editing = false;
      scope.form = {};
      var $elem = $('.add-package-modal');
      var throbber = $rootScope.throbber;
      scope.$watch('servicesList', function () {
        if (scope.servicesList) {
          scope.services = JSON.parse(scope.servicesList);
          $timeout(function () {
            $('.select-package-services').select2();
            $('.select-package-services').on('change', function (ev) {
              scope.form.services = $(this).val();
            });
          }, 0);
        }
      });

      scope.savePackage = function () {
        if (parseInt(scope.form.numappts) <= 0) {
          scope.numapptsValidationError = true;
          $timeout(function () {
            scope.numapptsValidationError = false;
          }, 2000);
        }

        if (!scope.form.services || !scope.form.services.length) {
          scope.serviceValidationError = true;
          $timeout(function () {
            scope.serviceValidationError = false;
          }, 2000);
          return;
        }

        if (scope.form.expirytype === 'none') {
          scope.form.expirydate = null;
          scope.form.expirydays = 0;
        }

        if (scope.packageexpirytype === 'none') {
          scope.form.packageexpirydate = null;
        }

        if (scope.usestype == 'unlimited') {
          scope.form.packageuses = null;
        }

        throbber.show();
        $http({
          method: scope.editing ? 'PUT' : 'POST',
          url: scope.editing ? API_URL + '/api/v1/package/' + scope.packageID : API_URL + '/api/v1/package',
          data: {
            package: scope.form
          }
        }).then(function (reply) {
          var response = reply.data;

          if (!response.success) {
            throbber.hide();
            throbber.showError('Sorry but there was an error with this query: ' + response.msg);
            return;
          }

          throbber.hide('Package ' + scope.editing ? 'updated' : 'saved' + ' successfully');
          scope.updateSuccessful = true;
          $timeout(function () {
            scope.updateSuccessful = false;
          }, 2000);
          scope.parent.init();

          if (!scope.editing) {
            scope.form = {};
            scope.form.cost = '00.00';
            scope.form.expirytype = 'none';
            scope.form.expirydate = null;
            scope.form.expirydays = 0;
            scope.form.services = [];
            scope.usestype = 'unlimited';
            scope.form.packageuses = 0;
            scope.packageexpirytype = 'none';
            scope.form.packageexpirydate = null;
            $rootScope.safeApply();
            initDatePickers();
            $elem.modal('hide');
          }
        });
      };

      scope.parent.editPackageModal = function (packageID) {
        throbber.show();
        scope.form = {};
        $http.get(API_URL + '/api/v1/package/' + packageID).then(function (reply) {
          var response = reply.data;
          throbber.hide();

          if (!response.success) {
            throbber.showError('Sorry but there was an error with this request: ' + response.msg);
            return;
          }

          var services = [];
          scope.packageID = packageID;

          if (!response.package.packageexpirydate) {
            scope.packageexpirytype = 'none';
          } else {
            scope.packageexpirytype = 'date';
            scope.packageDatepicker.data("DateTimePicker").date(moment(response.package.packageexpirydate));
          }

          if (response.package.expirytype === 'none') {
            scope.form.expirydays = 0;
            scope.form.expirydate = null;
          } else if (response.package.expirytype === 'date') {
            scope.customerPackageDatepicker.data("DateTimePicker").date(moment(response.package.expirydate));
          }

          if (response.package.packageuses > 0) {
            scope.usestype = 'limited';
          } else {
            scope.usestype = 'unlimited';
            scope.form.packageuses = 0;
          }

          scope.form = response.package;
          response.package.services.forEach(function (service) {
            services.push(service._id);
          });
          scope.form.services = services;
          scope.changeExpiryType();
          scope.showPackageDatePicker();
          initDatePickers();
          $timeout(function () {
            $('.select-package-services').select2();
          }, 0);
        });
        scope.editing = true;
        $elem.modal('show');
      };

      scope.parent.addPackageModal = function () {
        scope.form = {};
        scope.form.cost = '00.00';
        scope.form.expirytype = 'none';
        scope.form.expirydate = null;
        scope.form.expirydays = 0;
        scope.form.services = [];
        scope.usestype = 'unlimited';
        scope.form.packageuses = 0;
        scope.packageexpirytype = 'none';
        scope.form.packageexpirydate = null;
        document.getElementById('customer-package-expiry-date').value = '';
        document.getElementById('package-expiry-date-input').value = '';
        $rootScope.safeApply();
        $timeout(function () {
          $('.select-package-services').val(null).trigger('change');
        }, 0);
        scope.changeExpiryType();
        scope.showPackageDatePicker();
        initDatePickers();
        scope.editing = false;
        $elem.modal('show');
      };

      scope.changeExpiryType = function () {
        var expiryType = scope.form.expirytype;
        $('.expiry-section').not('.expiry-' + expiryType).slideUp(function () {
          $('.expiry-' + expiryType).slideDown(function () {
            initDatePickers();
          });
        });
      };

      scope.showPackageDatePicker = function () {
        if (scope.packageexpirytype === 'date') {
          $('.package-expiry-date').slideDown();
        } else {
          $('.package-expiry-date').slideUp();
        }
      };

      var initDatePickers = function initDatePickers() {
        $timeout(function () {
          var minDate = moment();

          if (scope.form.expirydate && moment().isBefore(moment(scope.form.expirydate))) {
            minDate = moment(scope.form.expirydate);
          }

          var customerPackageOptions = {
            format: 'MMMM Do YYYY',
            useCurrent: false,
            minDate: minDate
          };

          if (scope.form.expirytype === 'date') {
            customerPackageOptions.defaultDate = scope.form.expirydate;
          }

          if (!scope.customerPackageDatepicker) {
            scope.customerPackageDatepicker = $('.sb-customer-package-datepicker').datetimepicker(customerPackageOptions);
            scope.customerPackageDatepicker.on('dp.change', function (event) {
              event.preventDefault();
              scope.form.expirydate = event.date.format('YYYY-MM-DD');
              return false;
            });
          }

          var packageOptions = {
            format: 'MMMM Do YYYY',
            useCurrent: false,
            minDate: moment()
          };

          if (scope.form.packageexpirydate) {
            packageOptions.defaultDate = scope.form.packageexpirydate;
            scope.packageexpirytype = 'date';
          } else {
            scope.packageexpirytype = 'none';
          }

          if (!scope.packageDatepicker) {
            scope.packageDatepicker = $('.sb-package-datepicker').datetimepicker(packageOptions);
            scope.packageDatepicker.on('dp.change', function (event) {
              event.preventDefault();
              scope.form.packageexpirydate = event.date.format('YYYY-MM-DD');
              return false;
            });
          }
        }, 0);
      };

      initDatePickers();
    }
  };
}