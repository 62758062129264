(function () {
  var template = "<div class='popover product-tour' style='min-width: 600px; padding-bottom: 10px;'>" + "<div class='arrow'></div><h3 class='popover-title'></h3>" + "<div class='popover-content'></div>" + "<div class='popover-navigation'>" + "<button class='btn btn-default pull-left' data-role='end'>End tour</button>" + "<button class='btn btn-primary pull-right' data-role='next'>Next</button>" + "</div></div>";
  smooth.dashboardTour = new Tour({
    template: template,
    storage: false,
    onEnd: function onEnd() {
      smooth.dotour = false;
    },
    steps: [{
      orphan: true,
      title: "Welcome to Smoothbook!",
      content: "<p>To get you started we'd like to give you a short (two minute) tour of the system, show you some features and demonstrate how Smoothbook can save you time and money, add convenience for your users and help you market your business.</p>" + "<p>Please click the next button below to continue.</p>",
      backdrop: true
    }, {
      element: ".dashboard-tour",
      title: "Dashboard page",
      content: "<p>You are on the dashboard page which is the first page you will see whenever you log in.</p>" + "<p>From here you can easily filter and view, filter, print and email your upcoming appointments and classes. You can view / print and export" + " your upcoming appointments with all details + payment info., etc. and you can easily email some or all of your class attendees directly.</p>",
      backdrop: true,
      placement: 'bottom'
    }, {
      element: ".dashboard-customer-search",
      title: "Customer search",
      content: "<p>You can quickly and easily search and view your customers, their appointments and transactions</p>",
      backdrop: true,
      backdropPadding: 10,
      placement: 'top'
    }, {
      element: ".smoothbook-navigation",
      title: "Main menu",
      content: "<p>The main menu is purposely simple: appointments, customers, settings, scheduling and setup.</p>" + "<p>Click the 'next' button below and we'll show you some of the many features of Smoothbook.</p>",
      backdrop: true,
      onNext: function onNext() {
        smooth.dotour = true;
        document.location.href = '/#/settings';
      },
      placement: 'bottom',
      backdropPadding: 10
    }, {
      element: "#my-other-element",
      title: "Title of my step",
      content: "Content of my step"
    }]
  });
  smooth.settingsTour = new Tour({
    debug: true,
    template: template,
    storage: false,
    onEnd: function onEnd() {
      smooth.dotour = false;
    },
    steps: [{
      orphan: true,
      title: "Settings page",
      content: "<p>This is the settings page is where you can completely customise your system and connect to other services.</p>" + "<p>To quickly explore some of the features of Smoothbook just click the next button below</p>",
      backdrop: true
    }, {
      title: 'Charging for services',
      element: '.charging-icon-container',
      content: "<p>Charging for appointments is very configurable in Smoothbook: you can charge per individual appointment or " + "create packages: a set number of appointments for a given price. So you could offer your users 10 appointments for the price" + "of 8 for example; Smoothbook will track your users credits as they use them.</p>" + "<p>You can create memberships / subscriptions for your users meaning they are given unlimited access to selected services and / or " + "awarded packages automatically when they pay a recurring monthly fee.</p>" + "<p>You can create as many payment methods as you like and restrict access to certain deals (or classes) and offer promotions" + " by creating coupon codes.</p><p>Smoothbook integrates seamlessly with Stripe - a highly regarded payments processor.</p>",
      backdrop: true,
      placement: 'left'
    }, {
      title: 'Customise your booking calendar',
      element: '.calendar-settings',
      content: "<p>Smoothbook offers different types of calendar depending on your needs whether you are offering classes or " + "have a large number of services. There are numerous options to customise the colours of your calendar and upload your logo.</p>",
      backdrop: true,
      placement: 'bottom'
    }, {
      title: 'Custom terms & conditions, emails and forms',
      element: '.email-container',
      content: "<p>You can completely customise your terms & conditions, as well as the confirmation, reminder and followup emails that " + "your users receive; you can customise the forms they complete when they book</p>",
      backdrop: true,
      placement: 'right'
    }, {
      title: 'Zoom, Mailchimp and Google Calendar Integration',
      element: '.mailchimp-integration',
      content: "<p>Zoom meetings and webinars can be created automatically when your customers book; your users can be " + "automatically added to Mailchimp newsletter lists.</p>" + "<p>Smoothbook integrates with Google calendar so that your bookings are instantly updated and viewable in your Google calendar</p>",
      backdrop: true,
      placement: 'top'
    }, {
      title: 'User access management',
      element: '.user-access-container',
      content: "<p>For larger businesses you can offer different members of your team different levels of access to the system.</p>" + "<p>For example you can make it so that people only have access to their own schedule and bookings; you can make it so that staff can take bookings and payment over the phone (but cannot modify system settings).</p>",
      backdrop: true,
      placement: 'left'
    }, {
      orphan: true,
      title: "Mobile Friendly",
      content: "<p>Smoothbook handles thousands of appointments per day and well over half are made via mobile device through our mobile friendly booking calendar</p>" + "<p>It is vital that your appointments system is mobile friendly! (Like Smoothbook).</p>",
      backdrop: true,
      onNext: function onNext() {
        smooth.dotour = true;
        document.location.href = '/#/setup';
        return new jQuery.Deferred().promise();
      }
    }, {
      element: "#my-other-element",
      title: "Title of my step",
      content: "Content of my step"
    }]
  });
  smooth.setupTour = new Tour({
    debug: true,
    template: template,
    storage: false,
    onEnd: function onEnd() {
      smooth.dotour = false;
    },
    steps: [{
      orphan: true,
      title: 'Calendar integration',
      element: '.integration-container',
      content: "<p>You can give your users access to your booking calendar in a variety of different ways:</p>" + "<p>Smoothbook has a Wordpress plugin to seamlessly embed your calendar in your Wordpress site; you can " + "add a 'book now' button to your Facebook page.</p>" + "<p>You can embed your calendar or add a 'book now' button into any HTML website; you can send out links in emails, etc. enabling your users to book simply" + " and easy any time of the day or night.</p>",
      backdrop: true,
      placement: 'left',
      onNext: function onNext() {
        smooth.dotour = true;
        document.location.href = '/#/scheduling/workshops';
        return new jQuery.Deferred().promise();
      }
    }, {
      element: "#my-other-element",
      title: "Title of my step",
      content: "Content of my step"
    }]
  });
  smooth.workshopTour = new Tour({
    template: template,
    storage: false,
    onEnd: function onEnd() {
      smooth.dotour = false;
    },
    steps: [{
      orphan: true,
      title: "Events and workshops",
      content: "<p>Smoothbook has built-in support for events and workshops meaning you can create a schedule that is only bookable " + "on the date(s) that you specify.</p>" + "<p>More than that, Smoothbook gives you great configurability for events and workshops, for example you can treat a " + "workshop as one bookable unit meaning your users can book (and pay for) a semester's worth of classes at one time. </p>",
      backdrop: true,
      placement: 'none',
      onNext: function onNext() {
        smooth.dotour = true;
        document.location.href = '/#/scheduling/weekly';
        return new jQuery.Deferred().promise();
      }
    }, {
      element: "#my-other-element",
      title: "Title of my step",
      content: "Content of my step"
    }]
  });
  smooth.schedulingTour = new Tour({
    template: template,
    storage: false,
    onEnd: function onEnd() {
      smooth.dotour = false;
    },
    steps: [{
      orphan: true,
      title: "Scheduling page",
      content: "<p>This is the scheduling page where you schedule what can be booked with who and when. " + "All of your initial setup can be managed on this page - we highly recommend that you begin your Smoothbook configuration here.</p>" + "<p>We recommend creating a schedule first (you can always change it) - our aim is that you can create your own working booking " + "calendar in five minutes or less. After that it is easy quick and easy to modify settings, set up charging and so on.</p>" + "<p>Once you have done that, you can go to the setup page to configure how you wish to integrate Smoothbook with your business.</p>" + "<p>Clicking next will end your tour of Smoothbook. Please use the 'questions / feedback / bugs button below if you have any questions" + " at all or feel free to contact us on support@smoothbook.co any time.</p>",
      backdrop: true,
      onNext: function onNext() {
        smooth.dotour = false;
      }
    }, {
      element: "#my-other-element",
      title: "Title of my step",
      content: "Content of my step"
    }]
  });
})();