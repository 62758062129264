appointmentExplorer.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "$timeout", "$sce", "$location"];

var moment = require('moment-timezone');

angular.module('userApp').directive('appointmentExplorer', appointmentExplorer);

var stripScripts = function stripScripts(a, b, c) {
  b = new Option();
  b.innerHTML = a;

  for (a = b.getElementsByTagName('script'); c = a[0];) {
    c.parentNode.removeChild(c);
  }

  return b.innerHTML;
};

function appointmentExplorer(ADMIN_URL, API_URL, $http, $q, $rootScope, $timeout, $sce, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/appointments/appointment-explorer.html',
    scope: {},
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber,
          userInfo = $rootScope.userInfo;

      scope.init = function () {
        scope.filters = {
          gethtml: true
        };
        scope.paginationObject = {
          page: 0,
          perpage: '10'
        };
        var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/services/' + userInfo.orgid)];
        $q.all(promises).then(function (replies) {
          scope.resources = replies[0].data.resources;
          scope.services = replies[1].data.services;
          scope.getAppointments();
        }); // Set up select 2 for customer search since there can be many of them

        $(function () {
          $timeout(function () {
            var token = localStorage.getItem('jwt-token'); // Rendering a dropdown for all customers when there were many was causing problems so
            // retrieve customers by ajax.

            $('.customer-select2').select2({
              placeholder: 'choose customer',
              ajax: {
                url: API_URL + '/api/v1/customer',
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("x-access-token", token);
                },
                data: function data(params) {
                  var query = {
                    firstname: params.term,
                    lastname: params.term
                  }; // Query parameters will be ?search=[term]&type=public

                  return query;
                },
                processResults: function processResults(data) {
                  scope.customers = data.customers;
                  return {
                    results: data.customers.map(function (customer) {
                      return {
                        id: customer._id,
                        text: "".concat(customer.lastname, ", ").concat(customer.firstname)
                      };
                    })
                  };
                }
              }
            });
          }, 0);
        });
      };

      scope.getAppointments = function (page) {
        var params = scope.filters;
        params.htmltype = 'appointment';
        params.page = typeof page !== 'undefined' ? parseInt(page) : scope.paginationObject.page;
        params.perpage = scope.paginationObject.perpage || 10; // Assign filters from the url if they exist

        var filters = $location.search();
        scope.filters.serviceid = scope.filters.serviceid || filters.serviceid || '0';

        if (scope.filters.serviceid !== '0') {
          params.serviceid = scope.filters.serviceid;
        }

        scope.filters.resourceid = scope.filters.resourceid || filters.resourceid || '0';

        if (scope.filters.resourceid !== '0') {
          params.resourceid = scope.filters.resourceid;
        }

        scope.filters.customerid = scope.filters.customerid || filters.customer || '0';

        if (scope.filters.customerid !== '0') {
          params.customerid = scope.filters.customerid;
        }

        if (scope.emailTo && scope.emailTo.length) {
          params.emailto = scope.emailTo;
        }

        if (scope.toCSV) {
          params.tocsv = true;
        } else if (params.tocsv) {
          delete params.tocsv;
        }

        throbber.show();
        var deferred = $q.defer();
        var options = {
          params: params
        };

        if (scope.topdf) {
          $http.defaults.headers.common['content-type'] = 'application/pdf';
          options.responseType = 'arraybuffer';
        }

        $http.get(API_URL + '/api/v1/appointment', options).then(function (reply) {
          if (scope.topdf) {
            throbber.hide();
            var file = new Blob([reply.data], {
              type: 'application/pdf'
            }),
                fileURL = URL.createObjectURL(file);
            deferred.resolve(fileURL);
            scope.topdf = false;
            return;
          }

          if (scope.toCSV) {
            throbber.hide();
            var blob = new Blob([reply.data.data], {
              type: 'text/csv;charset=utf-8;'
            }),
                filename = "smoothbook-appointments-".concat(moment().format('YYYY-MM-DD'), ".csv");

            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, filename);
            } else {
              var link = document.createElement("a");

              if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }

            scope.toCSV = false;
            return;
          }

          throbber.hide();
          var response = reply.data;

          if (!response.success) {
            throbber.showError('Sorry but there was an error with this request: ' + response.msg);
            return;
          }

          scope.paginationObject.count = parseInt(response.count);
          scope.paginationObject.page = parseInt(response.page);
          scope.appointments = response.appointments;
          scope.appointmentsTable = $sce.trustAsHtml(stripScripts(response.html));
          var filters = {};

          if (scope.filters.serviceid !== '0') {
            filters.serviceid = scope.filters.serviceid;
          }

          if (scope.filters.resourceid !== '0') {
            filters.resourceid = scope.filters.resourceid;
          }

          if (scope.filters.customerid !== '0') {
            filters.customerid = scope.filters.customerid;
          }

          $location.search(filters);
          deferred.resolve();
        });
        return deferred.promise;
      };

      scope.changeItemsToShow = function () {
        // Always start at the first page if this changes.
        scope.getAppointments(0);
      };

      scope.exportToCSV = function () {
        scope.toCSV = true;
        scope.getAppointments().then(function () {
          scope.toCSV = false;
        }).catch(function (error) {
          console.error(error.message);
          scope.toCSV = false;
        });
      };

      scope.emailAppointments = function () {
        scope.sendingEmail = true;
        scope.getAppointments().then(function (response) {
          scope.sendingEmail = false;
          scope.emailSuccess = true;
          scope.emailTo = '';
          $timeout(function () {
            scope.emailSuccess = false;
          }, 2000);
        });
      };

      scope.printAppointments = function () {
        window.print();
      };

      scope.getPDF = function () {
        scope.topdf = true;
        scope.getAppointments().then(function (response) {
          window.open(response);
        });
      };

      scope.init();

      scope.dateFromChanged = function (date) {
        if (!date) {
          delete scope.filters.startdate;
        } else {
          scope.filters.startdate = date;
        }

        scope.getAppointments();
      };

      scope.dateToChanged = function (date) {
        if (!date) {
          delete scope.filters.enddate;
        } else {
          scope.filters.enddate = date;
        }

        scope.getAppointments();
      };

      scope.removeFilter = function (item) {
        if (item === 'startdate') {
          delete scope.filters.startdate;
          return scope.dateFromChanged();
        }

        if (item === 'enddate') {
          delete scope.filters.enddate;
          return scope.dateFromChanged();
        }

        scope.filters[item] = '0';
        scope.getAppointments();
      };
    }
  };
}