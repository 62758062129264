import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
appointmentExplorerDeux.$inject = ["ADMIN_URL", "API_URL", "$http", "AppointmentExplorerService", "$rootScope", "$timeout", "$location"];

var moment = require('moment-timezone');

angular.module('userApp').directive('appointmentExplorerDeux', appointmentExplorerDeux);

function downloadCSV(csv, filename) {
  var csvFile, downloadLink; // CSV file

  csvFile = new Blob([csv], {
    type: "text/csv"
  }); // Download link

  downloadLink = document.createElement("a"); // File name

  downloadLink.download = "".concat(filename, ".csv"); // Create a link to the file

  downloadLink.href = window.URL.createObjectURL(csvFile); // Hide download link

  downloadLink.style.display = "none"; // Add the link to DOM

  document.body.appendChild(downloadLink); // Click download link

  downloadLink.click();
}

function exportTableToCSV(filename) {
  var csv = [],
      rows = document.querySelectorAll("table tr");

  for (var i = 0; i < rows.length; i++) {
    var row = [],
        cols = rows[i].querySelectorAll("td, th");

    for (var j = 0; j < cols.length; j++) {
      row.push("\"".concat(cols[j].innerText, "\""));
    }

    csv.push(row.join(","));
  } // Download CSV file


  downloadCSV(csv.join("\n"), filename);
}

function appointmentExplorerDeux(ADMIN_URL, API_URL, $http, AppointmentExplorerService, $rootScope, $timeout, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/appointments/appointment-explorer-deux.html',
    scope: {},
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber,
          userInfo = $rootScope.userInfo;

      scope.init = function () {
        scope.filters = {
          customerid: '0',
          serviceid: '0',
          resourceid: '0',
          showresource: true,
          showemail: true
        };
        scope.paginationObject = {
          page: 0,
          perpage: '10'
        };
        var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/services/' + userInfo.orgid), $http.get(API_URL + '/api/v1/settings/forms')];
        Promise.all(promises).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 3),
              resourceReply = _ref2[0].data,
              serviceReply = _ref2[1].data,
              formsReply = _ref2[2].data;

          scope.resources = resourceReply.resources;
          scope.services = serviceReply.services;
          scope.forms = formsReply.forms;
          scope.getAppointments();
        }); // Set up select 2 for customer search since there can be many of them

        $timeout(function () {
          var token = localStorage.getItem('jwt-token'); // Rendering a dropdown for all customers when there were many was causing problems so
          // retrieve customers by ajax.

          $('.customer-select2').select2({
            placeholder: 'choose customer',
            ajax: {
              url: API_URL + '/api/v1/customer',
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("x-access-token", token);
              },
              data: function data(params) {
                var query = {
                  firstname: params.term,
                  lastname: params.term
                }; // Query parameters will be ?search=[term]&type=public

                return query;
              },
              processResults: function processResults(data) {
                scope.customers = data.customers;
                return {
                  results: data.customers.map(function (customer) {
                    return {
                      id: customer._id,
                      text: "".concat(customer.lastname, ", ").concat(customer.firstname)
                    };
                  })
                };
              }
            }
          });
          $('.customer-select2').on('select2:select', function (e) {
            scope.filters.customerid = $('.customer-select2').find(':selected').val();
            scope.getAppointments();
          });
        }, 0);
      };

      scope.init();

      scope.changeItemsToShow = function () {
        // Always start at the first page if this changes.
        scope.getAppointments();
      };

      scope.exportToCSV = function () {
        exportTableToCSV('appointments');
      };

      scope.emailAppointments = function () {
        scope.sendingEmail = true;
        scope.getAppointments().then(function (response) {
          scope.sendingEmail = false;
          scope.emailSuccess = true;
          scope.emailTo = '';
          $timeout(function () {
            scope.emailSuccess = false;
          }, 2000);
        });
      };

      scope.printAppointments = function () {
        window.print();
      };

      scope.dateFromChanged = function (date) {
        if (!date) {
          delete scope.filters.datefrom;
        } else {
          scope.filters.datefrom = date;
        }

        scope.getAppointments();
      };

      scope.dateToChanged = function (date) {
        if (!date) {
          delete scope.filters.dateto;
        } else {
          scope.filters.dateto = date;
        }

        scope.getAppointments();
      };

      scope.removeFilter = function (item) {
        if (item === 'startdate') {
          delete scope.filters.datefrom;
          return scope.dateFromChanged();
        }

        if (item === 'enddate') {
          delete scope.filters.dateto;
          return scope.dateToChanged();
        }

        scope.filters[item] = '0';
        scope.getAppointments();
      };

      scope.getAppointments = function () {
        var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        return new Promise(function (resolve, reject) {
          var _scope$filters = scope.filters,
              serviceid = _scope$filters.serviceid,
              resourceid = _scope$filters.resourceid,
              customerid = _scope$filters.customerid,
              datefrom = _scope$filters.datefrom,
              dateto = _scope$filters.dateto,
              filters = {
            serviceid: serviceid,
            resourceid: resourceid,
            customerid: customerid,
            datefrom: datefrom,
            dateto: dateto
          };
          AppointmentExplorerService.getAppointments(_objectSpread({
            filters: filters
          }, scope.paginationObject, {
            page: page,
            forms: scope.forms
          })).then(function (response) {
            throbber.hide();
            var appointmentsObject = AppointmentExplorerService.getDataObject(response.appointments, {
              filters: scope.filters,
              forms: scope.forms
            }),
                tableHTML = AppointmentExplorerService.getDOMTable(appointmentsObject),
                tableContainer = document.getElementById('table-container');
            tableContainer.style.display = 'none';
            tableContainer.innerHTML = '';
            tableContainer.appendChild(tableHTML);
            scope.paginationObject.count = parseInt(response.count);
            scope.paginationObject.page = parseInt(response.page);
            scope.appointments = response.appointments;
            if (scope.appointments.length) tableContainer.style.display = 'block';
            var filters = {};

            if (scope.filters.serviceid !== '0') {
              filters.serviceid = scope.filters.serviceid;
            }

            if (scope.filters.resourceid !== '0') {
              filters.resourceid = scope.filters.resourceid;
            }

            if (scope.filters.customerid !== '0') {
              filters.customerid = scope.filters.customerid;
            }

            $location.search(filters);
            $rootScope.safeApply();
            resolve(response);
          }).catch(function (err) {
            throbber.showError(err.message);
          });
        });
      };
    }
  };
}