(function () {
  SettingsController.$inject = ["$timeout", "$rootScope"];
  angular.module('userApp').controller('SettingsController', SettingsController);

  function SettingsController($timeout, $rootScope) {
    var set = this,
        userInfo = $rootScope.userInfo; // Some settings are only accessible to owners

    set.isOwner = !!userInfo.roles.find(function (role) {
      return role.type === 'OWNER';
    });
    set.isAgent = !!userInfo.roles.find(function (role) {
      return role.type === 'AGENT';
    });
    set.isResource = !!userInfo.roles.find(function (role) {
      return role.type === 'RESOURCE';
    });

    if (smooth.dotour) {
      $timeout(function () {
        smooth.settingsTour.init();
        smooth.settingsTour.start();
      }, 0);
    }
  }
})();