import _objectSpread from "@babel/runtime/helpers/objectSpread";

(function () {
  ManageAppointmentsController.$inject = ["API_URL", "$http", "$q", "$rootScope", "$scope", "$filter", "$location", "$routeParams", "DaysToCountService", "TimeslotService"];
  angular.module('userApp').controller('ManageAppointmentsController', ManageAppointmentsController);

  function ManageAppointmentsController(API_URL, $http, $q, $rootScope, $scope, $filter, $location, $routeParams, DaysToCountService, TimeslotService) {
    var man = this;

    man.init = function () {
      man.badConfig = false;
      man.apptDisplay = {};
      man.user = $rootScope.userInfo;
      man.userIsResource = man.user.roles[0].type === 'RESOURCE';
      man.resourceID = '0';
      man.orgID = man.user.orgid;
      man.forms = {};
      man.settings = {};
      smooth.orgID = man.orgID;

      if ($rootScope.movingAppointments && $rootScope.movingAppointments.length) {
        man.movingAppts = $rootScope.movingAppointments;
      }

      var searchParams = $location.search();

      if (man.userIsResource && !searchParams.resourceid) {
        $location.search('resourceid', man.user.chosenresources[0]);
        return;
      }

      man.slotParams = _objectSpread({}, searchParams, {
        ignorebooked: true
      });

      if (searchParams.workshopsOnly) {
        man.slotParams.workshopsonly = true;
        man.workshopsOnly = true;
      } else {
        man.workshopsOnly = false;
      }

      if (searchParams.resourceid && searchParams.resourceid !== 1) {
        if (searchParams.resourceid === 'noresource') {
          man.slotParams.noresource = true;
          man.noResource = true;
        } else {
          man.resourceID = searchParams.resourceid;
          man.slotParams.resourceid = searchParams.resourceid;
          man.slotParams.noresource = false;
        }
      } else {
        man.resourceID = '0';
        delete man.resource;
      }

      if (searchParams.serviceid && searchParams.serviceid !== 1) {
        man.serviceID = searchParams.serviceid;
        man.slotParams.serviceid = searchParams.serviceid;
      } else {
        man.serviceID = '0';
      }

      $rootScope.throbber && $rootScope.throbber.show();
      man.loading = true;
      var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/services/' + smooth.orgID), $http.get(API_URL + '/api/v1/settings/forms'), $http.get(API_URL + '/api/v1/settings/calendar'), TimeslotService.getTimeslots(man.slotParams) // Gets timeslots and daysAvailable
      ];
      $q.all(promises).then(function (replies) {
        $rootScope.throbber && $rootScope.throbber.hide();
        man.loading = false;

        for (var i = 0; i < replies.length; i++) {
          var response = replies[i].data || null;

          if (response && !response.success) {
            console.error('Error: ' + response.msg);
            return;
          }
        }

        man.resources = replies[0].data.resources;

        if (!man.noResource) {
          man.resources.forEach(function (resource) {
            if (resource._id === man.resourceID) {
              man.resource = resource;
            }
          });
        } else {
          man.resource = 'noresource';
        }

        man.services = replies[1].data.services;
        man.forms = replies[2].data.forms;
        man.calendarSettings = replies[3].data.calendarsettings;
        man.days = replies[4].days;
        man.daysAvailable = replies[4].daysAvailable;
        man.showNext = true;
        man.showPrevious = true;

        if (!man.services || !man.services.length) {
          man.badConfig = true;
          return;
        }

        showNextPrevious();
        $rootScope.calendarSettings = man.settings.calendarSettings;
      }, function (error) {
        $rootScope.throbber && $rootScope.throbber.hide();
        man.loading = false;

        if (error && error.error === 'NO_SERVICES') {
          man.badConfig = true;
        }

        console.error('error: ' + error.msg);
      });
    }; // End init


    man.init();

    man.filterService = function () {
      if (man.serviceID === '0') return $location.search('serviceid', null);
      $location.search('serviceid', man.serviceID);
    };

    man.filterResource = function () {
      if (man.resourceID === '0') return $location.search('resourceid', null);
      $location.search('resourceid', man.resourceID);
    };

    man.filterWorkshops = function () {
      if (man.workshopsOnly) $location.search('workshopsOnly', 'true');else $location.search('workshopsOnly', null);
    }; // Date has changed in the date picker - now update the display.


    man.dateChanged = function (date) {
      $location.search('startdate', date);
      $rootScope.safeApply();
    };

    man.removeDateFilter = function (ev) {
      ev.preventDefault();
      $location.search('startdate', null);
    };

    man.cancelMove = function () {
      man.movingAppts = null;
      $rootScope.movingAppointments = null;
    };

    man.moveDays = function (direction) {
      var daysToMove = DaysToCountService.getDaysToCount(); // Determine startDate

      var startDate;

      if (direction === 'forward') {
        // Get the date from the last of the currently showing days
        startDate = man.days[man.days.length - 1].date; // Add one day as starting point

        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      } else {
        startDate = man.days[0].date;
      }

      if (moment(startDate).isSameOrBefore(man.daysAvailable[0]) || moment(startDate).isSameOrAfter(man.daysAvailable[man.daysAvailable.length - 1])) {
        return;
      }

      var params = {
        startdate: startDate,
        daystocount: daysToMove,
        direction: direction,
        showpast: true
      };

      if (man.serviceID && man.serviceID !== '0') {
        params.serviceid = man.serviceID;
      }

      if (man.resourceID && man.resourceID !== '0') {
        params.resourceid = man.resourceID;
      }

      $rootScope.throbber && $rootScope.throbber.show();
      $http.get(API_URL + '/api/v1/timeslots', {
        params: params
      }).then(function (reply) {
        $rootScope.throbber && $rootScope.throbber.hide();
        var response = reply.data;

        if (!response.success) {
          $rootScope.throbber && $rootScope.throbber.showError('Sorry but there was an error with this request: ' + response.msg);
          console.error(response.msg);
          return;
        }

        man.days = response.days;
        man.startDate = man.days.length ? moment(man.days[0].date) : moment();
        $location.search('startdate', man.days[0].date);
        showNextPrevious();
      }, function (error) {
        $rootScope.throbber.showError($filter('calendarLabel')('Sorry but an error occurred with this request'));
        throw new Error(error);
      });
    }; // end MoveDays


    function showNextPrevious() {
      if (!man.days || !man.days.length) return;
      var startDate = moment(man.days[0].date);
      man.showPrevious = startDate.isAfter(moment(man.daysAvailable[0]));
      var lastAvailableDay = moment(man.daysAvailable[man.daysAvailable.length - 1]);
      man.showNext = moment(man.days[man.days.length - 1].date).isBefore(lastAvailableDay);
    }
  }
})();