manageClassModal.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "$timeout", "$sce", "$location"];
angular.module('userApp').directive('manageClassModal', manageClassModal);

function manageClassModal(ADMIN_URL, API_URL, $http, $q, $rootScope, $timeout, $sce, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/classes/manage-class-modal.html',
    scope: {
      launchClassModal: '='
    },
    link: function link(scope, elem, attrs) {
      scope.launchClassModal = function (ev, classObject) {
        ev.stopPropagation();
        scope.class = classObject;
        scope.emailString = scope.class.students.reduce(function (accumulator, student) {
          return accumulator + student.email + ',';
        }, '?bcc=');
        scope.showEmail = true;
        scope.showPaymentInfo = false;
        scope.showWaitlist = true;
        scope.showRegInfo = false;
        scope.showCustomerComments = false;
        scope.showAppointmentComments = false;
        $('.manage-class-modal').modal('show');
      };

      scope.print = function () {
        window.print();
      };

      scope.$on('$locationChangeStart', function (event) {
        $('.manage-class-modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      });
    }
  };
}