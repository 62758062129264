addScheduleContainer.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout"];
angular.module('userApp').directive('addScheduleContainer', addScheduleContainer);

function addScheduleContainer(ADMIN_URL, API_URL, $http, $rootScope, $timeout) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/scheduling/add-schedule-container.html',
    replace: true,
    scope: {
      userIsResource: '=?',
      services: '=',
      updateChosenService: '=',
      resources: '=',
      resourceid: '=',
      changeResource: '=',
      toggleForm: '=',
      getShifts: '='
    },
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber;
      scope.form = {};

      function init() {
        assignTimes();
        scope.shiftTimes = {
          fromHour: '00',
          fromMinute: '00',
          toHour: '00',
          toMinute: '00',
          gapHours: '00',
          gapMinutes: '00',
          durationHours: '00',
          durationMinutes: '00'
        };
        scope.form.openings = 1;
        scope.form.bookmulti = 1; // Number of times the same slot can be booked by the same person

        scope.form.resourceid = scope.resourceid || '0';
      }

      init();

      scope.updateChosenService = function () {
        if (scope.services && scope.services.length > 0) {
          scope.form.serviceid = scope.services[0]._id;
        }
      };

      scope.updateResource = function () {
        // Get resource id and update display in parent
        scope.changeResource(scope.form.resourceid);
      };

      scope.$watch('services', scope.updateChosenService);

      scope.addShift = function () {
        scope.form.days = [];
        scope.form.type = 'weekly';

        for (var day in scope.days) {
          if (scope.days[day]) {
            scope.form.days.push(day);
          }
        }

        scope.form.duration = parseInt(scope.shiftTimes.durationHours) * 3600 + parseInt(scope.shiftTimes.durationMinutes) * 60; // Validate

        if (!validateShift()) return; // Format times

        scope.form.start = scope.shiftTimes.fromHour + ':' + scope.shiftTimes.fromMinute;
        scope.form.end = scope.shiftTimes.toHour + ':' + scope.shiftTimes.toMinute;
        scope.form.gap = scope.shiftTimes.gapHours * 3600 + scope.shiftTimes.gapMinutes * 60;
        throbber.show();
        $http.post(API_URL + '/api/v1/shift', {
          newshift: scope.form
        }).then(function (response) {
          throbber.hide();

          if (!response.data.success) {
            throbber.showError('Sorry but there was an error with this request: ' + response.data.msg);

            if (response.data.id === 'SHIFT_CONFLICTS') {
              $('.schedule-shift.shift-' + response.data.conflictshiftid).addClass('shift-conflicts');
            }

            $timeout(function () {
              scope.validationError = '';
              $('.schedule-shift.shift-' + response.data.conflictshiftid).removeClass('shift-conflicts');
            }, 6000);
            return;
          }

          var shiftNum = response.data.shifts.length;
          scope.successMessage = 'Shift' + (shiftNum ? 's' : '') + ' created successfully';
          $timeout(function () {
            successMessage = '';
          }, 3000);
          scope.getShifts();

          try {
            // Mixpanel
            var subscription = $rootScope.userInfo.subscription;

            if (smooth.env === 'production' && subscription.id !== 'FOREVER_FREE') {
              mixpanel.track("Shift added");
              ga('send', 'event', 'users', 'shift added');
            }
          } catch (err) {
            console.error("Mixpanel error: ".concat(err.message));
          }
        }, function (error) {
          throbber.hide();
          scope.validationError = 'Sorry but there was an unexpected error with this request: ' + error.statusText + ' Please try again and contact support@smoothbook.co if the problem persists.';
          $timeout(function () {
            validationError = '';
          }, 4000);
        });
      };

      function validateShift() {
        if (scope.shiftTimes.fromHour > scope.shiftTimes.toHour || scope.shiftTimes.fromHour == scope.shiftTimes.toHour && scope.shiftTimes.fromMinute >= scope.shiftTimes.toMinute) {
          scope.shiftLengthError = true;
          $timeout(function () {
            scope.shiftLengthError = false;
          }, 4000);
          return false;
        }

        if (!scope.form.days.length) {
          scope.shiftDaysError = true;
          $timeout(function () {
            scope.shiftDaysError = false;
          }, 4000);
          return false;
        }

        if (scope.form.duration <= 0) {
          scope.durationInvalid = true;
          $timeout(function () {
            scope.durationInvalid = false;
          }, 4000);
          return false;
        }

        return true;
      } // Make sure that 'to' time is after 'from' time


      scope.validateTime = function () {
        if (parseInt(scope.shiftTimes.fromHour) >= parseInt(scope.shiftTimes.toHour)) {
          scope.shiftTimes.toHour = scope.shiftTimes.fromHour;

          if (parseInt(scope.shiftTimes.fromMinute) >= parseInt(scope.shiftTimes.toMinute)) {
            scope.shiftTimes.toMinute = scope.shiftTimes.fromMinute;
          }
        }
      }; // Just create two arrays of hours and minutes for the dropdowns


      function assignTimes() {
        scope.selectHours = [];

        for (var i = 0; i < 24; i++) {
          var hour = i < 10 ? '0' + i : i;
          scope.selectHours.push(hour);
        }

        scope.selectMinutes = [];

        for (var i = 0; i < 60; i++) {
          var minute = i < 10 ? '0' + i : i;
          scope.selectMinutes.push(minute);
        }
      }

      scope.addResource = function (resource) {
        scope.resources.push(resource);
        scope.form.resourceid = resource._id;
      };

      scope.addService = function (service) {
        scope.services.push(service);
        scope.form.serviceid = service._id;
      };

      scope.addServiceFunction = scope.addService;

      scope.openingsChange = function () {
        if (scope.form.bookmulti > scope.form.openings) {
          scope.form.bookmulti = scope.form.openings;
        }
      };

      scope.bookMultiChange = function () {
        if (scope.form.openings < scope.form.bookmulti) {
          scope.form.openings = scope.form.bookmulti;
        }
      };
    }
  };
}