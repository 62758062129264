import _objectSpread from "@babel/runtime/helpers/objectSpread";

(function () {
  ResetPasswordController.$inject = ["BASE_URL", "$http", "$timeout", "$routeParams", "$location"];
  ForgotPasswordController.$inject = ["API_URL", "ADMIN_URL", "$http", "$routeParams", "$location", "$timeout"];
  angular.module('userApp').controller('ForgotPasswordController', ForgotPasswordController).controller('ResetPasswordController', ResetPasswordController);

  function ForgotPasswordController(API_URL, ADMIN_URL, $http, $routeParams, $location, $timeout) {
    var fog = this;
    var queryParams = $location.search(),
        client_id = queryParams.client_id,
        partner_id = queryParams.partner_id,
        redirect_url = queryParams.redirect_url;
    fog.backToOauth = !!queryParams.client_id;
    fog.integration = _objectSpread({}, queryParams);

    fog.resetPassword = function () {
      fog.loading = true;
      fog.infoMessage = '';
      fog.errorMessage = '';
      $http.post(API_URL + '/userauth/forgotpassword', {
        emailaddress: fog.emailAddress
      }).then(function (reply) {
        var response = reply.data;
        fog.loading = false;

        if (!response.success) {
          fog.errorMessage = response.msg || 'Sorry but there was an error with this request. Please contact support@smoothbook.co';
        } else {
          fog.infoMessage = "An email with instructions to reset your password has been sent to " + fog.emailAddress + ". " + "Please check your spam folder if you don't receive the email within a minute or two.";

          if (fog.backToOauth) {
            fog.infoMessage += " Now redirecting back to MoveGB connection page";
            $timeout(function () {
              window.location = ADMIN_URL + "/#/oauth2/authorize?client_id=".concat(fog.integration.client_id, "&partner_id=").concat(fog.integration.partner_id, "&redirect_url=").concat(fog.integration.redirect_url);
            }, 4000);
          }
        }
      });
    };
  }

  function ResetPasswordController(BASE_URL, $http, $timeout, $routeParams, $location) {
    var res = this,
        resetKey = $routeParams.resetkey;
    res.keyValid = false; // Remove any existing access token

    localStorage.removeItem('jwt-token'); // Check reset key

    $http.get(BASE_URL + '/userauth/verifyresetkey/' + resetKey).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        res.failMessage = response.msg;
        return;
      }

      res.keyValid = true;
    });

    res.resetPassword = function () {
      if (res.password !== res.repeat) {
        res.noMatch = true;
        $timeout(function () {
          res.noMatch = false;
        }, 2000);
        return;
      }

      $http.post(BASE_URL + '/userauth/resetpassword/', {
        newpassword: res.password,
        resetkey: resetKey
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          res.failMessage = response.msg;
          return;
        }

        res.infoMessage = 'Your password has been reset correctly. You will now be redirected to the ' + 'dashboard, please wait.';
        $timeout(function () {
          // Success. Token will be stored in local storage, redirect to dashboard.
          $location.path('/login');
        }, 4000);
      });
    };
  }
})();