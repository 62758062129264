(function () {
  scheduleManager.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "SchedulingService"];
  angular.module('directive.schedule-manager', []).directive('scheduleManager', scheduleManager);

  function scheduleManager(ADMIN_URL, API_URL, $http, $q, $rootScope, SchedulingService) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/scheduling/schedule-manager.html',
      scope: {
        shifts: '=',
        services: '=',
        resources: '=',
        updateShifts: '=',
        chosenResource: '='
      },
      link: function link(scope, elem, attrs) {
        var _serviceColours = [];
        scope.selectedShifts = [];
        scope.noShifts = true;
        scope.orgNeedsSetup = $rootScope.orgNeedsSetup;

        scope.deleteShifts = function () {
          if (!confirm('Please confirm you would like to delete these shifts')) return;
          var promises = [];
          scope.selectedShifts.forEach(function (shift) {
            promises.push($http.delete(API_URL + '/api/v1/shift/' + shift._id));
          });
          $rootScope.throbber.show();
          $q.all(promises).then(function (responses) {
            $rootScope.throbber.hide();
            var success = true,
                message;

            for (var i = 0; i < responses.length; i++) {
              if (!responses[i].data.success) {
                success = false;
                message = responses[i].data.msg;
                break;
              }
            }

            if (!success) {
              $rootScope.throbber.showError('Sorry there was an error with this request: ' + message);
              return;
            } // Do this in a way that does not reload the page


            scope.selectedShifts.forEach(function (shift) {
              var day = shift.day;

              for (var i = 0; i < scope.weeklyShifts[day].length; i++) {
                if (shift._id === scope.weeklyShifts[day][i]._id) {
                  scope.weeklyShifts[day].splice(i, 1);

                  if (!scope.weeklyShifts[day].length) {
                    delete scope.weeklyShifts[day];

                    if (!Object.keys(scope.weeklyShifts).length) {
                      scope.weeklyShifts = {};
                      scope.noShifts = true;
                    }
                  }

                  break;
                }
              }
            });
            scope.selectedShifts = [];
          });
        };

        scope.selectShift = function ($index, day, shift) {
          scope.weeklyShifts[day][$index].selected = true;
          scope.selectedShifts.push(shift);
        };

        scope.unselectShift = function ($index, day, shiftID) {
          scope.weeklyShifts[day][$index].selected = false;

          for (var i = 0; i < scope.selectedShifts.length; i++) {
            if (scope.selectedShifts[i]._id === shiftID) {
              scope.selectedShifts.splice(i, 1);
              break;
            }
          }
        };

        scope.$watch('shifts', function () {
          if (scope.shifts && scope.shifts.length) {
            scope.noShifts = false;
            assignServiceColours();
            initShifts(scope.shifts);
          }
        });

        var initShifts = function initShifts(shifts) {
          // We've got all the info we need, now generate the schedule
          if (shifts && shifts.length) {
            for (var i = 0; i < shifts.length; i++) {
              shifts[i].timeslots = SchedulingService.getTimeslots(shifts[i]);
            } // Group the shifts by day


            shifts = assignColours(shifts);
            scope.weeklyShifts = SchedulingService.groupShifts(shifts);
            scope.noShifts = Object.keys(scope.weeklyShifts).length < 1 ? true : false;
          } else {
            scope.weeklyShifts = [];
            scope.noShifts = true;
          }
        }; // Create a list of colours and assign one for each service


        var assignServiceColours = function assignServiceColours() {
          // Colour the shifts
          var colours = ['#FFD119', 'cornflowerBlue', 'DarkGoldenRod', 'Gold', 'GreenYellow', 'Khaki', 'Wheat', 'LightSalmon', 'Aqua'];
          var colourIndex = 0;
          scope.services.forEach(function (service) {
            _serviceColours[service._id] = colours[colourIndex++];
            colourIndex = colourIndex === colours.length ? 0 : colourIndex;
          });
        };

        var assignColours = function assignColours(shifts) {
          for (var i = 0; i < shifts.length; i++) {
            shifts[i].serviceid.colour = _serviceColours[shifts[i].serviceid._id];
          }

          return shifts;
        };

        scope.cancelSelect = function () {
          for (var key in scope.weeklyShifts) {
            for (var i = 0; i < scope.weeklyShifts[key].length; i++) {
              scope.weeklyShifts[key][i].selected = false;
            }
          }

          scope.selectedShifts = [];
        };
      }
    };
  }
})();