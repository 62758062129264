import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
SubscribeController.$inject = ["API_URL", "$http", "$rootScope", "$timeout"];
angular.module('userApp').controller('SubscribeController', SubscribeController);

function SubscribeController(API_URL, $http, $rootScope, $timeout) {
  var sub = this,
      user = $rootScope.userInfo,
      throbber = $rootScope.throbber;
  sub.showPaymentModal = false;
  sub.paymentSuccess = false;
  sub.updateSuccess = false;
  sub.subscription = null;
  sub.durationYearly = false;

  sub.updateDuration = function () {}; // Retrieve any existing subs


  sub.init = function () {
    sub.loading = true;
    sub.isOwner = $rootScope.userInfo.roles.find(function (role) {
      return role.type === 'OWNER';
    });
    var promises = [$http.get(API_URL + '/api/v1/subscriptions'), $http.get(API_URL + '/api/v1/subscriptiontypes'), $http.get(API_URL + '/api/v1/invoices/unpaid-invoices')];
    Promise.all(promises).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          customerSub = _ref2[0].data,
          smoothSubscriptions = _ref2[1].data,
          unpaidInvoices = _ref2[2].data.outstandingInvoices;

      sub.subscriptions = customerSub.subscriptions; // Subscriptions which the customer owns
      // Does the customer have any paid subs?

      sub.hasPaidSubscription = sub.subscriptions.find(function (subby) {
        return subby.smoothid !== 'BASIC' && subby.status === 'ACTIVE' && !subby.on_trial;
      });
      sub.subscriptionTypes = smoothSubscriptions.subscriptionTypes; // Available subscriptions

      sub.outstandingInvoices = unpaidInvoices;
      sub.loading = false;
      $rootScope.safeApply();
    }).catch(function (err) {
      sub.loading = false;
      $rootScope.throbber.showError(err.message);
    });
  };

  sub.init();

  sub.togglePaymentModal = function (show, subType, $ev) {
    sub.subType = subType;
    $ev && $ev.preventDefault();
    sub.showPaymentModal = show;
    if (!show) sub.paymentSuccess = false;
  };

  sub.paymentSuccessCallback = function () {
    sub.init();
    sub.paymentSuccess = true;

    if (smooth.env === 'production') {
      ga('send', 'event', 'users', 'user subscribed');
    }
  };

  sub.cancelSubscription = function (subId) {
    if (!confirm('Are you sure that you\'d like to cancel this subscription?')) return;
    throbber.show();
    $http.delete("".concat(API_URL, "/api/v1/subscriptions/").concat(subId)).then(function (_ref3) {
      var data = _ref3.data;

      if (data.success) {
        throbber.hide('Your subscription has been successfully canceled'); // Give some time for the subscription cancelled webhook to be handled

        $timeout(function () {
          sub.init();
        }, 3000);
        return;
      }

      throbber.showError("Sorry but an error has occurred: ".concat(data.msg));
    }).catch(function (err) {
      throbber.showError(err.message);
    });
  };

  sub.toggleCardUpdateModal = function (show) {
    sub.showCardUpdateModal = show;

    if (!show) {
      sub.updateSuccess = false;
    }
  };

  sub.updateCardSuccessCallback = function () {
    sub.updateSuccess = true;
  };

  sub.payInvoice = function (invoice) {
    sub.showUpdateCardModal(invoice);
  };

  sub.cancelInvoice = function (invoiceID) {
    if (!confirm('Are you sure you\'d like to delete this invoice?')) return;
    throbber.show();
    $http.delete("".concat(API_URL, "/api/v1/invoices/").concat(invoiceID)).then(function (_ref4) {
      var data = _ref4.data;
      if (!data.success) return throbber.showError("Sorry but an error has occurred: ".concat(data.msg));
      throbber.hide('Invoice successfully cancelled');
      sub.init();
    }).catch(function (err) {
      throbber.showError("Sorry but an error has occurred: ".concat(err.message));
    });
  };

  sub.toggleTrans = function (index) {
    $('.history-row').fadeOut(function () {
      $(".transactions-row-".concat(index)).fadeToggle();
    });
  };

  sub.toggleHistory = function (index) {
    $('.transactions-row').fadeOut(function () {
      $(".history-row-".concat(index)).fadeToggle();
    });
  };
}