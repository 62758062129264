(function () {
  addCustomer.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
  angular.module('directive.addCustomer', []).directive('addCustomer', addCustomer);

  function addCustomer(ADMIN_URL, API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        customerList: '=',
        saveCustomer: '=',
        customerSaved: '=',
        showAddCustomerModal: '='
      },
      templateUrl: ADMIN_URL + '/templates/directives/add-customer.html',
      link: function link(scope, elem, attrs) {
        var throbber = $rootScope.throbber;

        scope.showAddCustomerModal = function () {
          scope.customer = {
            reginfo: {}
          };
          $('.add-customer-modal').modal('show');
        };

        scope.saveCustomer = function () {
          throbber.show();
          getRecaptchaToken().then(function (token) {
            $http.post(API_URL + '/api/v1/customer', {
              newCustomer: scope.customer,
              token: token
            }).then(function (reply) {
              var response = reply.data;

              if (!response.success) {
                throbber.showError(response.msg);
                return;
              }

              scope.customerSaved();
              throbber.hide('Customer created successfully');
            });
          });
        }; // Get registration form for customer


        $http.get(API_URL + '/api/v1/settings/forms').then(function (response) {
          scope.regoForm = response.data.forms.registration;
        });
      }
    };
  }

  var getRecaptchaToken = function getRecaptchaToken() {
    return new Promise(function (resolve, reject) {
      grecaptcha.execute('6Ldq-K8UAAAAAJtC6lVsC9Rc-FJXd8UNDphcYtav', {
        action: 'homepage'
      }).then(function (token) {
        resolve(token);
      });
    });
  };
})();