(function () {
  EugdpController.$inject = ["API_URL", "ADMIN_URL", "$rootScope", "$http", "$timeout", "$cookies", "$window", "$location"];
  angular.module('userApp').controller('EugdpController', EugdpController);

  function EugdpController(API_URL, ADMIN_URL, $rootScope, $http, $timeout, $cookies, $window, $location) {
    var eugdp = this;
    eugdp.isOwner = false;
    eugdp.destroyOrg = false;
    $rootScope.$watch('userInfo', function () {
      if ($rootScope.userInfo) {
        eugdp.userInfo = $rootScope.userInfo;
        eugdp.isOwner = false;
        eugdp.userInfo.roles.forEach(function (role) {
          if (role.type === 'OWNER') {
            eugdp.isOwner = true;
          }
        });
      }
    });

    eugdp.doConsent = function () {
      $rootScope.throbber.show();
      $http.put(API_URL + '/api/v1/user/' + eugdp.userInfo._id, {
        userinfo: {
          eugdprconsent: true,
          newtoken: true
        }
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          return $rootScope.throbber.showError(response.msg);
        }

        $rootScope.throbber.hide('EUGDPR consent updated');
        $timeout(function () {
          $location.path('/dashboard');
        }, 2000);
      });
    };

    eugdp.warnDelete = function () {
      eugdp.deleting = true;
    };

    eugdp.cancelDelete = function () {
      eugdp.deleting = false;
    };

    eugdp.typeDelete = function () {
      eugdp.enableDelete = eugdp.deleteText === 'DELETE';
    };

    eugdp.doDelete = function () {
      eugdp.loading = true;
      var url = API_URL + '/api/v1/user/account/delete' + (eugdp.destroyOrg ? '?destroyorg=1' : '');
      $http.delete(url).then(function (reply) {
        eugdp.loading = false;
        eugdp.deleting = false;
        var response = reply.data;
        eugdp.deleteMessage = true;
        localStorage.removeItem('jwt-token');
        $cookies.remove('x-access-token');
        $timeout(function () {
          eugdp.deleteMessage = false;
          $window.location.href = ADMIN_URL + '/#/login';
        }, 3000);
      });
    };
  }
})();