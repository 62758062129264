angular.module('directive.imageManager', []).directive('imageManager', ["Upload", "$http", "ADMIN_URL", "API_URL", "$timeout", function (Upload, $http, ADMIN_URL, API_URL, $timeout) {
  return {
    restrict: 'EA',
    scope: {
      images: '=',
      apiPath: '@',
      entityId: '@'
    },
    templateUrl: ADMIN_URL + '/templates/directives/imageManager.html',
    link: function link(scope, elem, attrs) {
      scope.imageUpload = function (file) {
        if (!file) {
          return;
        }

        scope.uploading = true;
        Upload.upload({
          url: API_URL + scope.apiPath + '/' + scope.entityId,
          data: {
            file: file,
            'entityid': scope.entityId
          }
        }).then(function (reply) {
          var response = reply.data;
          scope.uploading = false;

          if (!response.success) {
            scope.uploadFailureMessage = response.msg;
            $timeout(function () {
              scope.uploadFailureMessage = false;
            }, 4000);
            return;
          }

          scope.uploadSuccess = true;
          $timeout(function () {
            scope.uploadSuccess = false;
          }, 3000);
          scope.images = response.image;
        }, function (resp) {
          console.error(resp.data.msg);
          scope.uploading = false;
          scope.uploadFailureMessage = resp.data.msg;
          $timeout(function () {
            scope.uploadFailureMessage = false;
          }, 4000);
        }, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total); // console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
      };

      scope.deleteImage = function ($event) {
        $event.preventDefault();

        if (!confirm('Please confirm you would like to delete this image')) {
          return;
        }

        $http.delete(API_URL + scope.apiPath + '/' + scope.entityId).then(function (response) {
          if (!response.data.success) {
            return;
          } else {
            scope.images = {};
          }
        });
      };
    }
  };
}]);