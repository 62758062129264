(function () {
  editScheduleModal.$inject = ["ADMIN_URL", "API_URL", "$rootScope", "$http", "$q", "$timeout"];
  angular.module('userApp').directive('editScheduleModal', editScheduleModal);

  function editScheduleModal(ADMIN_URL, API_URL, $rootScope, $http, $q, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/scheduling/edit-schedule-modal.html',
      scope: {
        services: '=',
        showModal: '=',
        selectedShifts: '=',
        getShifts: '='
      },
      link: function link(scope, elem, attrs) {
        scope.showModal = function () {
          init();
          $('.edit-shift-modal', elem).modal('show');
        };

        var throbber = $rootScope.throbber;
        scope.form = {};

        function init() {
          assignTimes();
          var shift = scope.selectedShifts[0];
          var start = shift.timeslots[0].start.split(':'),
              end = shift.timeslots[shift.timeslots.length - 1].end.split(':');
          start[0] = start[0].length == 1 ? '0' + start[0] : start[0];
          end[0] = end[0].length == 1 ? '0' + end[0] : end[0]; // Duration and gap are stored as seconds

          var gapHours = String(Math.floor(shift.gap / 3600)),
              gapMinutes = String(shift.gap % 3600 / 60);
          gapHours = gapHours.length == 1 ? '0' + gapHours : gapHours;
          gapMinutes = gapMinutes.length == 1 ? '0' + gapMinutes : gapMinutes;
          var durationHours = String(Math.floor(shift.duration / 3600)),
              durationMinutes = String(shift.duration % 3600 / 60);
          durationHours = durationHours.length == 1 ? '0' + durationHours : durationHours;
          durationMinutes = durationMinutes.length == 1 ? '0' + durationMinutes : durationMinutes;
          scope.shiftTimes = {
            fromHour: start[0],
            fromMinute: start[1],
            toHour: end[0],
            toMinute: end[1],
            gapHours: gapHours,
            gapMinutes: gapMinutes,
            durationHours: durationHours,
            durationMinutes: durationMinutes
          };
          scope.form = {
            openings: shift.openings,
            bookmulti: shift.bookmulti,
            serviceid: shift.serviceid._id,
            resourceid: shift.hasresource ? shift.resourceid : null
          };
        }

        scope.updateChosenService = function () {
          if (scope.services && scope.services.length > 0) {
            scope.form.serviceid = scope.services[0]._id;
          }
        };

        scope.updateResource = function () {
          // Get resource id and update display in parent
          scope.changeResource(scope.form.resourceid);
        };

        scope.updateShifts = function () {
          scope.form.type = 'weekly';
          scope.form.duration = parseInt(scope.shiftTimes.durationHours * 3600) + scope.shiftTimes.durationMinutes * 60; // Validate

          if (!validateShift()) return; // Format times

          scope.form.start = scope.shiftTimes.fromHour + ':' + scope.shiftTimes.fromMinute;
          scope.form.end = scope.shiftTimes.toHour + ':' + scope.shiftTimes.toMinute;
          scope.form.gap = scope.shiftTimes.gapHours * 3600 + scope.shiftTimes.gapMinutes * 60; // Build an array of promises

          var promises = [];
          scope.selectedShifts.forEach(function (shift) {
            var newShift = angular.copy(scope.form);
            newShift.days = [shift.day];
            promises.push($http.put(API_URL + '/api/v1/shift/' + shift._id, {
              newshift: newShift
            }));
          });
          throbber.show();
          $q.all(promises).then(function (replies) {
            throbber.hide();

            for (var i = 0; i < replies.length; i++) {
              var response = replies[i].data;

              if (!response.success) {
                throbber.showError('Sorry but there was an error with this request: ' + response.msg);

                if (response.id === 'SHIFT_CONFLICTS') {
                  $('.schedule-shift.shift-' + response.conflictshiftid).addClass('shift-conflicts');
                }

                $timeout(function () {
                  scope.validationError = '';
                  $('.schedule-shift.shift-' + response.conflictshiftid).removeClass('shift-conflicts');
                }, 6000);
                return;
              }
            } // End for


            $('.edit-shift-modal', elem).modal('hide');
            scope.selectedShifts = [];
            scope.getShifts();
          }, function (error) {
            throbber.hide();
            scope.validationError = 'Sorry but there was an unexpected error with this request: ' + error.statusText + ' Please try again and contact support@smoothbook.co if the problem persists.';
            $timeout(function () {
              validationError = '';
            }, 4000);
          });
        };

        function validateShift() {
          if (parseInt(scope.shiftTimes.fromHour) > parseInt(scope.shiftTimes.toHour) || parseInt(scope.shiftTimes.fromHour) == parseInt(scope.shiftTimes.toHour) && parseInt(scope.shiftTimes.fromMinute) >= parseInt(scope.shiftTimes.toMinute)) {
            scope.shiftLengthError = true;
            $timeout(function () {
              scope.shiftLengthError = false;
            }, 4000);
            return false;
          }

          if (scope.form.duration <= 0) {
            scope.durationInvalid = true;
            $timeout(function () {
              scope.durationInvalid = false;
            }, 4000);
            return false;
          }

          return true;
        } // Make sure that 'to' time is after 'from' time


        scope.validateTime = function () {
          if (parseInt(scope.shiftTimes.fromHour) >= parseInt(scope.shiftTimes.toHour)) {
            scope.shiftTimes.toHour = scope.shiftTimes.fromHour;

            if (parseInt(scope.shiftTimes.fromMinute) >= parseInt(scope.shiftTimes.toMinute)) {
              scope.shiftTimes.toMinute = scope.shiftTimes.fromMinute;
            }
          }
        }; // Just create two arrays of hours and minutes for the dropdowns


        function assignTimes() {
          scope.selectHours = [];

          for (var i = 0; i < 24; i++) {
            var hour = i < 10 ? '0' + i : i;
            scope.selectHours.push(hour);
          }

          scope.selectMinutes = [];

          for (var i = 0; i < 60; i++) {
            var minute = i < 10 ? '0' + i : i;
            scope.selectMinutes.push(minute);
          }
        }

        scope.openingsChange = function () {
          if (scope.form.bookmulti > scope.form.openings) {
            scope.form.bookmulti = scope.form.openings;
          }
        };

        scope.bookMultiChange = function () {
          if (scope.form.openings < scope.form.bookmulti) {
            scope.form.openings = scope.form.bookmulti;
          }
        };
      }
    };
  }
})();