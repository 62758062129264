CalendarIntegrationController.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout", "$sce"];
import { iframeResizer } from 'iframe-resizer';
angular.module('userApp').controller('CalendarIntegrationController', CalendarIntegrationController);

function CalendarIntegrationController(ADMIN_URL, API_URL, $http, $rootScope, $timeout, $sce) {
  var int = this,
      throbber = $rootScope.throbber;
  var iFrameResize = iframeResizer;
  int.base_url = smooth.base_url;
  int.orgID = $rootScope.userInfo.orgid;

  int.init = function () {
    $http.get(API_URL + '/api/v1/settings/calendar').then(function (reply) {
      throbber.hide();
      var response = reply.data;

      if (!response.success) {
        throbber.showError(response.msg);
        return;
      }

      int.settings = response.calendarsettings;
      int.calendarUrl = $sce.trustAsResourceUrl("".concat(smooth.calendar_url, "/").concat(int.settings.slug ? int.settings.slug : int.orgID)); // int.calendarUrl = smooth.calendar_url + '/' + (int.settings.slug ? int.settings.slug : int.orgID);

      int.embedCode = '<script src="' + smooth.base_url + '/lib/calendar-embed.js"></script><a class="sb-calendar" data-embed="true" href="' + int.calendarUrl + '">' + '<img src="' + smooth.base_url + '/img/book-now-button.png" alt="Online appointments by Smoothbook"><a/>';
      int.classEmbedCode = '<script src="' + smooth.base_url + '/lib/calendar-embed.js"></script><a class="sb-calendar" data-embed="true" href="' + int.calendarUrl + '/#/class">' + '<img src="' + smooth.base_url + '/img/book-now-button.png" alt="Online appointments by Smoothbook"><a/>';
      int.serviceEmbedCode = '<script src="' + smooth.base_url + '/lib/calendar-embed.js"></script><a class="sb-calendar" data-embed="true" href="' + int.calendarUrl + '/#/service">' + '<img src="' + smooth.base_url + '/img/book-now-button.png" alt="Online appointments by Smoothbook"><a/>';
      int.buttonCode = '<a href="' + int.calendarUrl + '" target="_blank"><img src="' + smooth.base_url + '/img/book-now-button.png" alt="Online appointments by Smoothbook"><a/>';
      $timeout(function () {
        initTabs();
        initIframe();
      }, 0);
    });
  };

  int.init();

  function initIframe() {
    // Refresh iframe src
    document.getElementById('calendar-embed-demo').src = document.getElementById('calendar-preview-iframe').src;
    iFrameResize({
      checkOrigin: false
    }, '.iframe-demo');
  }

  int.changeTab = function (calendarType) {
    throbber.show();
    $http.put(API_URL + '/api/v1/settings/calendar/' + int.settings._id, {
      settings: {
        calendartype: calendarType
      }
    }).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        throbber.showError('Sorry but there was an error with this request: ' + response.msg);
        return;
      }

      int.settings = response.calendarsettings;
      initIframe();
      throbber.hide('Settings updated');
    });
  };

  function initTabs() {
    $('.tab-select').on('click', function (ev) {
      ev.preventDefault();
      var $parentContainer = $(this).closest('.tab-container');
      $parentContainer.find('.nav-tabs > li').removeClass('active');
      $parentContainer.find('.tab-pane').removeClass('active');
      var panelID = $(this).attr('id');
      $parentContainer.find('.' + panelID).addClass('active');
      $(this).closest('li').addClass('active');
    });
  }
}