require('jquery-ui/ui/widgets/sortable');

(function () {
  formManager.$inject = ["API_URL", "$http", "$rootScope"];
  angular.module('directive.form-manager', []).directive('formManager', formManager);

  function formManager(API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        form: '=',
        formType: '@',
        serviceId: '=?'
      },
      templateUrl: '/templates/directives/form-manager.html',
      link: function link(scope, elem, attrs) {
        scope.$watch('serviceId', function () {
          scope.formUrl = '/' + scope.formType + (scope.serviceId ? '/' + scope.serviceId : '');
        });
        scope.sortableOptions = {
          update: function update(e, ui) {
            scope.saveForm();
          }
        };

        scope.addField = function () {
          scope.addingField = !scope.addingField;
        };

        scope.groupDirty = function (index) {
          scope.form[index].dirty = true;
        };

        scope.resetDirty = function () {
          for (var i = 0; i < scope.form.length; i++) {
            scope.form[i].dirty = false;
          }
        };

        scope.saveForm = function () {
          for (var i = 0; i < scope.form.length; i++) {
            delete scope.form[i].dirty;
          }

          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/settings/forms' + scope.formUrl, {
            form: scope.form
          }).then(function (response) {
            var data = response.data;

            if (!data.success) {
              $rootScope.throbber.showError(data.msg);
              return;
            }

            scope.form = data.form;
            $rootScope.throbber.hide('Form updated');
          }, function (error) {
            $rootScope.throbber.showError(error.statusMessage);
          });
        };

        scope.deleteItem = function (index) {
          if (!confirm('Are you sure you want to delete this field item?')) return;
          var tempForm = angular.copy(scope.form);
          tempForm.splice(index, 1);
          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/settings/forms' + scope.formUrl, {
            form: tempForm
          }).then(function (response) {
            var data = response.data;

            if (!data.success) {
              $rootScope.throbber.showError(data.msg);
              return;
            }

            scope.form = data.form;
            $rootScope.throbber.hide('Form item deleted');
          }, function (error) {
            $rootScope.throbber.showError(error.statusMessage);
          });
        };
      }
    };
  }
})();