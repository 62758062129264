(function () {
  EditResourceController.$inject = ["$scope", "$http", "API_URL", "$location", "$routeParams", "$timeout"];
  AddResourceController.$inject = ["$rootScope", "$http", "API_URL", "$location", "$timeout"];
  angular.module('userApp').controller('AddResourceController', AddResourceController).controller('EditResourceController', EditResourceController);

  function AddResourceController($rootScope, $http, API_URL, $location, $timeout) {
    var res = this;
    res.addingResource = true;
    res.form = {};

    res.saveResource = function () {
      $http.post(API_URL + '/api/v1/resource', {
        newresource: res.form
      }).then(function (response) {
        response = response.data;
        if (response.toomanyresources) return res.tooManyResources = true;

        if (response.success) {
          res.justCreated = true;
          $location.path('/editresource/' + response.resource._id + '/true');
        }
      }, function (response) {
        console.log(response);
      });
    }; // End saveResource

  }

  function EditResourceController($scope, $http, API_URL, $location, $routeParams, $timeout) {
    var res = this;
    res.resourceid = $routeParams.resourceid;

    if ($routeParams.created) {
      res.justCreated = true;
    }

    res.addingResource = false;
    res.form = {};

    res.init = function () {
      $scope.throbber && $scope.throbber.show();
      $http.get(API_URL + '/api/v1/resource/' + res.resourceid).then(function (response) {
        $scope.throbber && $scope.throbber.hide();
        var response = response.data;

        if (response.success) {
          res.form = {
            name: response.resource.name,
            email: response.resource.email,
            description: response.resource.description,
            images: response.resource.images
          };
        } else {
          res.errorMessage = response.msg;
          $timeout(function () {
            res.errorMessage = "";
          }, 4000);
        }
      }, function (error) {});
    };

    res.init();

    res.saveResource = function () {
      $scope.throbber && $scope.throbber.show();
      $http.put(API_URL + '/api/v1/resource/' + res.resourceid, {
        resource: res.form
      }).then(function (response) {
        response = response.data;

        if (!response.success) {
          $scope.throbber && $scope.throbber.showError(response.msg);
          return;
        }

        res.form = response.resource;
        $scope.throbber.hide('Resource updated');
      }, function (response) {
        console.log(response);
      });
    }; // End saveResource

  }
})();