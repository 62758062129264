(function () {
  workshopDaysChooser.$inject = ["API_URL", "$http", "CalendarService", "$rootScope"];
  // daysChooser - supply a calendar with checkboxes to easily choose a set of dates
  angular.module('directive.workshop-days-chooser', ['service.calendarService']).directive('workshopDaysChooser', workshopDaysChooser);

  function workshopDaysChooser(API_URL, $http, CalendarService, $rootScope) {
    return {
      restrict: 'EA',
      templateUrl: '/templates/directives/scheduling/workshops/workshop-days-chooser.html',
      replace: true,
      scope: {
        month: '@',
        year: '@',
        chosenDates: '=',
        type: '@',
        typeid: '@',
        dateChosen: '='
      },
      link: function link(scope, elem, attrs) {
        var throbber = $rootScope.throbber;
        scope.$watch(function () {
          init();
        });

        function init() {
          var monthObject = CalendarService.getMonthGrid(parseInt(scope.month), scope.year, scope.chosenDates);
          scope.rows = new Array(Math.floor(monthObject.dates.length / 7));
          scope.columns = new Array(7);
          scope.monthName = monthObject.month.name;
          scope.dates = monthObject.dates;
          scope.days = monthObject.days;
        }

        init();

        scope.toggleDate = function (date, selected) {
          if (!selected && scope.chosenDates.indexOf(date) < 0) {
            scope.chosenDates.push(date);
            scope.chosenDates.sort();
          } else {
            scope.chosenDates.splice(scope.chosenDates.indexOf(date), 1);
            scope.chosenDates.sort();
          }

          scope.dateChosen(scope.chosenDates);
        };
      }
    };
  }
})();