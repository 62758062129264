(function () {
  appointmentWidget.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
  angular.module('userApp').directive('appointmentWidget', appointmentWidget);

  function appointmentWidget(ADMIN_URL, API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/appointments/appointment-widget.html',
      scope: {
        showModal: '='
      },
      link: function link(scope, elem, attrs) {
        scope.showModal = function (appointment) {
          scope.appointment = appointment;
          var serviceID = appointment.serviceid._id;
          $('.modal.appointment-widget-modal').modal('show'); // Get form data for the appointment viewer

          $http.get(API_URL + '/api/v1/settings/forms').then(function (reply) {
            var response = reply.data,
                bookingForm = null;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but there was an error with this request: ' + response.msg);
              return;
            }

            bookingForm = response.forms.booking;
            scope.bookingForm = bookingForm ? bookingForm[serviceID] ? bookingForm[serviceID] : bookingForm.default : null;
          });
        };
      }
    };
  }
})();