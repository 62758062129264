(function () {
  EditServiceController.$inject = ["$scope", "$http", "API_URL", "$routeParams", "$timeout", "$rootScope"];
  AddServiceController.$inject = ["$http", "API_URL", "$location", "$timeout"];
  angular.module('userApp').controller('AddServiceController', AddServiceController).controller('EditServiceController', EditServiceController);

  function AddServiceController($http, API_URL, $location, $timeout) {
    var serv = this;
    serv.addingService = true;
    serv.form = {};
    serv.form.duration = 0;
    serv.form.gap = 0;
    serv.form.openings = 1;

    serv.saveService = function () {
      $http.post(API_URL + '/api/v1/service', {
        newservice: serv.form
      }).then(function (response) {
        response = response.data;
        if (response.toomanyservices) return serv.tooManyServices = true;

        if (response.success) {
          $location.path('/editservice/' + response.service._id + '/true');
          return;
        }
      }, function (response) {
        console.log(response);
      });
    }; // End saveService
    // Get whether Zoom is connected or not


    serv.zoomLoading = true;
    serv.zoomConnected = false;
    $http.get(API_URL + '/zoom/isconnected').then(function (_ref) {
      var data = _ref.data;
      serv.zoomLoading = false;
      serv.zoomConnected = data.connected;
    }).catch(function (err) {
      serv.zoomLoading = false;
      console.error("Zoom api error: ".concat(err.message));
    });
  }

  function EditServiceController($scope, $http, API_URL, $routeParams, $timeout, $rootScope) {
    var serv = this;
    serv.serviceid = $routeParams.serviceid;
    serv.addingService = false;
    serv.form = {};
    var userInfo = $rootScope.userInfo;

    if ($routeParams.created) {
      serv.justCreated = true;
    }

    serv.init = function () {
      serv.form.durationminutes = '0';
      serv.form.durationhours = '0';
      $rootScope.throbber && $rootScope.throbber.show();
      $http.get(API_URL + '/api/v1/service/' + serv.serviceid).then(function (response) {
        $rootScope.throbber.hide();
        var response = response.data;

        if (response.success) {
          serv.form = {
            name: response.service.name,
            waitlist: !!response.service.waitlist,
            // May be undefined
            duration: response.service.duration,
            gap: response.service.gap,
            openings: response.service.openings,
            description: response.service.description,
            instructions: '',
            images: response.service.images
          };

          if (typeof response.service.zoom === 'undefined') {
            serv.form.zoom = {
              enabled: false,
              password: false,
              meetingtype: 'meeting'
            };
          } else {
            serv.form.zoom = response.service.zoom;
          }

          serv.instructionsInit = response.service.instructions;
        } else {
          serv.form = false;
          serv.errorMessage = response.msg;
          $scope.throbber.showError(response.msg);
          $timeout(function () {
            serv.errorMessage = "";
          }, 4000);
        }
      }, function (error) {}); // Get whether Zoom is connected or not

      serv.zoomLoading = true;
      serv.zoomConnected = false;
      $http.get(API_URL + '/zoom/isconnected').then(function (_ref2) {
        var data = _ref2.data;
        serv.zoomLoading = false;
        serv.zoomConnected = data.connected;
      }).catch(function (err) {
        serv.zoomLoading = false;
        console.error("Zoom api error: ".concat(err.message));
      });
    };

    serv.init();

    serv.saveService = function () {
      $scope.throbber.show();
      $http.put(API_URL + '/api/v1/service/' + serv.serviceid, {
        service: serv.form
      }).then(function (response) {
        $scope.throbber.hide('Service updated');
        response = response.data;

        if (response.success) {
          serv.form = response.service;
        }
      }, function (response) {
        console.log(response);
      });
    }; // End saveService

  }
})();