dropdownPaginationWidget.$inject = ["ADMIN_URL", "$rootScope"];
angular.module('userApp').directive('dropdownPaginationWidget', dropdownPaginationWidget);

function dropdownPaginationWidget(ADMIN_URL, $rootScope) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/dropdown-pagination-widget.html',
    scope: {
      paginationObject: '=',
      changePage: '='
    },
    link: function link(scope, elem, attrs) {
      scope.settings = {
        currentPage: 1
      };

      function updateDropdown() {
        scope.pageCount = Math.ceil(scope.paginationObject.count / scope.paginationObject.perpage); // Ridiculousness for Angularjs

        scope.settings = {
          currentPage: String(scope.paginationObject.page + 1)
        };
        scope.pagesToShow = [];

        for (var i = 1; i <= scope.pageCount; i++) {
          scope.pagesToShow.push(i);
        }

        $rootScope.safeApply();
      }

      updateDropdown(); // More angularjs ridiculousness

      scope.$watch('paginationObject.perpage', function () {
        updateDropdown();
      });
      scope.$watch('paginationObject.count', function () {
        updateDropdown();
      });
      scope.$watch('paginationObject.page', function () {
        updateDropdown();
      });

      scope.first = function () {
        scope.changePage(0).then(function () {
          updateDropdown();
        });
      };

      scope.previous = function () {
        if (scope.paginationObject.page === 0) {
          return;
        }

        scope.changePage(scope.paginationObject.page - 1).then(function () {
          updateDropdown();
        });
      };

      scope.next = function () {
        if (scope.paginationObject.page + 1 >= scope.pageCount) {
          return;
        }

        scope.changePage(parseInt(scope.paginationObject.page) + 1).then(function () {
          updateDropdown();
        });
      };

      scope.last = function () {
        scope.changePage(scope.pageCount - 1).then(function () {
          updateDropdown();
        });
      };

      scope.enterPage = function (event) {
        if (event.which !== 13) return;
        scope.settings.currentPage = event.target.value;
        scope.changePage(scope.settings.currentPage - 1).then(function () {
          updateDropdown();
        });
      };

      scope.dropdownChange = function () {
        scope.changePage(scope.settings.currentPage - 1).then(function () {
          updateDropdown();
        });
      };
    }
  };
}