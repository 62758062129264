(function () {
  viewCalendarModal.$inject = ["ADMIN_URL", "$timeout", "$rootScope", "$sce"];

  var iFrameResize = require('iframe-resizer').iframeResizer;

  angular.module('userApp').directive('viewCalendarModal', viewCalendarModal);

  function viewCalendarModal(ADMIN_URL, $timeout, $rootScope, $sce) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/calendar/view-calendar-modal.html',
      scope: {
        viewBookingCalendar: '='
      },
      link: function link(scope, elem, attrs) {
        function initIframe() {
          iFrameResize({
            checkOrigin: false
          }, '.iframe-demo');
        }

        var init = function init() {
          scope.calendarUrl = $sce.trustAsResourceUrl("".concat(smooth.calendar_url, "/").concat($rootScope.userInfo.orgid));
          $timeout(function () {
            initIframe();
          }, 3000);
        };

        scope.viewBookingCalendar = function () {
          init();
          scope.showCalendar = true;
          document.getElementById('view-calendar-modal').style.visibility = 'visible';
          document.getElementById('view-calendar-modal').style['z-index'] = '100';
        };

        scope.closeModal = function () {
          scope.calendarUrl = $sce.trustAsResourceUrl("".concat(smooth.calendar_url));
          document.getElementById('view-calendar-modal').style.visibility = 'hidden';
          document.getElementById('view-calendar-modal').style['z-index'] = '-3';
        };
      }
    };
  }
})();