workshopListDays.$inject = ["API_URL", "$http", "CalendarService", "$rootScope", "$timeout"];
angular.module('userApp').directive('workshopListDays', workshopListDays);

function workshopListDays(API_URL, $http, CalendarService, $rootScope, $timeout) {
  return {
    restrict: 'EA',
    templateUrl: '/templates/directives/scheduling/workshops/workshop-list-days.html',
    replace: true,
    scope: {
      month: '@',
      year: '@',
      workshopList: '=',
      workshopObject: '='
    },
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber;
      scope.$watch('workshopList', function () {
        if (scope.workshopList.length) {
          getMonths();
        }
      });
      scope.$watch('month', function () {
        getMonths();
      });
      scope.$watch('year', function () {
        getMonths();
      });

      function getMonths() {
        var monthObject = CalendarService.getMonthGrid(parseInt(scope.month), scope.year, [], scope.workshopObject);
        scope.rows = new Array(Math.floor(monthObject.dates.length / 7));
        scope.columns = new Array(7);
        scope.monthName = monthObject.month.name;
        scope.dates = monthObject.dates;
        scope.days = monthObject.days;
        $timeout(function () {
          $('[data-toggle="tooltip"]').tooltip();
        }, 0);
      }

      getMonths();
    }
  };
}