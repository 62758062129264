angular.module('userApp').service('AuthService', ["$http", "$q", "API_URL", function ($http, $q, API_URL) {
  this.register = function (params, newsletterSubscribe, token) {
    var deferred = $q.defer();
    $http.post(API_URL + '/userauth/register', {
      userinfo: params,
      newslettersubscribe: newsletterSubscribe,
      token: token
    }).then(function (response) {
      deferred.resolve(response.data);
    }, function (response, status) {
      console.error('HTTP error: ' + status);
      deferred.reject(response.data);
    });
    return deferred.promise;
  };

  this.oauthAuthenticate = function (params, captchatoken, partner_id) {
    var deferred = $q.defer();
    $http.post(API_URL + '/oauth2/oauthverify', {
      user: params,
      captchatoken: captchatoken,
      partner_id: partner_id
    }).then(function (response) {
      deferred.resolve(response.data);
    }).catch(function (response) {
      console.error('HTTP error: ' + response.data.msg);
      deferred.resolve(response.data);
    });
    return deferred.promise;
  };

  this.login = function (params, token) {
    var deferred = $q.defer();
    $http.post(API_URL + '/userauth/login', {
      user: params,
      token: token
    }).then(function (response) {
      deferred.resolve(response.data);
    }, function (response, status) {
      console.error('HTTP error: ' + response.data.msg);
      deferred.reject(response.data);
    });
    return deferred.promise;
  };
}]);