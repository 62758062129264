updateCardModal.$inject = ["ADMIN_URL"];
angular.module('userApp').directive('updateCardModal', updateCardModal);

function updateCardModal(ADMIN_URL) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/subscriptions/update-card-modal.html',
    scope: {
      showUpdateCardModal: '=',
      activationSuccess: '='
    },
    link: function link(scope, elem, attrs) {
      scope.showUpdateCardModal = function () {
        var invoice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        scope.invoice = invoice;
        scope.invoiceID = !!invoice && invoice.id;
        scope.initComponent(invoice);
        $('.update-card-modal').modal();
      };
    }
  };
}