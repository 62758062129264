(function () {
  cloneScheduleModal.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "SchedulingService"];
  angular.module('userApp').directive('cloneScheduleModal', cloneScheduleModal);

  function cloneScheduleModal(ADMIN_URL, API_URL, $http, $q, $rootScope, SchedulingService) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/scheduling/clone-schedule-modal.html',
      scope: {
        showCloneScheduleModal: '=',
        resources: '=',
        resourceId: '@',
        updateShifts: '='
      },
      link: function link(scope, elem, attrs) {
        scope.form = {};

        scope.showCloneScheduleModal = function () {
          scope.resourceShifts = [];
          scope.selectedShifts = [];

          if (!scope.resourceId || scope.resourceId === '' || scope.resourceId === '0') {
            scope.hasResource = false;
          } else {
            scope.hasResource = true;
          } // Don't list the current resource in the resources dropdown


          scope.availableResources = [];
          scope.resources.forEach(function (resource) {
            if (resource._id !== scope.resourceId) {
              scope.availableResources.push(resource);
            }
          });
          $('.clone-schedule-modal', elem).modal('show');
        };

        scope.changeResource = function () {
          // Get the shifts for this resource
          var resourceID = scope.form.chosenResource,
              params = {};

          if (scope.form.chosenResource === '0') {
            params.hasresource = false;
          } else {
            params.resourceid = scope.form.chosenResource;
          }

          $rootScope.throbber.show();
          $http.get(API_URL + '/api/v1/shift', {
            params: params
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
              return;
            }

            initShifts(response.shifts);
            $rootScope.throbber.hide();
          }, function (error) {
            $rootScope.throbber.showError('Sorry but an error occurred with this request: ' + error.errorMessage);
          });
        }; // Bugger about getting the shifts into usable format


        var initShifts = function initShifts(shifts) {
          scope.resourceShifts = []; // We've got all the info we need, now generate the schedule

          if (shifts && shifts.length) {
            for (var i = 0; i < shifts.length; i++) {
              shifts[i].timeslots = SchedulingService.getTimeslots(shifts[i]);
            } // Group the shifts by day


            var newShifts = SchedulingService.groupShifts(shifts);

            for (var day in newShifts) {
              for (var i = 0; i < newShifts[day].length; i++) {
                var newShift = angular.copy(newShifts[day][i]);
                var start = newShift.timeslots[0].start,
                    end = newShift.timeslots[newShift.timeslots.length - 1].end;
                newShift.niceStart = start;
                newShift.niceEnd = end;
                newShift.day = day;
                scope.resourceShifts.push(newShift);
              }
            }

            scope.noShifts = Object.keys(scope.resourceShifts).length < 1 ? true : false;
          } else {
            scope.resourceShifts = [];
            scope.noShifts = true;
          }
        };

        scope.copyShifts = function () {
          var resourceID = scope.resourceId,
              promises = [];
          scope.selectedShifts.forEach(function (shift) {
            shift.resourceid = resourceID;
            shift.days = [shift.day];
            shift.start = shift.niceStart;
            shift.end = shift.niceEnd;
            shift.hasresource = true;
            delete shift._id;
            promises.push($http.post(API_URL + '/api/v1/shift', {
              newshift: shift
            }));
          });
          $rootScope.throbber.show();
          $q.all(promises).then(function (replies) {
            var success = true,
                message;
            replies.forEach(function (reply) {
              if (!reply.data.success) {
                success = false;
                message = reply.data.msg;
              }
            });

            if (!success) {
              $('.clone-schedule-modal').modal('hide');
              $rootScope.throbber.showError(message);
              return;
            }

            scope.updateShifts();
            $('.clone-schedule-modal').modal('hide');
            $rootScope.throbber.hide('Shifts copied successfully');
          }, function (error) {
            $rootScope.throbber.showError(error.errorMessage);
          });
        };

        scope.selectCopyShift = function ($index, shift) {
          scope.resourceShifts[$index].selected = true;
          scope.selectedShifts.push(shift);
        };

        scope.unselectCopyShift = function ($index, shiftID) {
          scope.resourceShifts[$index].selected = false;

          for (var i = 0; i < scope.selectedShifts.length; i++) {
            if (scope.selectedShifts[i]._id == shiftID) {
              scope.selectedShifts.splice(i, 1);
              break;
            }
          }
        };
      }
    };
  }
})();