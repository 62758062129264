(function () {
  singleWorkshopCharges.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
  angular.module('userApp').directive('singleWorkshopCharges', singleWorkshopCharges);

  function singleWorkshopCharges(ADMIN_URL, API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/settings/single-workshop-charges.html',
      scope: {
        workshops: '=',
        currency: '=',
        showCharges: '='
      },
      link: function link(scope, elem, attrs) {
        scope.saveCharge = function ($index) {
          var workshop = scope.workshops[$index],
              workshopCharge = workshop.workshopcharge;

          if (!workshopCharge) {
            workshopCharge = 0;
          }

          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/shift/workshop/' + workshop._id, {
            newshift: {
              workshopcharge: workshopCharge
            }
          }).then(function (reply) {
            var response = reply.data;
            scope.workshops[$index].selected = false;
            workshop = response.service;

            if (!response.success) {
              $rootScope.throbber.show('Sorry but an error occurred: ' + response.msg);
              return;
            }

            $rootScope.throbber.hide('Service cost updated');
          }).catch(function (err) {
            $rootScope.throbber.showError("Sorry but there was an error with this request: ".concat(err.statusText));
          });
        };

        scope.applyCharge = function (select, $index) {
          scope.workshops[$index].selected = select;
        };
      }
    };
  }
})();