angular.module('adminApp', ['ngRoute', 'angular-jwt', 'MainController']).config(["$routeProvider", function ($routeProvider) {
  $routeProvider // Auth
  .when('/register', {
    templateUrl: '/templates/auth/register.html',
    controller: 'RegisterController',
    controllerAs: 'reg'
  }).when('/login', {
    templateUrl: '/templates/auth/login.html',
    controller: 'LoginController',
    controllerAs: 'log'
  }) // Admin - users

  /*.when('/users',
      {
          templateUrl: '/templates/admin/listusers.html',
          controller: 'UsersController',
          controllerAs: 'use'
      })
    .when('/roles',
      {
          templateUrl: '/templates/admin/listroles.html',
          controller: 'RoleController',
          controllerAs: 'role'
      })
    .when('/user/add',
      {
          templateUrl: '/templates/admin/edituser.html',
          controller: 'AddUserController',
          controllerAs: 'use'
      })
    .when('/user/edit/:userid',
      {
          templateUrl: '/templates/admin/edituser.html',
          controller: 'EditUserController',
          controllerAs: 'use'
      })
    // Admin - orgs
  .when('/orgs',
      {
          templateUrl: '/templates/admin/listorgs.html',
          controller: 'OrgController',
          controllerAs: 'organ'
      })
    .when('/org/add',
      {
          templateUrl: '/templates/admin/editorg.html',
          controller: 'AddOrgController',
          controllerAs: 'organ'
      })
    .when('/org/edit/:orgid',
      {
          templateUrl: '/templates/admin/editorg.html',
          controller: 'EditOrgController',
          controllerAs: 'organ'
      })
    .when('/dashboard',
      {
          templateUrl: '/templates/admin/admindashboard.html',
          controller: 'DashboardController',
          controllerAs: 'dash'
      })
    .when('/taxons',
      {
          templateUrl: '/templates/admin/listtaxons.html',
          controller: 'TaxonomyController',
          controllerAs: 'tax'
      })
    .when('/taxons/add',
      {
          templateUrl: '/templates/admin/edittaxon.html',
          controller: 'AddTaxonomyController',
          controllerAs: 'tax'
      })
    .when('/taxons/edit/:taxonid',
      {
          templateUrl: '/templates/admin/edittaxon.html',
          controller: 'EditTaxonomyController',
          controllerAs: 'tax'
      })
  */
  .otherwise({
    redirectTo: '/login'
  });
}]) // Monitor all requests and responses, check for and assign JWT token.
.factory('AuthIntercept', ["$window", "$rootScope", "ADMIN_URL", "jwtHelper", function ($window, $rootScope, ADMIN_URL, jwtHelper) {
  var authInterceptor = {
    request: function request(req) {
      var token = localStorage.getItem('jwt-token'); // Append token to header if it exists. Currently occurs on
      // every request.

      if (token) {
        req.headers['x-access-token'] = token; // Get user info from the token and store globally.

        var payload = jwtHelper.decodeToken(token);
        $rootScope.userInfo = payload.user;
      }

      return req;
    },
    response: function response(res) {
      // Token sent. Store it
      if (res.data.success && res.data.token) {
        localStorage.setItem('jwt-token', res.data.token);
      }

      return res;
    },
    responseError: function responseError(rejection) {
      return rejection;
    }
  };
  return authInterceptor;
}]).config(["$httpProvider", function ($httpProvider) {
  $httpProvider.interceptors.push("AuthIntercept");
}]);