(function () {
  singleAppointmentCharges.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
  angular.module('userApp').directive('singleAppointmentCharges', singleAppointmentCharges);

  function singleAppointmentCharges(ADMIN_URL, API_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/settings/single-appointment-charges.html',
      scope: {
        services: '=',
        currency: '=',
        showCharges: '='
      },
      link: function link(scope, elem, attrs) {
        scope.saveCharge = function ($index) {
          var service = scope.services[$index],
              cost = service.cost;

          if (!cost) {
            cost = null;
          }

          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/service/' + service._id, {
            service: {
              cost: cost
            }
          }).then(function (reply) {
            var response = reply.data;
            scope.services[$index].selected = false;
            service = response.service;

            if (!response.success) {
              $rootScope.throbber.show('Sorry but an error occurred: ' + response.msg);
              return;
            }

            $rootScope.throbber.hide('Service cost updated');
          });
        };

        scope.applyCharge = function (select, $index) {
          scope.services[$index].selected = select;
        };
      }
    };
  }
})();