(function () {
  reserveTimeslot.$inject = ["API_URL", "$http", "$timeout"];
  angular.module('userApp').directive('reserveTimeslot', reserveTimeslot);

  function reserveTimeslot(API_URL, $http, $timeout) {
    return {
      restrict: 'EA',
      templateUrl: '/templates/directives/reserve-timeslot.html',
      replace: true,
      scope: {
        controller: '=controllerScope'
      },
      link: function link(scope, elem, attrs) {
        scope.controller.reserveTimeslots = function (timeslot, shift) {
          scope.timeslot = timeslot;
          scope.shift = shift;
          scope.slotsToReserve = '1';
          scope.reserveReason = '';
          $('.reserve-timeslot-modal').modal('show');
        };

        scope.doReserve = function () {
          var timeslot = scope.timeslot,
              shift = scope.shift;
          var appointment = {
            start: timeslot.start,
            end: timeslot.end,
            serviceid: timeslot.serviceid,
            shiftid: shift._id,
            workshopshiftid: shift.isworkshop ? shift._id : null,
            reserved: true,
            status: 'CONFIRMED',
            reservereason: scope.reserveReason || ''
          };

          if (timeslot.resourceid) {
            appointment.resourceid = timeslot.resourceid;
            appointment.hasresource = true;
          } else {
            appointment.hasresource = false;
          }

          var appointmentInfo = {
            appointment: appointment,
            numappts: scope.slotsToReserve
          };
          $http.post(API_URL + '/api/v1/appointments/manage/reserve', {
            appointmentinfo: appointmentInfo
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              scope.errorMessage = 'Sorry but there was an error with this request: ' + response.msg;
              $timeout(function () {
                scope.errorMessage = '';
              }, 2000);
              return;
            }

            scope.controller.init();
            $('.reserve-timeslot-modal').modal('hide');
          });
        };

        scope.getNumber = function (num) {
          return new Array(num);
        };
      }
    };
  }
})();