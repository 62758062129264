(function () {
  customerAppointments.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout"];
  angular.module('userApp').directive('customerAppointments', customerAppointments);

  function customerAppointments(ADMIN_URL, API_URL, $http, $rootScope, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/appointments/customer-appointments.html',
      scope: {
        customerId: '@',
        updateParent: '='
      },
      link: function link(scope, elem, attrs) {
        scope.paginationObject = {
          count: 0,
          page: 0,
          limit: 10
        };

        scope.changePage = function (page) {
          var params = {
            page: page || 0,
            limit: 10
          };
          $http.get(API_URL + '/api/v1/appointment/customer/' + scope.customerId, {
            params: params
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              scope.errorMessage = 'Sorry but an error occurred: ' + response.msg;
              $timeout(function () {
                scope.errorMessage = '';
              }, 3000);
              return;
            }

            scope.appointments = response.appointments;
            scope.paginationObject = {
              count: response.count,
              page: response.page,
              limit: response.limit
            };
          });
        };

        scope.changePage();

        scope.deleteAppointment = function (appointment) {
          var deleteMessage = appointment.paymentinfo.type === 'CREDIT' ? 'Delete appointment? Deleting this appointment will refund one credit to the customer\'s package' : 'Please confirm you would like to delete this appointment';

          if (!confirm(deleteMessage)) {
            return;
          }

          $rootScope.throbber.show();
          $http.delete("".concat(API_URL, "/api/v2/appointment/").concat(appointment._id)).then(function (response) {
            scope.updateParent();
            $http.get(API_URL + '/api/v1/appointment/customer/' + scope.customerId).then(function (reply) {
              $rootScope.throbber.hide();
              var response = reply.data;

              if (!response.success) {
                $rootScope.throbber.showError('Sorry but there was an error with this request: ' + response.msg);
                return;
              }

              scope.appointments = response.appointments;
            });
          });
        };
      }
    };
  }
})();