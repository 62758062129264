import _objectSpread from "@babel/runtime/helpers/objectSpread";
workshopsCalendar.$inject = ["ADMIN_URL", "$rootScope"];
angular.module('userApp').directive('workshopsCalendar', workshopsCalendar);

function workshopsCalendar(ADMIN_URL, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/scheduling/workshops/workshops-calendar.html',
    replace: true,
    scope: {
      workshops: '='
    },
    link: function link(scope, elem, attrs) {
      scope.updateWorkshops = function () {
        scope.workshopList = [];
        scope.workshopObject = {};

        if (scope.workshops.length) {
          scope.workshops.forEach(function (work) {
            var workshop = {
              name: work.serviceid.name,
              id: work._id,
              status: work.status,
              colour: work.colour
            };
            workshop.show = typeof work.show === 'undefined' ? true : work.show;
            scope.workshopList.push(workshop);
            if (!workshop.show) return;
            work.dates.reduce(function (accum, current) {
              if (accum[current]) {
                accum[current].workshops.push(workshop);
                accum[current].html = "".concat(accum[current].html, "<div>").concat(workshop.name, "</div>");
                accum[current].count = accum[current].count + 1;
                accum[current].colour = 'black';
              } else {
                accum[current] = {
                  workshops: [workshop],
                  html: "<div>".concat(workshop.name, "</div>"),
                  count: 1,
                  colour: work.colour
                };
              }

              return accum;
            }, scope.workshopObject);
          }); // End foreach
        }
      };

      scope.$watch('workshops', scope.updateWorkshops);

      var init = function init() {
        var throbber = $rootScope.throbber,
            month = moment().subtract(3, 'months'),
            // Start from today's date / month
        monthObject = {};
        scope.months = [{
          month: month.month(),
          year: month.format('YYYY')
        }]; // Get a list of the next 12 months

        for (var i = 0; i < 11; i++) {
          monthObject = {
            month: month.add(1, 'months').month(),
            year: month.format('YYYY')
          };
          scope.months.push(monthObject);
        }

        scope.startMonth = 3;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
      };

      init();

      scope.showNextMonths = function () {
        scope.startMonth += 3;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
        $timeout(function () {
          $('[data-toggle="tooltip"]').tooltip();
        }, 0);
      };

      scope.showPrevMonths = function () {
        scope.startMonth -= 3;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
        $timeout(function () {
          $('[data-toggle="tooltip"]').tooltip();
        }, 0);
      };

      scope.toggleWorkshop = function (index) {
        var workshop = scope.workshopList[index];
        scope.workshops = scope.workshops.map(function (worky) {
          if (worky._id === workshop.id) {
            worky.show = scope.workshopList[index].show;
          }

          return worky;
        });
      };

      scope.toggleShowAll = function (show) {
        scope.workshops = scope.workshops.map(function (worky) {
          return _objectSpread({}, worky, {
            show: show
          });
        });
      };
    }
  };
}