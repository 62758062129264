(function () {
  angular.module('filter.smoothdatetime', []).filter('smoothDateTime', smoothDateTime); // A filter to transform a timestamp based on timezone, locale and whether the clock is
  // 24 hour or not

  function smoothDateTime() {
    return function (timestamp, settings) {
      moment.locale(settings.locale);
      moment.tz.setDefault(settings.timezone);
      return moment.unix(timestamp).format('ll LT');
    };
  }
})();