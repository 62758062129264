(function () {
  TimeSettingsService.$inject = ["ADMIN_URL", "$http", "$q"];
  angular.module('service.timeSettings', []).service('TimeSettingsService', TimeSettingsService);

  function TimeSettingsService(ADMIN_URL, $http, $q) {
    this.getSettings = function () {
      var deferred = $q.defer();
      $http.get(ADMIN_URL + '/api/v1/settings/calendar').success(function (response) {
        // Set global defaults:
        // locale
        var locale = response.calendarsettings.locale.split('_')[0];
        moment.locale(locale); // Set timezone

        moment.tz.setDefault(response.calendarsettings.timezone);
        deferred.resolve(response);
      }).error(function (data, status) {
        console.error('Bookservice http error: ' + status);
        deferred.resolve({
          success: false
        });
      });
      return deferred.promise;
    }; // Set global locale and timezone


    this.setDefaults = function (calendarSettings) {
      var locale = calendarSettings.locale.split('_')[0];
      moment.locale(locale); // Set timezone

      moment.tz.setDefault(calendarSettings.timezone);
    };
  }
})();