(function () {
  daysClosedChooser.$inject = ["ADMIN_URL", "API_URL", "$http", "CalendarService", "$rootScope"];
  // daysChooser - supply a calendar with checkboxes to easily choose a set of dates
  angular.module('directive.days-closed-chooser', ['service.calendarService']).directive('daysClosedChooser', daysClosedChooser);

  function daysClosedChooser(ADMIN_URL, API_URL, $http, CalendarService, $rootScope) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/datepicker/days-chooser.html',
      replace: true,
      scope: {
        month: '@',
        year: '@',
        daysClosed: '=',
        controllerScope: '='
      },
      link: function link(scope, elem, attrs) {
        var throbber = $rootScope.throbber; // TODO: set locale
        // CalendarService.setOptions({locale:'en'})

        scope.$watch('daysClosed', function () {
          if (scope.daysClosed) {
            init();
          }
        });

        function init() {
          var monthObject = CalendarService.getMonthGrid(parseInt(scope.month), scope.year, scope.daysClosed);
          scope.rows = new Array(Math.floor(monthObject.dates.length / 7));
          scope.columns = new Array(7);
          scope.monthName = monthObject.month.name;
          scope.dates = monthObject.dates;
          scope.days = monthObject.days;
        }

        init();

        scope.toggleDate = function (date, selected) {
          throbber.show();

          if (selected) {
            $http({
              method: 'DELETE',
              url: API_URL + '/api/v1/daysclosed/',
              params: {
                daystodelete: JSON.stringify([date])
              }
            }).then(function (reply) {
              var response = reply.data;

              if (!response.success) {
                throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
                return;
              }

              scope.daysClosed = response.daysclosed;
              init();
              throbber.hide('Day closed removed');
            }, function (error) {
              throbber.showError('Sorry but there was an error with this request');
            });
            return;
          }

          $http.post(API_URL + '/api/v1/daysclosed', {
            daysclosed: [date]
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
              return;
            }

            scope.daysClosed = response.daysclosed;
            init();
            throbber.hide('Day closed added');
          }, function (error) {
            throbber.showError('Sorry but there was an error with this request');
          });
        };

        scope.selectAll = function () {
          var dates = CalendarService.getDaysForMonth(scope.month, scope.year);
          throbber.show();
          $http.post(API_URL + '/api/v1/daysclosed', {
            daysclosed: dates
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
              return;
            }

            scope.daysClosed = response.daysclosed;
            init();
            throbber.hide('Days closed added');
          }, function (error) {
            throbber.showError('Sorry but there was an error with this request');
          });
        };

        scope.selectNone = function () {
          var dates = CalendarService.getDaysForMonth(scope.month, scope.year);
          throbber.show();
          $http({
            method: 'DELETE',
            url: API_URL + '/api/v1/daysclosed/',
            params: {
              daystodelete: JSON.stringify(dates)
            }
          }).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
              return;
            }

            scope.daysClosed = response.daysclosed;
            init();
            throbber.hide('Days closed removed');
          }, function (error) {
            throbber.showError('Sorry but there was an error with this request');
          });
        };
      }
    };
  }
})();