(function () {
  angular.module('service.calendarService', []).service('CalendarService', CalendarService);

  function CalendarService() {
    if (typeof moment === 'undefined') {
      throw new Error('Moment has not been defined');
    }

    var _options = {
      locale: 'en-gb',
      startOfWeek: null
    },
        _moment = moment; // Keep a local copy of moment with global options set.

    this.setOptions = function (options) {
      $.extend(_options, options);
      this.init();
    };

    this.init = function () {
      _moment.locale(_options.locale);
    };

    this.init(); // Set things up with defaults. These can be overridden by setOptions().
    // Return an array with:
    // - All the days of the specified month
    // - Days of the previous month required to fill out the grid
    // - Days of the next month required to fill out the grid
    // ** Remember month is zero indexed! **

    this.getMonthGrid = function (month, year, daysClosed) {
      var dateObject = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var daysClosed = daysClosed || [];

      if (isNaN(month) || month < 0 || month > 11 || isNaN(year)) {
        throw new Error('Need month and year');
      }

      var grid = {
        locale: _options.locale,
        month: {
          number: month,
          name: _moment().month(month).format('MMMM') // Month formatted by locale

        },
        next: _moment().month(month).add(1, 'months').format('MMM'),
        previous: _moment().month(month).subtract(1, 'months').format('MMM')
      }; // Get the start and end dates for the calendar

      var dates = this.getStartAndEndDates(month, year),
          today = _moment().format('YYYY-MM-DD'),
          todayTimestamp = _moment().hour(0).minute(0).second(0).unix(),
          gridDates = []; // Cycle through the dates


      for (var date = _moment(dates.startDate); date.isSameOrBefore(dates.endDate); date.add(1, 'days')) {
        var day = {
          date: date.format('YYYY-MM-DD'),
          day: date.format('D'),
          past: date.unix() < todayTimestamp,
          selected: false,
          thisMonth: !(date.isBefore(dates.monthStart) || date.isAfter(dates.monthEnd))
        };
        if (day.date === today) day.today = true;

        if (dateObject && dateObject[date.format('YYYY-MM-DD')]) {
          day.selected = true;
          day.data = dateObject[date.format('YYYY-MM-DD')];
          day.html = dateObject[date.format('YYYY-MM-DD')].html || '';
          day.count = dateObject[date.format('YYYY-MM-DD')].count || 0;
          day.colour = dateObject[date.format('YYYY-MM-DD')].colour || 'black';
        }

        for (var i = 0; i < daysClosed.length; i++) {
          if (daysClosed[i] === day.date) {
            day.selected = true;
          }
        }

        gridDates.push(day);
      } // Get days of week


      var weekDay = _moment(dates.startDate),
          days = [];

      days.push(weekDay.format('ddd'));

      for (var j = 0; j < 6; j++) {
        days.push(weekDay.add(1, 'days').format('ddd'));
      }

      grid.days = days;
      grid.dates = gridDates;
      return grid;
    }; // Get the start and end date for the calendar: the dates we're running from and to
    // - Beginning of the week of the start of the month
    // - End of the week of the end of the month


    this.getStartAndEndDates = function (month, year) {
      // Moment will use locale to determine which day starts the week unless this is overridden.
      var date = _moment().year(year).month(month).date(1),
          monthStart = date.format('YYYY-MM-DD'),
          startDate = date.clone(),
          // Necessary because moment dates are mutable
      endDate = date.clone(); // Get day of week number (locale aware) for first day of the month
      // - zero indexed


      var startDayOfWeek = date.weekday(); // Now rewind to the beginning of the week which is where we start our list

      startDate = startDate.subtract(startDayOfWeek, 'days').format('YYYY-MM-DD'); // We need the end of the week *after* the end of the month.

      var endDayOfWeek = _moment(endDate).endOf('month').weekday(),
          endDateOfMonth = _moment(endDate).endOf('month').format('YYYY-MM-DD');

      endDate = _moment(endDate).endOf('month').add(6 - endDayOfWeek, 'days').format('YYYY-MM-DD');
      return {
        startDate: startDate,
        endDate: endDate,
        monthStart: monthStart,
        monthEnd: endDateOfMonth,
        weekStart: moment.localeData(_options.locale).firstDayOfWeek()
      };
    };

    this.getDaysForMonth = function (month, year) {
      var dates = [];
      var start = moment().set('date', 1).set('month', month).set('year', year);

      for (var end = moment(start).add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
        dates.push(start.format('YYYY-MM-DD'));
      }

      return dates;
    };
  }
})();