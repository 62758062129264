(function () {
  smoothTime.$inject = ["$rootScope"];
  angular.module('filter.smoothtime', []).filter('smoothTime', smoothTime); // A filter to transform a timestamp based on timezone, locale and whether the clock is
  // 24 hour or not

  function smoothTime($rootScope) {
    return function (timestamp) {
      moment.tz.setDefault($rootScope.userInfo.timezone); // Get locale from the user's client

      var locale = window.navigator.userLanguage || window.navigator.language || 'en';
      moment.locale(locale);
      return moment.unix(timestamp).format('LT');
    };
  }
})();