manageCustomerMemberships.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout", "$location"];
angular.module('userApp').directive('manageCustomerMemberships', manageCustomerMemberships);

function manageCustomerMemberships(ADMIN_URL, API_URL, $http, $rootScope, $timeout, $location) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/customer/manage-customer-memberships.html',
    scope: {
      customerId: '@'
    },
    link: function link(scope, elem, attrs) {
      var init = function init() {
        var user = $rootScope.userInfo; // Resources are not allowed to give / manage memberships

        scope.userIsResource = user.roles[0].type === 'RESOURCE'; // Get list of available memberships

        $http.get(API_URL + "/api/v1/membership/customer/".concat(scope.customerId)).then(function (_ref) {
          var response = _ref.data;
          if (!response.success) return scope.membershipsError = "Sorry but an error occurred: ".concat(response.msg);
          scope.memberships = response.customerMemberships;
        }).catch(function (err) {
          console.error("Error retrieving memberships: ".concat(err.message));
        });
      };

      init();

      scope.toggleHistoryInfo = function (ev, membershipid) {
        $("#membership-transactions-".concat(membershipid)).slideUp();
        $("#membership-history-".concat(membershipid)).slideToggle();
      };

      scope.toggleTransactionInfo = function (ev, membershipid) {
        $("#membership-history-".concat(membershipid)).slideUp();
        $("#membership-transactions-".concat(membershipid)).slideToggle();
      };

      scope.cancelMembership = function (membershipid) {
        if (!confirm('Please confirm you want to cancel this membership for the customer. The customer\'s membership will remain valid ' + 'until the end of the billing cycle but they will not be billed again.')) return;
        $http.delete(API_URL + "/api/v1/membership/customer/".concat(membershipid)).then(function (_ref2) {
          var response = _ref2.data;
          if (!response.success) return scope.membershipsError = "Sorry but an error occurred: ".concat(response.msg); // Wait a few seconds for the backend to process the webhook

          $timeout(function () {
            init();
          }, 3000);
        }).catch(function (err) {
          console.error("Error retrieving memberships: ".concat(err.message));
        });
      };
    }
  };
}