AddWorkshopController.$inject = ["$scope", "$q", "$http", "API_URL", "$routeParams", "$location", "$rootScope", "$timeout"];
angular.module('userApp').controller('AddWorkshopController', AddWorkshopController);

function AddWorkshopController($scope, $q, $http, API_URL, $routeParams, $location, $rootScope, $timeout) {
  var work = this;
  work.loading = false;
  work.workshops = [];
  work.workshopDates = [];
  work.workshopSettings = {};
  var throbber = $rootScope.throbber;

  init = function init() {
    var user = $rootScope.userInfo,
        orgID = user.orgid;
    work.userIsResource = user.roles[0].type == 'RESOURCE';
    work.loading = true;
    work.hideAddSchedule = false;
    work.services = [];
    work.resources = [];
    var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/services/' + orgID)];
    $scope.throbber.show();
    $q.all(promises).then(function (responses) {
      var error = '',
          shifts;
      $scope.throbber.hide();
      work.loading = false;
      responses.forEach(function (_ref) {
        var data = _ref.data;
        var response = data;
        var resources = response.resources,
            services = response.services;

        if (!response.success) {
          error = response.msg;
        } else {
          if (resources && resources.length) {
            work.resources = resources;

            if (work.resourceID) {
              for (var i = 0; i < work.resources.length; i++) {
                if (work.resourceID === work.resources[i]._id) {
                  work.chosenResource = work.resources[i];
                }
              }
            }
          }

          if (services && services.length) {
            work.services = services;
            work.currentService = work.services[0];
          }
        }
      }); // End forEach

      if (error) {
        $scope.throbber.showError(error);
      }

      if (smooth.dotour) {
        $timeout(function () {
          smooth.schedulingTour.init();
          smooth.schedulingTour.start();
        }, 0);
      }
    });
  }; // End init


  init();

  work.addWorkshopShift = function () {
    var newShift = {
      type: 'WORKSHOP',
      serviceid: work.workshopSettings.serviceid,
      workshopstart: work.workshopSettings.workshopstart,
      workshopend: work.workshopSettings.workshopend,
      hasresource: work.workshopSettings.resourceid !== '0',
      resourceid: work.workshopSettings.resourceid !== '0' ? work.workshopSettings.resourceid : null,
      openings: work.workshopSettings.openings,
      bookmulti: work.workshopSettings.bookmulti,
      joinmidsession: work.workshopSettings.joinmidsession,
      bookall: work.workshopSettings.bookall,
      bookremaining: work.workshopSettings.bookremaining,
      dates: work.workshopDates
    };
    $rootScope.throbber.show();
    $http.post("".concat(API_URL, "/api/v1/shift/workshop"), {
      newshift: newShift
    }).then(function (result) {
      if (!result.data.success) {
        return throbber.showError("Sorry but this shift cannot be created because it conflicts with another shift on: ".concat(result.data.conflictdate));
      }

      $rootScope.throbber.hide('Workshop or event added successfully');
      $timeout(function () {
        $location.path('/scheduling/workshops/');
      }, 2000);
    }).catch(function (error) {
      console.error(error);
      $rootScope.throbber.showError("We're very sorry but an error occurred with this request. Please contact support@smoothbook.co if this problem re-occurs");
    });
  };

  work.dateChosen = function (dates) {
    work.workshopDates = dates;
  };

  work.workshopSettingsUpdated = function (settings) {
    work.workshopSettings = settings;
  };

  work.changeResource = function (resourceID) {
    if (!resourceID) {
      return;
    }

    work.resourceID = resourceID;
    work.getShifts();
  };

  work.getShifts = function () {
    var params = {
      isworkshop: true
    };

    if (work.resourceID) {
      params.resourceid = work.resourceID;
    } else {
      params.hasresource = false;
    }

    $scope.throbber.show();
    $http.get(API_URL + '/api/v1/shift', {
      params: params
    }).then(function (reply) {
      $scope.throbber.hide();
      var response = reply.data;

      if (!response.success) {
        $scope.throbber.showError('Sorry but there was an error with this operation: ' + response.msg);
        return;
      }

      work.shifts = response.shifts;
    });
  };

  work.toggleAddShift = function () {
    $('.add-schedule-container').slideToggle();
    work.addingShift = !work.addingShift;
  };

  work.serviceAdded = function (service) {
    work.services.push(service);
    work.updateChosenService();
  };
}