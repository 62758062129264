(function () {
  angular.module('userApp').service('DaysToCountService', DaysToCountService);

  function DaysToCountService() {
    // The number of days to return from the timeslots api is determined by the
    // client width.
    this.getDaysToCount = function () {
      if (window.matchMedia('(max-width: 767px)').matches) {
        return 1; // Tablet
      } else if (window.matchMedia('(min-width: 768px) and (max-width: 992px)').matches) {
        return 2;
      } else {
        return 4;
      }
    };
  }
})();