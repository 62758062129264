import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
setupMessage.$inject = ["$rootScope", "$http", "ADMIN_URL", "API_URL"];
angular.module('userApp').directive('setupMessage', setupMessage);

function setupMessage($rootScope, $http, ADMIN_URL, API_URL) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/setup-message.html',
    scope: {},
    link: function link(scope, elem, attrs) {
      var user = $rootScope.userInfo;

      var getStatus = function getStatus() {
        try {
          var promises = [$http.get(API_URL + '/api/v1/services/' + user.orgid), $http.get(API_URL + '/api/v1/shift'), $http.get(API_URL + '/api/v1/appointment')];
          Promise.all(promises).then(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 3),
                servicesData = _ref2[0].data,
                shiftsData = _ref2[1].data,
                apptsData = _ref2[2].data;

            scope.orgHasServices = !!servicesData.services.length;
            scope.orgHasShifts = !!shiftsData.shifts.length;
            scope.orgHasAppts = apptsData.length;
            scope.orgNeedsSetup = !scope.orgHasShifts || !scope.orgHasServices || !scope.orgHasAppts;
            $rootScope.safeApply();
          }).catch(function (err) {
            console.error(err.message);
          });
        } catch (err) {
          console.error(err.message);
        }
      };

      getStatus();
      $rootScope.getSetupStatus = getStatus; // So that other parts of the program can update the status.

      scope.hideMessage = function () {
        $http.put("".concat(API_URL, "/api/v1/user/").concat(user._id), {
          userinfo: {
            showsetup: false,
            newtoken: true
          }
        }).then(function (result) {
          console.log(result);
        }).catch(function (err) {
          console.error("Error updating setup status: ".concat(err.message));
        });
      };
    }
  };
}