var mixpanel = require('mixpanel-browser'),
    config = require('../../../config');

angular.module('userApp').controller('RegisterController', ["AuthService", "API_URL", "$http", "$rootScope", "$timeout", "$window", function (AuthService, API_URL, $http, $rootScope, $timeout, $window) {
  var reg = this,
      throbber = $rootScope.throbber;
  $rootScope.authorising = true;
  $rootScope.hideBugWidget = true;
  reg.newsletterSubscribe = true;
  reg.form = {};
  reg.timezones = smooth.timezones;
  reg.form.timezone = moment.tz.guess(); // 'guess' at user's timezone

  $(function () {
    $timeout(function () {
      $('.company-timezone-select').select2();
    }, 0);
  });

  reg.eugdpr = function () {
    // EUGDPR
    $('#eugdpr-modal').modal('show');
  };

  reg.register = function () {
    $('#eugdpr-modal').modal('hide');
    reg.loading = true;
    reg.errorMessage = '';
    throbber.show();
    getRecaptchaToken().then(function (token) {
      AuthService.register(reg.form, reg.newsletterSubscribe, token).then(function (response) {
        reg.loading = false;
        throbber.hide();

        if (!response.success) {
          reg.errorMessage = response.msg;
          return;
        } // Success. Redirect to dashboard depending on access level.


        var isSuper = false; // Analytics

        if (smooth.env === 'production') {
          ga('set', 'userId', response.user._id);
          ga('send', 'event', 'users', 'user registered');
        }

        try {
          var user = response.user,
              subscription = user.subscription;

          if (smooth.env === 'production') {
            mixpanel.init(config.mixpanel_token, {
              batch_requests: true
            });
            mixpanel.identify(user._id);
            mixpanel.people.set_once({
              "$email": user.email,
              "$created": user.createdAt,
              "$timezone": user.timezone,
              '$name': "".concat(user.firstname, " ").concat(user.lastname),
              'Organisation': user.orgname,
              'Orgid': user.orgid
            });
            mixpanel.track('User registered');
          }
        } catch (e) {
          console.error("Mixpanel error: ".concat(e.message));
        }

        for (var i = 0; i < response.user.roles.length; i++) {
          if (response.user.roles[i]['type'] === 'SUPER') {
            isSuper = true;
            break;
          }
        }

        delete $rootScope.hideBugWidget;
        $window.location.href = isSuper ? '/admin#/dashboard/true' : '/#/dashboard/true';
      }).catch(function (error) {
        reg.loading = false;
        throbber.hide();
        console.error(error.msg);
        reg.errorMessage = error.msg;
      });
    });
  };
}]); // Google recaptcha

var getRecaptchaToken = function getRecaptchaToken() {
  return new Promise(function (resolve, reject) {
    grecaptcha.execute('6Ldq-K8UAAAAAJtC6lVsC9Rc-FJXd8UNDphcYtav', {
      action: 'homepage'
    }).then(function (token) {
      resolve(token);
    });
  });
};

angular.module('userApp').controller('LoginController', ["AuthService", "API_URL", "$http", "$rootScope", "$window", "$timeout", function (AuthService, API_URL, $http, $rootScope, $window, $timeout) {
  var log = this;
  log.user = {};
  $rootScope.authorising = true;
  $rootScope.hideBugWidget = true; // Currently there are two systems: forceNew will force login to the new system.

  log.userLogin = function () {
    log.retryPassword = false;
    log.loading = false;
    getRecaptchaToken().then(function (token) {
      log.loading = true;
      AuthService.login(log.user, token).then(function (response) {
        log.loading = false;

        if (!response.success) {
          if (log.dualLogin) {
            log.retryPassword = true;
            return;
          }

          log.errorMessage = response.msg;
          $timeout(function () {
            log.errorMessage = '';
          }, 4000);
          return;
        } // Analytics


        ga('set', 'userId', response.user._id);

        try {
          var user = response.user;

          if (smooth.env === 'production' && moment(user.createdAt).isAfter(moment('2020-08-05'))) {
            mixpanel.init(config.mixpanel_token, {
              batch_requests: true
            });
            mixpanel.identify(user._id);
            mixpanel.people.set_once({
              "$email": user.email,
              "$created": user.createdAt,
              "$timezone": user.timezone,
              '$name': "".concat(user.firstname, " ").concat(user.lastname),
              'Organisation': user.orgname,
              'Orgid': user.orgid
            });
            mixpanel.track('User login');
          }
        } catch (e) {
          console.error("Mixpanel error: ".concat(e.message));
        }

        delete $rootScope.hideBugWidget;
        $window.location.href = '/#/dashboard';
      }).catch(function (err) {
        log.loading = false;
        log.errorMessage = err.message || err.msg || err;
        $timeout(function () {
          log.errorMessage = '';
        }, 4000);
      });
    });
  };

  function post(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.

    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }
}]);