(function () {
  SetupController.$inject = ["$timeout"];
  angular.module('userApp').controller('SetupController', SetupController);

  function SetupController($timeout) {
    var set = this;

    if (smooth.dotour) {
      $timeout(function () {
        smooth.setupTour.init();
        smooth.setupTour.start();
      }, 0);
    }
  }
})();