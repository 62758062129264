(function () {
  TimeslotService.$inject = ["API_URL", "$http", "$q", "$rootScope", "DaysToCountService"];
  angular.module('userApp').service('TimeslotService', TimeslotService);

  function TimeslotService(API_URL, $http, $q, $rootScope, DaysToCountService) {
    this.getTimeslots = function (filters) {
      var timeslotOptions = angular.copy(filters || {}),
          availableDayOptions = angular.copy(filters || {});
      timeslotOptions.daystocount = DaysToCountService.getDaysToCount();
      timeslotOptions.getunavailable = true;
      timeslotOptions.showpast = true;
      availableDayOptions.nosettings = true; // For manage appointments, allow the user to browse the last three months of appointments

      availableDayOptions.startdate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      var deferred = $q.defer();
      var promises = [$http.get(API_URL + '/api/v1/timeslots', {
        params: timeslotOptions
      }), $http.get(API_URL + '/api/v1/daysavailable/' + $rootScope.userInfo.orgid, {
        params: availableDayOptions
      })];
      $q.all(promises).then(function (responses) {
        var success = true,
            error,
            msg;
        responses.forEach(function (response) {
          if (!response.data.success) {
            success = false;
            error = response.data.error || null;
            msg = response.data.msg;
          }
        });

        if (!success) {
          deferred.reject({
            success: false,
            error: error,
            msg: msg || ''
          });
          return;
        }

        var days = responses[0].data.days,
            daysAvailable = responses[1].data;
        deferred.resolve({
          success: true,
          days: days,
          daysAvailable: daysAvailable.days
        });
      }, function (error) {
        deferred.reject({
          success: false,
          msg: error.message
        });
      });
      return deferred.promise;
    };
  }
})();