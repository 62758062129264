import _readOnlyError from "@babel/runtime/helpers/readOnlyError";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
ChargingController.$inject = ["BASE_URL", "API_URL", "$http", "$q", "$cookies", "$rootScope", "$scope", "$timeout", "jwtHelper"];
angular.module('userApp').controller('ChargingController', ChargingController);

function ChargingController(BASE_URL, API_URL, $http, $q, $cookies, $rootScope, $scope, $timeout, jwtHelper) {
  var charge = this;
  charge.paymentForm = {};
  charge.stripeForm = {};
  charge.stripeAuthURI = BASE_URL + '/stripe/authorise';

  charge.init = function () {
    var orgID = $rootScope.userInfo.orgid,
        throbber = $rootScope.throbber;
    charge.subscribed = !!$rootScope.userInfo.subscription; // Figure out if user has basic subscription or not.

    var token = localStorage.getItem('jwt-token'),
        payload = jwtHelper.decodeToken(token); // Disable functionality in child components of the

    charge.premiumSubRequired = window.smooth.charging && payload.subscription && payload.subscription.id === 'BASIC';
    throbber.show();
    var promises = [$http.get(API_URL + '/api/v1/settings/charging', {
      withCredentials: true
    }), $http.get(API_URL + '/api/v1/package'), $http.get(API_URL + '/api/v1/services/' + orgID), $http.get(API_URL + '/api/v1/membership'), $http.get(API_URL + '/api/v1/currencylist'), $http.get("".concat(API_URL, "/api/v1/shift/workshop")), $http.get("".concat(API_URL, "/api/v1/settings/organisation"))];
    $q.all(promises).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 7),
          settings = _ref2[0].data.settings,
          packages = _ref2[1].data.packages,
          services = _ref2[2].data.services,
          memberships = _ref2[3].data.memberships,
          currencies = _ref2[4].data.currencies,
          workshopshifts = _ref2[5].data.workshopshifts,
          orgSettings = _ref2[6].data.organisation;

      throbber.hide();
      charge.settings = settings;
      charge.stripe_percent = settings.stripe_percent;
      charge.connectTransferred = !!orgSettings.connectTransferred;
      charge.showConnectMigration = !!settings.showConnectMigration;
      console.log("charge.connectTransferred: ".concat(charge.connectTransferred));
      console.log("charge.showConnectMigration: ".concat(charge.showConnectMigration));
      charge.packages = packages;
      charge.services = services;
      charge.memberships = memberships;
      charge.currencies = currencies;
      charge.workshops = workshopshifts;
      charge.currencyCode = charge.settings.currency.code;
      var stripeAuthorised = charge.settings.stripeAuthorised;
      charge.stripeInfo = stripeAuthorised ? charge.settings.stripeInfo : null;
      $timeout(function () {
        $('.currency-dropdown').select2();
        $('.currency-dropdown').on('change', function (ev) {
          ev.preventDefault();
          charge.currencyCode = ev.currentTarget.value;
          charge.updateCurrency();
        });
      }, 0);
    }).catch(function (err) {
      throbber.showError('Sorry but an error occurred with this query: ' + err.message);
    });
  };

  charge.init();

  charge.disconnectStripe = function () {
    if (!confirm('If you disconnect Stripe your charging settings will be unaffected but your users will not be able to pay for (or book) ' + 'any services that have a cost associated with them. You can re-connect with Stripe in the future without any problem.   Continue?')) return;
    $http.put(API_URL + '/api/v1/settings/charging/disconnectstripe').then(function (result) {
      charge.init();
    }).catch(function (error) {
      console.error(error);
    });
  };

  $scope.miniBrowserClosed = function () {
    charge.init();
  };

  charge.updateCurrency = function () {
    $scope.throbber.show();
    $http.put(API_URL + '/api/v1/settings/charging/updatecurrency', {
      currency: charge.currencies[charge.currencyCode]
    }).then(function (reply) {
      $scope.throbber.hide();
      charge.currencyUpdatedAlert = true;
      $timeout(function () {
        charge.currencyUpdatedAlert = false;
      }, 2000);
    });
  };

  charge.deleteMembership = function (membershipID) {
    if (!confirm('Please confirm you would like to delete this membership as well as associated Stripe products and plans. ' + 'Please note that your customer\'s memberships will *not* be deleted - their existing memberships will be valid until they expire')) {
      return;
    }

    $scope.throbber.show();
    $http.delete(API_URL + '/api/v1/membership/' + membershipID).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        $scope.throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
        return;
      }

      $scope.throbber.hide('Membership deleted');
      charge.init();
    }).catch(function (err) {
      $scope.throbber.showError('Sorry but an error occurred with this request: ' + err.data ? err.data : err.message ? err.message : '');
    });
  };

  charge.deletePackage = function (packageID) {
    if (!confirm('Please confirm you would like to delete this package.\n\nThis action will delete the package from any associated memberships.' + '\n\n\Please note that your customer\'s packages and credits and existing subscriptions will *not* be deleted - ' + 'they will still be able to book with their package credit / membership.')) {
      return;
    }

    $scope.throbber.show('Package deleted');
    $http.delete(API_URL + '/api/v1/package/' + packageID).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        $scope.throbber.showError('Sorry but an error occurred with this request: ' + response.msg);
        return;
      }

      $scope.throbber.hide('Package deleted');
      charge.init();
    }).catch(function (err) {
      $scope.throbber.showError('Sorry but an error occurred with this request: ' + err);
    });
  };

  charge.showHelp = function () {
    $('.charging-help').slideToggle();
  };

  charge.openMiniWindow = function (type, windowUrl) {
    var title = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var width = arguments.length > 3 ? arguments[3] : undefined;
    var height = arguments.length > 4 ? arguments[4] : undefined;
    smooth.windowType = type;
    var calculatedWidth = width || parseInt(screen.width * 0.4),
        calculatedHeight = height || parseInt(screen.height * 0.8),
        winl = parseInt((screen.width - calculatedWidth) / 2),
        wint = parseInt((screen.height - calculatedHeight) / 2);
    if (winl < 0) winl = (_readOnlyError("winl"), 0);
    if (wint < 0) wint = (_readOnlyError("wint"), 0);
    var settings = 'height=' + calculatedHeight + ',';
    settings += 'width=' + calculatedWidth + ',';
    settings += 'top=' + wint + ',';
    settings += 'left=' + winl + ',';
    settings += 'resizable,scrollbars';
    var win = window.open(windowUrl, title, settings);
    win.window.focus();
    var interval = window.setInterval(function () {
      try {
        if (win == null || win.closed) {
          window.clearInterval(interval); // Need to trigger event in Angular from here

          $scope.miniBrowserClosed();
        }
      } catch (e) {}
    }, 1000);
  };
}

;