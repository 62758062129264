angular.module('userApp').controller('AccountController', ["API_URL", "ADMIN_URL", "$http", "$timeout", "$cookies", "$window", "$rootScope", function (API_URL, ADMIN_URL, $http, $timeout, $cookies, $window, $rootScope) {
  var acc = this,
      throbber = $rootScope.throbber;

  acc.init = function () {
    throbber.show();
    acc.userID = $rootScope.userInfo._id;
    acc.destroyOrg = false;
    $http.get(API_URL + '/api/v1/user/' + acc.userID).then(function (reply) {
      throbber.hide();
      var response = reply.data,
          user = response.user;

      if (!response.success) {
        throbber.showError('Sorry but there was an error with this request: ' + response.msg);
        return;
      } // Get the user's subscription from the JWT token.


      acc.subscription = user.subscription; // Determine if this user is allowed to delete their account.

      var userRoles = user.roles;
      acc.isOwner = false;

      for (var i = 0; i < userRoles.length; i++) {
        if (userRoles[i].type === 'OWNER') {
          acc.isOwner = true;
        }
      }

      acc.form = response.user;
    });
  };

  acc.init();

  acc.saveUser = function () {
    throbber.show();
    $http.put(API_URL + '/api/v1/user/' + acc.userID, {
      userinfo: acc.form
    }).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        throbber.showError(response.msg);
        return;
      }

      acc.form = response.user;
      throbber.hide('Account updated successfully');
    });
  };

  acc.warnDelete = function () {
    acc.deleting = true;
  };

  acc.cancelDelete = function () {
    acc.deleting = false;
  };

  acc.typeDelete = function () {
    acc.enableDelete = acc.deleteText === 'DELETE';
  };

  acc.doDelete = function () {
    acc.loading = true;
    var url = API_URL + '/api/v1/user/account/delete' + (acc.destroyOrg ? '?destroyorg=1' : '');
    $http.delete(url).then(function (reply) {
      acc.loading = false;
      acc.deleting = false;
      var response = reply.data;
      acc.deleteMessage = true;
      $timeout(function () {
        acc.deleteMessage = false;
        localStorage.removeItem('jwt-token');
        $cookies.remove('x-access-token');
        $window.location.href = ADMIN_URL + '/#/login';
      }, 3000);
    });
  };
}]);