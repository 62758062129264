(function () {
  smoothDate.$inject = ["$rootScope"];
  angular.module('filter.smoothdate', []).filter('smoothDate', smoothDate); // A filter to transform a timestamp based on timezone, locale and whether the clock is
  // 24 hour or not

  function smoothDate($rootScope) {
    return function (timestamp) {
      moment.tz.setDefault($rootScope.userInfo.timezone); // Get locale from the user's client

      var locale = window.navigator.userLanguage || window.navigator.language || 'en';
      moment.locale(locale);
      return moment.unix(timestamp).format('ll');
    };
  }
})();