(function () {
  UserAccessController.$inject = ["API_URL", "$http", "$rootScope"];
  angular.module('userApp').controller('UserAccessController', UserAccessController);

  function UserAccessController(API_URL, $http, $rootScope) {
    var user = this,
        throbber = $rootScope.throbber,
        ownerID = $rootScope.userInfo._id;

    user.init = function () {
      throbber.show();
      $http.get(API_URL + '/api/v1/user').then(function (reply) {
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          throbber.showError('Sorry but there was a problem with this request: ' + response.msg);
          return;
        }

        user.users = response.users; // This is all the users for the organisation
      });
    };

    user.init();

    user.deleteUser = function (userID) {
      if (!confirm('Please confirm you would like to delete this user')) {
        return;
      }

      throbber.show(); // Make sure that the last owner is not being deleted:
      // - i.e. if an owner is being deleted and there is only one owner left.

      var numOwners = 0;
      deletingOwner = false;

      for (var i = 0; i < user.users.length; i++) {
        user.users[i].roles.forEach(function (role) {
          if (role.type === 'OWNER') {
            numOwners++;

            if (user.users[i]._id === userID) {
              deletingOwner = true;
            }
          }
        });
      }

      if (numOwners === 1 && deletingOwner) {
        throbber.showError('Your organisation must have at least one owner. You cannot delete the last owner');
        return;
      }

      $http.delete(API_URL + '/api/v1/user/' + userID).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError('Sorry but there was an error with this request: ' + response.msg);
          return;
        }

        user.init();
        throbber.hide('User deleted successfully');
      });
    };
  }
})();