ListWorkshopsController.$inject = ["$scope", "$q", "$http", "API_URL", "$routeParams", "$location", "$rootScope", "$timeout"];
angular.module('userApp').controller('ListWorkshopsController', ListWorkshopsController);

function ListWorkshopsController($scope, $q, $http, API_URL, $routeParams, $location, $rootScope, $timeout) {
  var throbber = $rootScope.throbber,
      moment = require('moment-timezone');

  var work = this;
  var workshopColours = ['#FFD119', 'cornflowerBlue', 'DarkGoldenRod', 'Gold', 'GreenYellow', 'Khaki', 'Wheat', 'LightSalmon', 'Aqua'];
  work.loading = false;
  work.workshops = [];
  work.showFinished = false;
  var locale = $rootScope.userInfo.locale;
  moment.locale(locale);

  if (smooth.dotour) {
    $timeout(function () {
      smooth.workshopTour.init();
      smooth.workshopTour.start();
    }, 0);
  }

  var init = function init() {
    throbber.show(); // Retrieve workshops

    $http.get("".concat(API_URL, "/api/v1/shift/workshop")).then(function (results) {
      throbber.hide();
      var data = results.data;

      if (data.success) {
        var colourIndex = 0;
        work.hasActive = false;
        work.hasFinished = false;
        work.workshops = data.workshopshifts.map(function (workshop) {
          workshop.displayDates = workshop.dates.map(function (date) {
            return moment(date).format('ddd ll');
          });
          workshop.status = moment(workshop.dates[workshop.dates.length - 1]).isBefore(moment()) ? 'finished' : 'active';
          workshop.zoom = !!workshop.serviceid.zoom;
          workshop.colour = workshopColours[colourIndex];
          colourIndex = colourIndex < workshopColours.length - 1 ? colourIndex + 1 : 0;
          if (workshop.status === 'active') work.hasActive = true;
          if (workshop.status === 'finished') work.hasFinished = true;
          return workshop;
        });
      }
    }, function (error) {
      console.error(error.msg);
      throbber.showError("Sorry an error occurred: ".concat(error.message));
    });
  };

  init();

  work.summaryModal = function (workshopID) {
    console.log(workshopID);
  };

  work.deleteWorkshop = function (workshopID) {
    if (!confirm('Are you sure you would like to delete this workshop?')) return;
    throbber.show();
    $http.delete("".concat(API_URL, "/api/v1/shift/workshop/").concat(workshopID)).then(function (response) {
      if (!response.data.success) {
        return throbber.showError("Sorry but an error occurred: ".concat(response.msg));
      }

      throbber.hide('Workshop shift deleted successfully');
      $timeout(function () {
        init();
      }, 3000);
    }, function (error) {
      console.error(error);
      throbber.showError("Sorry an error occurred: ".concat(error.data.msg));
    });
  };
}