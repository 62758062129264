(function () {
  angular.module('userApp').directive('numberFormat', numberFormat);

  function numberFormat() {
    return {
      require: 'ngModel',
      restrict: 'A',
      scope: {
        decimals: '@?',
        // Integer - number of decimals allowed
        maxInt: '@?',
        minInt: '@?'
      },
      link: function link(scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            val = String(val); // Replace non numbers and / or decimal point

            var digits = scope.decimals ? val.replace(/[^0-9.]/g, '') : val.replace(/[^0-9]/g, '');

            if (scope.decimals) {
              var fractions = digits.split('.');

              if (fractions[1] && fractions[1].length > scope.decimals) {
                fractions[1] = fractions[1].slice(1, fractions[1].length);
                digits = fractions[0] + '.' + fractions[1];
              }
            }

            if (scope.maxInt && parseInt(digits) > parseInt(scope.maxInt)) {
              digits = parseInt(scope.maxInt);
            }

            if (scope.minInt && parseInt(digits) < parseInt(scope.minInt)) {
              digits = parseInt(scope.minInt);
            }

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }

            return parseFloat(digits);
          }

          return undefined;
        }

        ctrl.$parsers.push(inputValue);
      }
    };
  }
})();