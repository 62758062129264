stripeUpdateCard.$inject = ["ADMIN_URL", "API_URL", "$rootScope", "$http", "$filter", "$location", "$timeout"];
angular.module('userApp').directive('stripeUpdateCard', stripeUpdateCard);

function stripeUpdateCard(ADMIN_URL, API_URL, $rootScope, $http, $filter, $location, $timeout) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/stripePayments/stripe-update-card.html',
    scope: {
      initComponent: '=',
      activationSuccess: '='
    },
    link: function link(scope, elem, attrs) {
      var stripe = null,
          stripeCard = null;

      scope.initComponent = function () {
        var invoice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        scope.membershipPayment = true; // scope.useDefaultPayment = !!scope.defaultPayment; Not working well with payment intents and SCA

        scope.useDefaultPayment = false; // The invoice may be paid successfully at the same time as the membership is created.
        // Or the invoice may require SCA or card may be declined.

        scope.invoiceid = invoice && invoice.stripeinvoiceid;
        scope.processing = false;
        scope.activationSuccessMessage = '';
        scope.stripeError = '';
        stripe = window.Stripe(smooth.stripe_public_key);
        var elements = stripe.elements();
        stripeCard = elements.create('card');
        stripeCard.mount('#update-card-element');
        stripeCard.addEventListener('change', function (event) {
          var displayError = document.getElementById('card-errors');

          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
      };

      scope.submitCard = function () {
        $rootScope.throbber.show();
        scope.processing = true; // Some payment methods and cards may require further authentication. In all cases
        // retrieve a payment intent from the server. If the transaction requires no further authentication
        // send back the payment intent ID to confirm the transaction.

        stripe.createToken(stripeCard).then(function (result) {
          if (result.error) {
            scope.processing = false;
            return $rootScope.throbber.showError(result.error.message);
          }

          if (scope.invoiceid) {
            // There is an invoice that is unpaid and the payment method has been updated
            return payInvoice(result.token, scope.invoiceid);
          }

          updateCard(result.token);
        });
      };

      var handleSCA = function handleSCA(clientSecret) {
        $rootScope.throbber.show();
        scope.processing = true;
        stripe.handleCardPayment(clientSecret, stripeCard).then(function (response) {
          scope.processing = false;
          $rootScope.throbber.hide();

          if (response.error) {
            scope.stripeError = "Sorry but an error occurred: ".concat(response.error.message);
            $rootScope.safeApply();
            $timeout(function () {
              scope.stripeError = '';
            }, 4000);
            return;
          } // Unless there has been a problem, the invoice will now have been paid.
          // Also a webhook will have been generated that will have been sent to the backend to activate the membership


          scope.activationSuccessMessage = true;
          $rootScope.safeApply();
          $timeout(function () {
            scope.activationSuccessMessage = false;
            $('.update-card-modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            scope.activationSuccess();
          }, 3000);
        }).catch(function (err) {
          console.error(err);
          scope.stripeError = 'Sorry but an error occurred:' + err.msg;
          $timeout(function () {
            scope.stripeError = '';
          }, 3000);
        });
      };

      var updateCard = function updateCard(stripetoken) {
        scope.processing = true;
        $http.put(API_URL + "/api/v1/invoices/update-card", {
          stripetoken: stripetoken
        }).then(function (reply) {
          $rootScope.throbber.hide();
          scope.processing = false;
          var response = reply.data;

          if (!response.success) {
            scope.stripeError = "Sorry but an error occurred: ".concat(response.msg);
            $rootScope.safeApply();
            $timeout(function () {
              scope.stripeError = '';
            }, 3000);
            return;
          }

          if (response.success && !response.requires_action) {
            scope.cardUpdatedSuccessMessage = true;
            $timeout(function () {
              scope.cardUpdatedSuccessMessage = false;
              $('.update-card-modal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              scope.activationSuccess();
            }, 3000);
            return;
          } // Do SCA. This is completed client side.


          if (response.requires_action) {
            return handleSCA(response.payment_intent_client_secret);
          }

          scope.stripeError = "Sorry but an error occurred - unhandled status: ".concat(response.status);
          $timeout(function () {
            scope.stripeError = '';
          }, 3000);
        }).catch(function (error) {
          console.error(error.message);
          scope.stripeError = "Sorry but an error occurred - unhandled status: ".concat(error.message);
          scope.processing = false;
          $timeout(function () {
            scope.stripeError = '';
          }, 3000);
        });
      };

      var payInvoice = function payInvoice(stripetoken, invoiceid) {
        scope.processing = true;
        $http.put(API_URL + "/api/v1/invoices/update-card/".concat(invoiceid), {
          stripetoken: stripetoken
        }).then(function (reply) {
          $rootScope.throbber.hide();
          scope.processing = false;
          var response = reply.data;

          if (!response.success) {
            scope.stripeError = "Sorry but an error occurred: ".concat(response.msg);
            $rootScope.safeApply();
            $timeout(function () {
              scope.stripeError = '';
            }, 3000);
            return;
          }

          if (response.success && !response.requires_action) {
            scope.activationSuccessMessage = true;
            $timeout(function () {
              scope.activationSuccessMessage = false;
              $('.update-card-modal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              scope.activationSuccess();
            }, 3000);
            return;
          } // Do SCA. This is completed client side.


          if (response.requires_action) {
            return handleSCA(response.payment_intent_client_secret);
          }

          scope.stripeError = "Sorry but an error occurred - unhandled status: ".concat(response.status);
          $timeout(function () {
            scope.stripeError = '';
          }, 3000);
        }).catch(function (error) {
          console.error(error.message);
          scope.stripeError = "Sorry but an error occurred - unhandled status: ".concat(error.message);
          scope.processing = false;
          $timeout(function () {
            scope.stripeError = '';
          }, 3000);
        });
      };

      scope.cancelPayment = function () {
        $('.update-card-modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      };
    }
  };
}