(function () {
  userChangePassword.$inject = ["ADMIN_URL", "BASE_URL", "$http", "$timeout"];
  angular.module('userApp').directive('userChangePassword', userChangePassword);

  function userChangePassword(ADMIN_URL, BASE_URL, $http, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/user/change-password-modal.html',
      scope: {
        changePassword: '='
      },
      link: function link(scope, elem, attrs) {
        scope.changePassword = function () {
          elem.modal('show');
        };

        scope.updatePassword = function () {
          var newPassword = scope.newPassword;
          scope.loading = true;
          $http.put(BASE_URL + '/userauth/updatepassword', {
            newpassword: newPassword
          }).then(function (reply) {
            scope.loading = false;
            var response = reply.data;

            if (!response.success) {
              scope.failMessage = response.msg;
              $timeout(function () {
                scope.failMessage = '';
              }, 2000);
              return;
            }

            scope.successMessage = true;
            $timeout(function () {
              scope.successMessage = false;
              elem.modal('hide');
            }, 2000);
          });
        };
      }
    };
  }
})();