// Google recaptcha
var getRecaptchaToken = function getRecaptchaToken() {
  return new Promise(function (resolve, reject) {
    grecaptcha.execute('6Ldq-K8UAAAAAJtC6lVsC9Rc-FJXd8UNDphcYtav', {
      action: 'homepage'
    }).then(function (token) {
      resolve(token);
    });
  });
};

angular.module('userApp').controller('DiscourseSSOController', ["API_URL", "$http", "$window", "$timeout", "$location", "$rootScope", "AuthService", function (API_URL, $http, $window, $timeout, $location, $rootScope, AuthService) {
  var dis = this,
      userInfo = $rootScope.userInfo;
  dis.loading = false;
  dis.user = {
    email: '',
    password: ''
  };
  dis.loggedIn = !!(userInfo && userInfo.email); // jwt token exists - use it to start sso process with discourse server.

  var doSSOLogin = function doSSOLogin() {
    var queryParams = $location.search(),
        sso = queryParams.sso,
        sig = queryParams.sig,
        token = localStorage.getItem('jwt-token');
    $http.post("".concat(API_URL, "/discourse/sso_login"), {
      sso: sso,
      sig: sig
    }, {
      headers: {
        'x-access-token': token
      }
    }).then(function (_ref) {
      var data = _ref.data;
      console.log(data); // Now redirect back to the forum

      $window.location.href = "".concat(data.redirect_url, "?sso=").concat(data.sso, "&sig=").concat(data.sig);
    }).catch(function (err) {
      console.error("Error: ".concat(err.message));
    });
  };

  dis.doUserLogin = function () {
    dis.retryPassword = false;
    dis.loading = false;
    getRecaptchaToken().then(function (token) {
      dis.loading = true;
      AuthService.login(dis.user, token).then(function (response) {
        dis.loading = false;

        if (!response.success) {
          dis.errorMessage = response.msg;
          $timeout(function () {
            dis.errorMessage = '';
          }, 4000);
          return;
        } // Analytics


        ga('set', 'userId', response.user._id);
        doSSOLogin();
      }).catch(function (err) {
        dis.loading = false;
        dis.errorMessage = err.message || err.msg || err;
        $timeout(function () {
          dis.errorMessage = '';
        }, 4000);
      });
    });
  };

  if (dis.loggedIn) return doSSOLogin();
}]);