workshopSelectDatesSection.$inject = ["ADMIN_URL", "$rootScope"];
angular.module('userApp').directive('workshopSelectDatesSection', workshopSelectDatesSection);

function workshopSelectDatesSection(ADMIN_URL, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/scheduling/workshops/workshop-select-dates-section.html',
    replace: true,
    scope: {
      dateChosen: '=',
      chosenDates: '=',
      joinMidsession: '='
    },
    link: function link(scope, elem, attrs) {
      scope.data = {
        dates: scope.chosenDates,
        dateChosen: scope.dateChosen
      };
      scope.$watch('chosenDates', function () {
        scope.data.dates = scope.chosenDates;
      });

      var init = function init() {
        scope.datesInPast = scope.chosenDates.filter(function (date) {
          var now = moment();
          return moment(date).isBefore(now);
        });
        var throbber = $rootScope.throbber,
            month = moment(),
            // Start from today's date / month
        monthObject;
        scope.months = [{
          month: month.month(),
          year: month.format('YYYY')
        }]; // Get a list of the next 12 months

        for (var i = 0; i < 11; i++) {
          monthObject = {
            month: month.add(1, 'months').month(),
            year: month.format('YYYY')
          };
          scope.months.push(monthObject);
        }

        scope.startMonth = 0;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
      };

      init();

      scope.showNextMonths = function () {
        scope.startMonth += 3;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
      };

      scope.showPrevMonths = function () {
        scope.startMonth -= 3;
        scope.monthsToShow = scope.months.slice(scope.startMonth, scope.startMonth + 3);
      };
    }
  };
}