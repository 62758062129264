(function () {
  daysChooser.$inject = ["API_URL", "$http", "CalendarService", "$rootScope"];
  // daysChooser - supply a calendar with checkboxes to easily choose a set of dates
  angular.module('directive.days-chooser', ['service.calendarService']).directive('daysChooser', daysChooser);

  function daysChooser(API_URL, $http, CalendarService, $rootScope) {
    return {
      restrict: 'EA',
      templateUrl: '/templates/directives/datepicker/days-chooser.html',
      replace: true,
      scope: {
        month: '@',
        year: '@',
        daysClosed: '=',
        type: '@',
        typeid: '@'
      },
      link: function link(scope, elem, attrs) {
        var throbber = $rootScope.throbber; // TODO: set locale
        // CalendarService.setOptions({locale:'en'})

        scope.$watch(function () {
          if (scope.daysClosed) {
            init();
          }
        });

        function init() {
          var monthObject = CalendarService.getMonthGrid(parseInt(scope.month), scope.year, scope.daysClosed);
          scope.rows = new Array(Math.floor(monthObject.dates.length / 7));
          scope.columns = new Array(7);
          scope.monthName = monthObject.month.name;
          scope.dates = monthObject.dates;
          scope.days = monthObject.days;
        }

        init();

        scope.toggleDate = function (date, selected) {
          throbber.show();

          if (!selected && scope.daysClosed.indexOf(date) < 0) {
            scope.daysClosed.push(date);
            scope.daysClosed.sort();
          } else {
            scope.daysClosed.splice(scope.daysClosed.indexOf(date), 1);
            scope.daysClosed.sort();
          }

          var params = {};
          params[scope.type] = {
            daysoff: scope.daysClosed
          };
          $http.put(API_URL + '/api/v1/' + scope.type + '/' + scope.typeid, params).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but there was an error: ' + response.msg);
              return;
            }

            var entity = scope.type === 'service' ? response.service : response.resource;
            scope.daysClosed = entity.daysoff;
            throbber.hide('Days off updated');
            init();
          });
        };

        scope.selectAll = function () {
          var dates = CalendarService.getDaysForMonth(scope.month, scope.year);
          throbber.show();
          dates.forEach(function (date) {
            if (scope.daysClosed.indexOf(date) < 0) {
              scope.daysClosed.push(date);
            }
          });
          scope.daysClosed.sort();
          var params = {};
          params[scope.type] = {
            daysoff: scope.daysClosed
          };
          $http.put(API_URL + '/api/v1/' + scope.type + '/' + scope.typeid, params).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but there was an error: ' + response.msg);
              return;
            }

            var entity = scope.type === 'service' ? response.service : response.resource;
            scope.daysClosed = entity.daysoff;
            throbber.hide('Days off updated');
            init();
          });
        };

        scope.selectNone = function () {
          var dates = CalendarService.getDaysForMonth(scope.month, scope.year);
          throbber.show();
          dates.forEach(function (date) {
            scope.daysClosed.splice(scope.daysClosed.indexOf(date), 1);
          });
          scope.daysClosed.sort();
          var params = {};
          params[scope.type] = {
            daysoff: scope.daysClosed
          };
          $http.put(API_URL + '/api/v1/' + scope.type + '/' + scope.typeid, params).then(function (reply) {
            var response = reply.data;

            if (!response.success) {
              throbber.showError('Sorry but there was an error: ' + response.msg);
              return;
            }

            var entity = scope.type === 'service' ? response.service : response.resource;
            scope.daysClosed = entity.daysoff;
            throbber.hide('Days off updated');
            init();
          });
        };
      }
    };
  }
})();