themeChooser.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope"];
angular.module('userApp').directive('themeChooser', themeChooser);

function themeChooser(ADMIN_URL, API_URL, $http, $rootScope) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: ADMIN_URL + '/templates/directives/settings/theme-chooser.html',
    scope: {
      chooseTheme: '='
    },
    link: function link(scope, elem, attrs) {
      scope.themes = [{
        name: 'Original',
        colours: {
          background: '#F7F8FA',
          primary: '#1784D1',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#0BD87B',
          info: '#1784D1',
          danger: '#FF8B60',
          warning: '#FF6E38',
          highlight: '#FFE290'
        }
      }, {
        name: 'Pastel',
        colours: {
          background: '#FFFFFF',
          primary: '#ABDF75',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#C9E3AC',
          info: '#ABDF75',
          danger: '#EA9010',
          warning: '#EA9010',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Hacienda',
        colours: {
          background: '#F8D939',
          primary: '#987E22',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#63A042',
          info: '#241C14',
          danger: '#F44366',
          warning: '#E0900A',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Waterloo',
        colours: {
          background: '#EEEDE7',
          primary: '#7A7E8E',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#5aa063',
          info: '#1f173b',
          danger: '#f44336',
          warning: '#d7902b',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Tumbleweed',
        colours: {
          background: '#f9f9fa',
          primary: '#d9b782',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#76b15f',
          info: '#837e8d',
          danger: '#f44336',
          warning: '#f4a127',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Cornflour',
        colours: {
          background: '#faf8fa',
          primary: '#93cbe5',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#61b77d',
          info: '#4cb3bb',
          danger: '#f44336',
          warning: '#dfa745',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Rock Blue',
        colours: {
          background: '#3d6183',
          primary: '#9da7cc',
          default: '#999999',
          bodytext: '#FFFFFF',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#64ad75',
          info: '#000000',
          danger: '#f44336',
          warning: '#ff9800',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Sun',
        colours: {
          background: '#f6f4eb',
          primary: '#ecc623',
          default: '#FFFFFF',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#000000',
          success: '#7cb643',
          info: '#000000',
          danger: '#f44336',
          warning: '#f9a60b',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Yellow Sea',
        colours: {
          background: '#4a3e29',
          primary: '#f2d218',
          default: '#8ea392',
          bodytext: '#FFFFFF',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#7eba3f',
          info: '#000000',
          danger: '#f44336',
          warning: '#fba907',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Horizon',
        colours: {
          background: '#FFFFFF',
          primary: '#5b7aa7',
          default: '#999999',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#519f6a',
          info: '#162030',
          danger: '#f44336',
          warning: '#ce8f32',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Fruit Salad',
        colours: {
          background: '#ECECE6',
          primary: '#4f985d',
          default: '#999999',
          bodytext: '#000000',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#4da854',
          info: '#3d3d4d',
          danger: '#f44336',
          warning: '#ca981c',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Cello',
        colours: {
          background: '#21325E',
          primary: '#b96249',
          default: '#999999',
          bodytext: '#FFFFFF',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#4da854',
          info: '#000000',
          danger: '#f44336',
          warning: '#6d984e',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Apple',
        colours: {
          background: '#171215',
          primary: '#9DEB1C',
          default: '#999999',
          bodytext: '#FFFFFF',
          text1: '#FFFFFF',
          text2: '#FFFFFF',
          success: '#66bd42',
          info: '#21325e',
          danger: '#f44336',
          warning: '#e3ad0a',
          highlight: '#EBF38B'
        }
      }, {
        name: 'Royal Blue',
        colours: {
          background: '#1C4892',
          primary: '#635ee7',
          default: '#FFF',
          bodytext: '#FFFFFF',
          text1: '#FFFFFF',
          text2: '#000',
          success: '#53977d',
          info: '#000000',
          danger: '#f44336',
          warning: '#d08745',
          highlight: '#EBF38B'
        }
      }];

      scope.updateTheme = function (index) {
        scope.chooseTheme(scope.themes[index]);
      };
    }
  };
}