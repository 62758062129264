WeeklySchedulingController.$inject = ["$scope", "$q", "$http", "API_URL", "$routeParams", "$location", "$rootScope", "$timeout"];
angular.module('userApp').controller('WeeklySchedulingController', WeeklySchedulingController);

function WeeklySchedulingController($scope, $q, $http, API_URL, $routeParams, $location, $rootScope, $timeout) {
  var shed = this;
  shed.loading = false;

  var init = function init() {
    var user = $rootScope.userInfo,
        orgID = user.orgid;
    shed.userIsResource = user.roles[0].type == 'RESOURCE';
    shed.loading = true;
    shed.hideAddSchedule = false;
    shed.services = [];
    shed.resources = [];
    var promises = [$http.get("".concat(API_URL, "/api/v1/resource?enabledonly=true")), $http.get("".concat(API_URL, "/api/v1/services/").concat(orgID, "?enabledonly=true"))]; // Determine which resource we're retrieving shifts for

    shed.resourceID = $routeParams.resourceid || null;

    if (shed.userIsResource && !shed.resourceID) {
      $location.path('/scheduling/weekly/' + user.chosenresources[0]);
      return;
    }

    var params = {};

    if (shed.resourceID && shed.resourceID !== '0') {
      params.resourceid = shed.resourceID;
    } else {
      params.hasresource = false;
    }

    promises.push($http.get(API_URL + '/api/v1/shift', {
      params: params
    }));
    $scope.throbber.show();
    $q.all(promises).then(function (responses) {
      var error = '',
          shifts;
      $scope.throbber.hide();
      shed.loading = false;
      responses.forEach(function (response) {
        var response = response.data;

        if (!response.success) {
          error = response.msg;
        } else {
          if (response.resources && response.resources.length) {
            shed.resources = response.resources;

            if (shed.resourceID) {
              for (var i = 0; i < shed.resources.length; i++) {
                if (shed.resourceID === shed.resources[i]._id) {
                  shed.chosenResource = shed.resources[i];
                }
              }
            }
          }

          if (response.services && response.services.length) {
            shed.services = response.services;
            shed.currentService = shed.services[0];
            $rootScope.orgHasServices = !!response.services.length;
          }

          if (response.shifts) {
            shed.shifts = response.shifts;
            !!$rootScope.getSetupStatus && $rootScope.getSetupStatus();
          }
        }
      }); // End forEach

      if (error) {
        $scope.throbber.showError(error);
      }

      if (smooth.dotour) {
        $timeout(function () {
          smooth.schedulingTour.init();
          smooth.schedulingTour.start();
        }, 0);
      }
    });
  }; // End init


  init();

  shed.changeResource = function (resourceID) {
    if (!resourceID) {
      return;
    }

    shed.resourceID = resourceID;
    shed.getShifts(); // Do soft update for the path

    $location.path('/scheduling/weekly/' + shed.resourceID, false);
  };

  shed.getShifts = function () {
    var params = {};

    if (shed.resourceID) {
      params.resourceid = shed.resourceID;
    } else {
      params.hasresource = false;
    }

    $scope.throbber.show();
    $http.get(API_URL + '/api/v1/shift', {
      params: params
    }).then(function (reply) {
      $scope.throbber.hide();
      var response = reply.data;

      if (!response.success) {
        $scope.throbber.showError('Sorry but there was an error with this operation: ' + response.msg);
        return;
      }

      shed.shifts = response.shifts;
      !!$rootScope.getSetupStatus && $rootScope.getSetupStatus();
    });
  };

  shed.toggleAddShift = function () {
    $('.add-schedule-container').slideToggle();
    shed.addingShift = !shed.addingShift;
  };

  shed.serviceAdded = function (service) {
    shed.services.push(service);
    shed.updateChosenService();
    $rootScope.getSetupStatus && $rootScope.getSetupStatus();

    if (smooth.env === 'production') {
      ga('send', 'event', 'users', 'service added');
      mixpanel.track('Service added');
    }

    try {
      // Mixpanel
      var subscription = $rootScope.userInfo.subscription;

      if (smooth.env === 'production' && subscription.id !== 'FOREVER_FREE') {
        mixpanel.track("Service added");
        ga('send', 'event', 'users', 'service added');
      }
    } catch (err) {
      console.error("Mixpanel error: ".concat(err.message));
    }
  };
}