(function () {
  addAppointment.$inject = ["ADMIN_URL", "API_URL", "$http", "$timeout", "$rootScope"];
  angular.module('directive.add-appointment', []).directive('addAppointment', addAppointment); // Add appointments (can be more than one) from the manage bookings interface.
  // The process is:
  // 1. Choose slot
  // 2. Choose customer or complete registration form
  // 3. Choose number of slots to book
  // 4. Complete booking form
  // 5. Submit appointments for validation
  // 6. If payment required, manage that
  // 7. Done

  function addAppointment(ADMIN_URL, API_URL, $http, $timeout, $rootScope) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/add-appointment.html',
      scope: {
        addBooking: '=',
        forms: '=',
        updateParent: '=',
        calendarSettings: '='
      },
      link: function link(scope, elem, attrs) {
        scope.customer = {};
        scope.form = {};
        scope.appointment = {};
        scope.newAppointment = {};
        scope.confirmationEmails = true;
        scope.creditCount = 0;

        var init = function init() {
          scope.customer = {};
          scope.form.comment = '';
          scope.existingCustomers = [];
          scope.bookingForm = false;
          scope.loading = false;
          scope.state = 'registration'; // Initialise forms

          if (scope.forms.booking && scope.forms.booking[scope.appointment.serviceid]) {
            scope.bookingForm = scope.forms.booking[scope.appointment.serviceid];
          } else if (scope.forms.booking && scope.forms.booking['default']) {
            scope.bookingForm = scope.forms.booking['default'];
          } else {
            scope.bookingForm = null;
          }

          scope.regoForm = scope.forms.registration; // Blank form data

          scope.regoData = {};
          scope.bookingData = {};
          var token = localStorage.getItem('jwt-token'); // Rendering a dropdown for all customers when there were many was causing problems so
          // retrieve customers by ajax.

          $('.choose-customer-dropdown').select2({
            width: '100%',
            placeholder: 'choose existing customer',
            ajax: {
              url: API_URL + '/api/v1/customer?getpackages=true',
              beforeSend: function beforeSend(request) {
                request.setRequestHeader("x-access-token", token);
              },
              data: function data(params) {
                var query = {
                  firstname: params.term,
                  lastname: params.term
                }; // Query parameters will be ?search=[term]&type=public

                return query;
              },
              processResults: function processResults(data) {
                scope.existingCustomers = data.customers;
                return {
                  results: data.customers.map(function (customer) {
                    return {
                      id: customer._id,
                      text: "".concat(customer.lastname, ", ").concat(customer.firstname)
                    };
                  })
                };
              }
            }
          });
          $('.choose-customer-dropdown').on('select2:select', function (e) {
            var customerId = $('.choose-customer-dropdown').find(':selected').val();
            scope.chooseCustomer(customerId);
          });
        };

        scope.chooseCustomer = function (customerId) {
          if (customerId < 1) {
            scope.customer = {};
            scope.regoData = {};
            return;
          }

          var cust = scope.existingCustomers.find(function (customer) {
            return customer._id === customerId;
          }); // Complete basic registration email

          scope.customer = cust; // Now complete the rest of the rego info

          scope.regoData = cust.reginfo || {}; // Determine if this customer has any package credit that can be used to pay for this service

          var serviceID = scope.appointment.serviceid,
              customerPackages = cust.packages;
          scope.customerPayPackages = [];

          for (var packageID in customerPackages) {
            var packageServices = customerPackages[packageID].packageid ? customerPackages[packageID].packageid.services : [];

            if (packageServices.indexOf(serviceID) > -1) {
              customerPackages[packageID].appointmentsPaidFor = 0;
              scope.customerPayPackages.push(customerPackages[packageID]);
            }
          }

          $rootScope.safeApply();
        };

        scope.submitRego = function () {
          if (scope.loading) {
            return;
          }

          scope.loading = true;
          scope.customer.reginfo = scope.regoData;

          if (!scope.customer.firstname || !scope.customer.lastname || !scope.customer.email) {
            scope.loading = false;
            scope.bookingError = 'Please complete customer details';
            $timeout(function () {
              scope.bookingError = '';
            }, 3000);
            return;
          } // Attempt to register the customer. If they already exist make another request
          // to update their details.


          $http.post(API_URL + '/api/v1/customer', {
            newCustomer: scope.customer
          }).then(function (reply) {
            var response = reply.data;
            scope.loading = false;

            if (!response.success) {
              if (response.error === 'CUSTOMER_ALREADY_EXISTS') {
                var customerID = response.customerID;
                scope.appointment.customerid = customerID; // Assume that the user wants to update the details of the existing customer.

                $http.put(API_URL + '/api/v1/customer/' + customerID, {
                  customer: scope.customer
                }).then(function (reply) {
                  var response = reply.data;

                  if (!response.success) {
                    scope.errorMessage = 'Sorry but there was an error with this request: ' + response.msg + '. Please try again or contact support@smoothbook.co if the problem persists';
                    return;
                  }

                  scope.postRego();
                });
              } else {
                scope.errorMessage = 'Sorry but there was an error with this request: ' + response.msg;
                $timeout(function () {
                  scope.errorMessage = '';
                }, 3000);
              }
            } else {
              // Customer has been created properly, go to booking form if necessary
              scope.appointment.customerid = response.customer._id;
              scope.postRego();
            }
          }, function (error) {
            console.error(error);
            scope.loading = false;
            scope.errorMessage = 'Sorry but there was an error with this request: ' + error.errorMessage;
            $timeout(function () {
              scope.errorMessage = '';
            }, 3000);
          });
        }; // Determine whether to show booking form, choose payment option or submit the new appointment


        scope.postRego = function () {
          if (scope.bookingForm && scope.bookingForm.length) {
            scope.state = 'booking';
            return;
          } // Choose customer credit if it's available


          if (scope.customerPayPackages && scope.customerPayPackages.length) {
            scope.appointmentsToPayFor = scope.appointment.numappts;
            scope.state = 'choose-payment';
            return;
          } // No booking form, submit the bookings


          scope.submitBookings();
        };

        scope.postBooking = function () {
          // Determine whether to go to payment page.
          if (scope.customerPayPackages && scope.customerPayPackages.length) {
            scope.appointmentsToPayFor = scope.appointment.numappts;
            scope.state = 'choose-payment';
            return;
          } // No payments, just submit bookings


          scope.submitBookings();
        };

        scope.submitBookings = function () {
          scope.appointment.bookinginfo = scope.bookingData;

          if (scope.form.comment) {
            scope.appointment.comments = [{
              commentBy: $rootScope.userInfo.firstname + ' ' + $rootScope.userInfo.lastname,
              text: scope.form.comment,
              time: moment().toDate()
            }];
          }

          if (typeof scope.appointment.resourceid === 'undefined') {
            delete scope.appointment.resourceid;
          }

          delete scope.appointment.resource;
          delete scope.appointment.service;
          scope.loading = true;
          var params = {
            appointments: [scope.appointment],
            confirmemails: scope.confirmationEmails,
            bookremaining: !!scope.settings.bookremaining,
            bookall: !!scope.settings.bookall
          };

          if (scope.customerPayPackages && scope.customerPayPackages.length) {
            var paymentPackages = [];
            scope.customerPayPackages.forEach(function (payPackage) {
              if (payPackage.appointmentsPaidFor) {
                paymentPackages.push({
                  packageid: payPackage._id,
                  appointmentsPaidFor: payPackage.appointmentsPaidFor
                });
              }
            });

            if (paymentPackages.length) {
              params.paymentpackages = paymentPackages;
            }
          } // Now submit the appointment


          $http.post(API_URL + '/api/v1/appointments/manage/create', params).then(function (reply) {
            var response = reply.data;
            scope.loading = false;

            if (!response.success) {
              scope.bookingError = response.msg;
              return;
            }

            scope.bookingSuccess = true;
            scope.updateParent();
            $timeout(function () {
              scope.bookingSuccess = false;
              $('.add-appointment-modal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
            }, 1500);
          }).catch(function (error) {
            scope.loading = false;
            $('.add-appointment-modal').modal('hide');
            $rootScope.throbber.showError("Sorry but an error occurred with this request: ".concat(error.data.msg, ". Please contact support@smoothbook.co if this error persists."));
          });
        };

        scope.adjustCredit = function (index, up) {
          if (up) {
            if (scope.customerPayPackages[index].appointmentsPaidFor === scope.customerPayPackages[index].credits) {
              scope.creditLimit = true;
              $timeout(function () {
                scope.creditLimit = false;
              }, 2000);
              return;
            }

            scope.customerPayPackages[index].appointmentsPaidFor++;
          } else {
            if (scope.customerPayPackages[index].appointmentsPaidFor === 0) {
              return;
            }

            scope.customerPayPackages[index].appointmentsPaidFor--;
          }

          scope.creditCount = 0;
          scope.customerPayPackages.forEach(function (pack) {
            scope.creditCount += pack.appointmentsPaidFor;
          });
          scope.appointmentsToPayFor = scope.appointment.numappts - scope.creditCount;
        }; // Launch modal and get necessary information


        scope.addBooking = function (newAppointment, shift) {
          scope.shift = shift;
          scope.settings = {
            bookremaining: shift.bookremaining,
            bookall: shift.bookall
          };
          scope.appointment = newAppointment;
          scope.appointment.numappts = 1;
          scope.customerPayPackages = [];
          init();
          $('.add-appointment-modal').modal('show');
        };

        scope.toggleBookAll = function (isBookall) {
          if (isBookall && scope.settings.bookall) scope.settings.bookremaining = false;
          if (!isBookall && scope.settings.bookremaining) scope.settings.bookall = false;
        };
      }
    };
  }
})();