subRequired.$inject = ["jwtHelper"];
angular.module('userApp').directive('subRequired', subRequired);

function subRequired(jwtHelper) {
  return {
    restrict: 'E',
    replace: true,
    template: '<p ng-if="premiumSubRequired" class="sub-required">' + '<span class="glyphicon glyphicon-star" aria-hidden="true"></span>' + 'This is a premium feature. You can access this and many other features with a subscription to Smoothbook. ' + '<a ng-href="#/subscribe">Please click here to find out more</a></p>',
    link: function link(scope, elem, attrs) {
      // Get user info from the token and store globally.
      var token = localStorage.getItem('jwt-token'),
          payload = jwtHelper.decodeToken(token); // Disable functionality in child components of the

      scope.premiumSubRequired = window.smooth.charging && payload.subscription && payload.subscription.id === 'BASIC';
    }
  };
}