(function () {
  calendarDemo.$inject = ["ADMIN_URL", "$http", "$rootScope"];
  angular.module('userApp').directive('calendarDemo', calendarDemo);

  function calendarDemo(ADMIN_URL, $http, $rootScope) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/calendar-demo.html',
      scope: {
        viewCalendar: '=',
        calendarUrl: '='
      },
      link: function link(scope, elem, attrs) {
        scope.viewCalendar = function () {
          $('#demo-calendar-modal').modal('show');
          document.getElementById('calendar-preview-iframe').src = document.getElementById('calendar-preview-iframe').src;
        };
      }
    };
  }
})();