import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
AppointmentExplorerService.$inject = ["$http", "API_URL", "$rootScope"];

var moment = require('moment-timezone');

angular.module('userApp').service('AppointmentExplorerService', AppointmentExplorerService);

function AppointmentExplorerService($http, API_URL, $rootScope) {
  var _$rootScope$userInfo = $rootScope.userInfo,
      timezone = _$rootScope$userInfo.timezone,
      locale = _$rootScope$userInfo.locale;
  moment.tz.setDefault(timezone);
  moment.locale(locale);
  var tableColumns = [{
    key: 'start',
    label: 'Date / time'
  }, {
    key: 'name',
    label: 'Name'
  }]; // Booking form fields can be dependent on the service

  var getBookingFormColumns = function getBookingFormColumns(appointments, serviceForms) {
    try {
      var columns = [];
      var columnsObject = appointments.reduce(function (accum, appointment) {
        var serviceID = appointment.serviceid._id,
            useDefault = !serviceForms[serviceID];
        if (useDefault && accum['default']) return accum;
        if (!useDefault && accum[serviceID]) return accum;
        var fields = useDefault ? serviceForms.default : serviceForms[serviceID]; // It's possible that there is no default booking form

        if (!fields) return accum;
        fields.forEach(function (field) {
          columns.push({
            type: 'bookingform',
            serviceid: appointment.serviceid._id,
            label: field.label,
            key: field._id
          });
        });

        if (useDefault) {
          accum['default'] = true;
        } else {
          accum[serviceID] = true;
        }

        return accum;
      }, {});
      return columns;
    } catch (err) {
      console.error(err);
    }
  };

  this.getAppointments = function (_ref) {
    var filters = _ref.filters,
        page = _ref.page,
        perpage = _ref.perpage;

    var params = _objectSpread({}, filters, {
      page: page,
      perpage: perpage
    });

    if (params.resourceid === '0') delete params.resourceid;
    if (params.serviceid === '0') delete params.serviceid;
    if (params.customerid === '0') delete params.customerid;
    return new Promise(function (resolve, reject) {
      $http.get(API_URL + '/api/v2/appointments', {
        params: params
      }).then(function (_ref2) {
        var response = _ref2.data;
        resolve(response);
      }).catch(function (err) {
        console.error(err.message);
        throbber.showError(err.message);
        reject();
      });
    });
  }; // Create a two dimensional array with columns based on appointment data and data required


  this.getDataObject = function (appointments) {
    var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        filters = _ref3.filters,
        forms = _ref3.forms;

    moment.tz.setDefault(timezone);
    moment.locale(locale); // 1. Need to know how many columns. This is the default plus other
    // required ones.

    var columns = JSON.parse(JSON.stringify(tableColumns));

    if (filters.showemail) {
      columns.push({
        key: 'customerid.email',
        label: 'Email'
      });
    } // Service name not currently optional?


    columns.push({
      key: 'serviceid.name',
      label: 'Service'
    });

    if (filters.showresource) {
      columns.push({
        key: 'resourceid.name',
        label: 'Resource'
      });
    } // Add fields from registration form


    if (filters.showregoform) {
      forms.registration.forEach(function (field) {
        columns.push({
          type: 'regofield',
          label: field.label,
          key: field._id
        });
      });
    } // Booking form fields


    if (filters.showbookingform) {
      var newColumns = getBookingFormColumns(appointments, forms.booking);
      columns = _toConsumableArray(columns).concat(_toConsumableArray(newColumns));
    } // Add customer comments (array)


    if (filters.showcustomercomments) {
      columns.push({
        type: 'customercomments',
        label: 'Customer Comments'
      });
    } // Add booking comments (array)


    if (filters.showappointmentcomments) {
      columns.push({
        type: 'appointmentcomments',
        label: 'Appointment Comments'
      });
    } // Add payment info


    if (filters.showpaymentinfo) {
      columns.push({
        type: 'paymentinfo',
        label: 'Payment'
      });
    } // One row in the table for each appointment


    var header = columns.map(function (column) {
      return column.label;
    });
    var appointmentList = appointments.map(function (appointment) {
      return columns.map(function (column) {
        var cellValue = 'n/a';

        try {
          if (column.type && column.type === 'regofield') {
            if (appointment.customerid && appointment.customerid.reginfo) {
              return cellValue = appointment.customerid.reginfo[column.key] || 'n/a';
            } else {
              return cellValue;
            }
          }

          if (column.type && column.type === 'bookingform') {
            if (appointment.bookinginfo && appointment.bookinginfo[column.key]) {
              return cellValue = appointment.bookinginfo[column.key];
            } else {
              return cellValue;
            }
          }

          if (column.type && column.type === 'customercomments') {
            // The cell is going to have each comment on a separate row
            var comments = appointment.customerid.comments;
            if (!comments || !comments.length) return cellValue = 'none';
            cellValue = comments.reduce(function (accum, comment) {
              return "".concat(accum).concat(comment.text, "\n");
            }, '');
            return cellValue;
          }

          if (column.type && column.type === 'appointmentcomments') {
            // The cell is going to have each comment on a separate row
            var _comments = appointment.comments;
            if (!_comments || !_comments.length) return cellValue = 'none';
            cellValue = _comments.reduce(function (accum, comment) {
              return "".concat(accum).concat(comment.text, "\n");
            }, '');
            return cellValue;
          }

          if (column.type && column.type === 'paymentinfo') {
            var paymentMethod = appointment.paymentinfo && appointment.paymentinfo.type ? appointment.paymentinfo.type : 'none';

            switch (paymentMethod) {
              case 'SINGLE_APPOINTMENT':
                return cellValue = "Single appointment payment of ".concat(appointment.paymentinfo.formatted);

              case 'CREDIT':
                var packageName = appointment.paymentinfo.customerpackage.packageid.name;
                return cellValue = "Paid for with ".concat(packageName, " package");

              case 'MEMBERSHIP':
                var membershipName = appointment.paymentinfo.membershipid.membershipid.name;
                return cellValue = "Paid for with ".concat(membershipName, " membership");

              default:
                return cellValue = 'no payment';
            }
          }

          var keys = column.key.split('.');
          if (keys[0] === 'start') return cellValue = filters.dateutc ? moment.unix(appointment.start).toISOString() : moment.unix(appointment.start).format('LLL');
          if (keys[0] === 'name') return cellValue = "".concat(appointment.customerid.lastname, ", ").concat(appointment.customerid.firstname);
          cellValue = keys.length === 2 ? appointment[keys[0]][keys[1]] : appointment[keys[0]];
        } catch (e) {
          cellValue = 'n/a';
        }

        return cellValue;
      });
    });
    return {
      header: header,
      appointmentList: appointmentList
    };
  }; // Create a DOM table based on the array


  this.getDOMTable = function (_ref4) {
    var header = _ref4.header,
        appointmentList = _ref4.appointmentList;
    var table = document.createElement('table'),
        thead = document.createElement('thead'),
        tbody = document.createElement('tbody');
    var theadRow = document.createElement('tr');
    header.forEach(function (heading) {
      var thCell = document.createElement('th');
      thCell.innerText = heading;
      theadRow.appendChild(thCell);
    });
    thead.appendChild(theadRow);
    table.appendChild(thead);
    appointmentList.forEach(function (appointmentRow) {
      var row = document.createElement('tr');
      appointmentRow.forEach(function (cell) {
        var td = document.createElement('td');
        td.innerText = cell;
        row.appendChild(td);
      });
      tbody.appendChild(row);
    });
    table.appendChild(tbody);
    table.classList.add('table');
    return table;
  };
}