angular.module('userApp').controller('SuperLoginController', ["API_URL", "$http", "$timeout", "$location", function (API_URL, $http, $timeout, $location) {
  var log = this;
  log.user = {};

  log.userLogin = function () {
    $http.post(API_URL + '/userauth/superlogin', {
      user: log.user
    }).success(function (response) {
      if (!response.success) {
        log.errorMessage = response.msg;
        $timeout(function () {
          log.errorMessage = '';
        }, 2000);
      }

      $location.path('/dashboard');
    }).error(function (data, status) {
      console.error('HTTP error: ' + status);
    });
  };
}]);