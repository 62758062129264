(function () {
  angular.module('userApp').directive('manageAppointmentsDatepicker', ManageAppointmentsDatepicker);

  function ManageAppointmentsDatepicker() {
    return {
      restrict: 'EA',
      template: '<div class="manage-appts-choose-date"></div>',
      scope: {
        date: '=',
        availableDates: '=',
        dateChanged: '='
      },
      link: function link(scope, elem, attrs) {
        var options = {
          format: 'yyyy-mm-dd',
          weekStart: 1,
          beforeShowDay: beforeShowDay
        };

        if (scope.date) {
          var momentDate = moment(scope.date);
          options.defaultViewDate = {
            year: momentDate.year(),
            month: momentDate.month(),
            day: 1
          };
        }

        scope.$watch('availableDates', function () {
          if (scope.availableDates && scope.availableDates.length) {
            $('.manage-appts-choose-date').datepicker(options).on('changeDate', changeDate).on('changeMonth', changeMonth);
          }
        }); // Callbacks for datepicker

        function beforeShowDay(date) {
          var date = moment([date.getFullYear(), date.getMonth(), date.getDate()]).format('YYYY-MM-DD'),
              today = moment().format('YYYY-MM-DD'),
              options = {};

          if (date === today) {
            options.classes = 'mb-today';
          } // Config is good, set up the calendar: supply an array of available days


          var dates = [];
          scope.availableDates.forEach(function (date) {
            dates.push(date.date);
          });

          if (dates.indexOf(date) > -1) {
            options.enabled = true;
            options.classes = options.classes + ' mb-day-available';
          } else {
            options.enabled = false;
          }

          return options;
        }

        function changeDate(e) {
          // Called when a date is chosen on the datepicker
          scope.date = moment([e.date.getFullYear(), e.date.getMonth(), e.date.getDate()]).format('YYYY-MM-DD');
          scope.dateChanged(scope.date);
        }

        function changeMonth(e) {
          // Called when the month is changed on the datepicker
          var startEnd = getMonthDateRange(e.date.getMonth(), e.date.getFullYear());
        } // Get start and end dates in usable format


        function getMonthDateRange(month, year) {
          // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
          // array is 'year', 'month', 'day', etc
          var startDate = moment([year, month, 1]).format('YYYY-MM-DD');
          var endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
          return {
            start: startDate,
            end: endDate
          };
        }
      }
    };
  }
})();