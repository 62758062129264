addWorkshopScheduleContainer.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$timeout"];
angular.module('userApp').directive('addWorkshopScheduleContainer', addWorkshopScheduleContainer);

function addWorkshopScheduleContainer(ADMIN_URL, API_URL, $http, $rootScope, $timeout) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/scheduling/workshops/add-workshop-schedule-container.html',
    replace: true,
    scope: {
      userIsResource: '=?',
      services: '=',
      updateChosenService: '=',
      resources: '=',
      resourceid: '=',
      changeResource: '=',
      toggleForm: '=',
      getShifts: '=',
      workshopSettings: '=',
      workshopDates: '=',
      workshopSettingsUpdated: '='
    },
    link: function link(scope, elem, attrs) {
      var throbber = $rootScope.throbber;
      assignTimes();
      scope.form = {};
      scope.shiftTimes = {
        fromHour: '00',
        fromMinute: '00',
        toHour: '00',
        toMinute: '00',
        gapHours: '00',
        gapMinutes: '00',
        durationHours: '00',
        durationMinutes: '00'
      }; // Below are the default settings for a new shift.
      // If a shift is being edited, these settings will be updated when the call to the API returns.

      scope.form.openings = 1;
      scope.form.bookmulti = 1; // Number of times the same slot can be booked by the same person

      scope.form.resourceid = scope.resourceid || '0';
      scope.form.joinmidsession = true;
      scope.form.bookremaining = false;
      scope.$watch('services', function () {
        if (!scope.services.length) return;
        scope.form.serviceid = scope.services[0]._id;
      });
      scope.$watch('workshopSettings', function () {
        if (!scope.workshopSettings.serviceid) return;
        scope.form = {
          serviceid: scope.workshopSettings.serviceid,
          resourceid: !!scope.workshopSettings.resourceid ? scope.workshopSettings.resourceid : '0',
          openings: scope.workshopSettings.openings,
          bookmulti: scope.workshopSettings.bookmulti,
          joinmidsession: scope.workshopSettings.joinmidsession,
          bookremaining: scope.workshopSettings.bookremaining,
          bookall: scope.workshopSettings.bookall
        };
        var startTimes = scope.workshopSettings.workshopstart.split(':'),
            endTimes = scope.workshopSettings.workshopend.split(':');
        scope.shiftTimes = {
          fromHour: startTimes[0],
          fromMinute: startTimes[1],
          toHour: endTimes[0],
          toMinute: endTimes[1]
        };
      });

      scope.formChanged = function () {
        scope.form.type = 'workshop';
        scope.form.isworkshop = true;
        scope.form.duration = 0; // Format times

        scope.form.workshopstart = scope.shiftTimes.fromHour + ':' + scope.shiftTimes.fromMinute;
        scope.form.workshopend = scope.shiftTimes.toHour + ':' + scope.shiftTimes.toMinute;
        scope.form.valid = !scope.shiftLengthError && !scope.validationError; // If joinmidsession is false then bookremaining must be true

        if (!scope.form.joinmidsession) {
          scope.form.bookall = true;
          scope.form.bookremaining = false;
        }

        scope.workshopSettingsUpdated(scope.form);
      };

      scope.toggleBookRemaining = function (itemChanged) {
        if (itemChanged === 'bookall') {
          if (scope.form.bookall) {
            scope.form.bookremaining = false;
          }
        } else {
          if (scope.form.bookremaining) {
            scope.form.bookall = false;
          }
        }

        scope.formChanged();
      }; // Make sure that 'to' time is after 'from' time


      scope.validateTime = function () {
        if (parseInt(scope.shiftTimes.fromHour) >= parseInt(scope.shiftTimes.toHour)) {
          scope.shiftTimes.toHour = scope.shiftTimes.fromHour;

          if (parseInt(scope.shiftTimes.fromMinute) >= parseInt(scope.shiftTimes.toMinute)) {
            scope.shiftTimes.toMinute = scope.shiftTimes.fromMinute;
          }
        }

        if (scope.shiftTimes.fromHour > scope.shiftTimes.toHour || scope.shiftTimes.fromHour === scope.shiftTimes.toHour && scope.shiftTimes.fromMinute >= scope.shiftTimes.toMinute) {
          scope.shiftLengthError = true;
        } else {
          scope.shiftLengthError = false;
        }

        scope.formChanged();
      }; // Just create two arrays of hours and minutes for the dropdowns


      function assignTimes() {
        scope.selectHours = [];

        for (var j = 0; j < 24; j++) {
          var hour = j < 10 ? '0' + j : j;
          scope.selectHours.push(hour);
        }

        scope.selectMinutes = [];

        for (var i = 0; i < 60; i++) {
          var minute = i < 10 ? '0' + i : i;
          scope.selectMinutes.push(minute);
        }
      }

      scope.addResource = function (resource) {
        scope.resources.push(resource);
        scope.form.resourceid = resource._id;
        scope.formChanged();
      };

      scope.addService = function (service) {
        scope.services.push(service);
        scope.form.serviceid = service._id;
        scope.formChanged();
      };

      scope.openingsChange = function () {
        scope.validationError = '';

        if (!scope.form.openings || parseInt(scope.form.openings) <= 0) {
          scope.validationError = 'Workshop must have at least one opening';
          return;
        }

        if (scope.form.bookmulti > scope.form.openings) {
          scope.form.bookmulti = scope.form.openings;
        }

        scope.formChanged();
      };

      scope.bookMultiChange = function () {
        scope.validationError = '';

        if (!scope.form.bookmulti || parseInt(scope.form.bookmulti) <= 0) {
          scope.validationError = "'Can be booked x times' must be at least one";
          return;
        }

        if (scope.form.openings < scope.form.bookmulti) {
          scope.form.openings = scope.form.bookmulti;
        }

        scope.formChanged();
      };

      scope.updateChosenService = function () {
        if (scope.services && scope.services.length > 0) {
          scope.form.serviceid = scope.services[0]._id;
        }
      };
    }
  };
}