(function () {
  ApisController.$inject = ["BASE_URL", "API_URL", "$http", "$cookies", "$rootScope", "$timeout"];
  angular.module('userApp').controller('ApisController', ApisController);

  function ApisController(BASE_URL, API_URL, $http, $cookies, $rootScope, $timeout) {
    var api = this,
        throbber = $rootScope.throbber,
        userInfo = $rootScope.userInfo;
    api.user = {}; // Some settings are only accessible to owners

    api.isOwner = !!userInfo.roles.find(function (role) {
      return role.type === 'OWNER';
    });

    api.init = function () {
      // Will redirect the mini browser to the Mailchimp auth window
      api.mailchimpAuthUri = API_URL + '/mailchimp/auth/authorise'; // This call will return the actual url for the Mailchimp api.,
      // e.g. https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=851495201746&redirect_uri=http%3A%2F%2F127.0.0.1%3A3000%2Fmailchimp%2Fauth%2Fcallback

      api.zoomAuthUri = API_URL + '/zoom/auth/authorise?orgid=' + userInfo.orgid;
      smooth.mailchimpAuthUri = api.mailchimpAuthUri;
      smooth.zoomAuthUri = api.zoomAuthUri;
      throbber.show(); // Get user info for calendar settings

      $http.get(API_URL + '/api/v1/user/' + $rootScope.userInfo._id).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError('response.msg');
          return;
        }

        api.user = response.user;
        var userChanged = false;

        if (typeof response.user.googlesharelist === 'undefined') {
          response.user.googlesharelist = [];
          response.user.googlesharelistchanged = true;
          userChanged = true;
        }

        if (typeof api.user.googleallresourcesupdate === 'undefined') {
          api.user.googleallresourcesupdate = true;
          userChanged = true;
        }

        if (typeof api.user.googlenoresourceupdate === 'undefined') {
          api.user.googlenoresourceupdate = true;
          userChanged = true;
        } // Set defaults in the background


        if (userChanged) api.updateUserSettings();
        throbber.hide();
      }); // Assign local functions to window so that they can be called in the global scope
      // when the mini-browser closes.

      $timeout(function () {
        window.getMailchimpLists = api.getMailchimpLists;
        window.handleZoomResult = api.handleZoomResult;
      }, 0); // Only owners can change Mailchimp settings

      if (!api.isOwner) return; // Get Mailchimp info

      api.mailchimpLoading = true;
      $http.get(API_URL + '/mailchimp/lists', {
        withCredentials: true
      }).then(function (reply) {
        api.mailchimpLoading = false;
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          return;
        }

        if (!response.connected) {
          api.mailchimpConnected = false;
          return;
        }

        api.mailchimpConnected = true;
        api.mailchimpLists = response.lists;
        api.selectedList = response.selectedlist || '0';
      });
      api.zoomLoading = true;
      api.zoomConnected = false;
      $http.get(API_URL + '/zoom/isconnected').then(function (_ref) {
        var data = _ref.data;
        api.zoomLoading = false;
        api.zoomConnected = data.connected;
      }).catch(function (err) {
        api.zoomLoading = false;
        console.error("Zoom api error: ".concat(err.message));
      });
    };

    api.init();

    api.updateUserSettings = function (callBack) {
      throbber.show();
      var userInfo = api.user;
      $http.put(API_URL + '/api/v1/user/' + $rootScope.userInfo._id, {
        userinfo: userInfo
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError('response.msg');
          return;
        }

        api.user = response.user;
        throbber.hide('Settings updated');
        callBack && callBack();
      });
    };

    api.getMailchimpLists = function () {
      throbber.show();
      $http.get(BASE_URL + '/mailchimp/lists').then(function (reply) {
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          throbber.showError('Sorry an error occurred: ' + response.msg);
          return;
        }

        if (!response.connected) {
          api.mailchimpConnected = false;
          return;
        }

        api.mailchimpConnected = true;
        api.mailchimpLists = response.lists;
        api.selectedList = response.selectedlist || '0';
      });
    };

    api.saveMailchimpList = function () {
      var selectedList = api.selectedList !== '0' ? api.selectedList : '';
      throbber.show();
      $http.put(BASE_URL + '/mailchimp/updatelist', {
        mailchimplist: selectedList
      }).then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          throbber.showError(response.msg);
          return;
        }

        throbber.hide('Mailchimp list updated successfully');
      });
    };

    api.addGoogleEmail = function () {
      if (api.user.googlesharelist.indexOf(api.newGoogleEmail) >= 0) {
        return;
      }

      api.user.googlesharelist.push(api.newGoogleEmail);
      api.user.googlesharelistchanged = true;
      api.updateUserSettings(function () {
        api.newGoogleEmail = '';
      });
    };

    api.removeGoogleEmail = function (index) {
      api.user.googlesharelist.splice(index, 1);
      api.user.googlesharelistchanged = true;
      api.updateUserSettings(function () {
        api.newGoogleEmail = '';
      });
    };

    api.sendGoogleCalendar = function () {
      throbber.show();
      $http.get(BASE_URL + '/google/resetcalendar').then(function (reply) {
        throbber.hide();
        var response = reply.data;

        if (!response.success) {
          return throbber.showError(response.msg);
        }

        api.resetMessage = true;
        $timeout(function () {
          api.resetMessage = false;
        }, 3000);
      });
    };

    api.handleZoomResult = function () {
      api.zoomLoading = true;
      api.zoomConnected = false;
      $http.get(API_URL + '/zoom/isconnected').then(function (_ref2) {
        var data = _ref2.data;
        api.zoomLoading = false;
        api.zoomConnected = data.connected;
      }).catch(function (err) {
        api.zoomLoading = false;
        console.error("Zoom api error: ".concat(err.message));
      });
    };

    api.disconnectZoom = function () {
      $http.put(API_URL + '/zoom/disconnect').then(function (_ref3) {
        var data = _ref3.data;
        api.zoomDisconnected = true;
        api.zoomConnected = false;
        $timeout(function () {
          api.zoomDisconnected = false;
        }, 3000);
      }).catch(function (err) {
        console.error(err.message);
      });
    };
  }
})();