(function () {
  DaysClosedController.$inject = ["API_URL", "$http", "$scope"];
  angular.module('userApp').controller('DaysClosedController', DaysClosedController);

  function DaysClosedController(API_URL, $http, $scope) {
    var day = this,
        month = moment(),
        // Start from today's date / month
    monthObject;

    day.init = function (done) {
      // Get all days closed for the org
      $scope.throbber.show();
      $http.get(API_URL + '/api/v1/daysclosed').then(function (reply) {
        var response = reply.data;

        if (!response.success) {
          $scope.throbber.showError('Sorry but there was an error with this operation: ' + response.msg);
          return;
        }

        $scope.throbber.hide();
        day.daysClosed = response.daysclosed;
        done && done();
      });
      day.months = [{
        month: month.month(),
        year: month.format('YYYY')
      }]; // Get a list of the next 12 months

      for (var i = 0; i < 11; i++) {
        monthObject = {
          month: month.add(1, 'months').month(),
          year: month.format('YYYY')
        };
        day.months.push(monthObject);
      }
    };

    day.init();
  }
})();