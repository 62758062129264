(function () {
  schedulingHelpModal.$inject = ["ADMIN_URL"];
  angular.module('userApp').directive('schedulingHelpModal', schedulingHelpModal);

  function schedulingHelpModal(ADMIN_URL) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/scheduling/scheduling-help-modal.html',
      replace: true,
      scope: {
        showModal: '='
      },
      link: function link(scope, elem, attrs) {
        scope.showModal = function () {
          $('.scheduling-help-modal').modal('show');
        };
      }
    };
  }
})();