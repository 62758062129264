import _objectSpread from "@babel/runtime/helpers/objectSpread";
addMembership.$inject = ["ADMIN_URL", "API_URL", "$http", "$timeout", "$rootScope"];
angular.module('userApp').directive('addMembership', addMembership);

function addMembership(ADMIN_URL, API_URL, $http, $timeout, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/add-membership.html',
    replace: true,
    scope: {
      parent: '=controllerScope',
      servicesList: '@services',
      packagesList: '@packages',
      currency: '='
    },
    link: function link(scope, elem, attrs) {
      scope.editing = false;
      var $elem = $('.add-membership-modal'),
          throbber = $rootScope.throbber;

      scope.parent.addMembershipModal = function () {
        scope.form = {}; // Must have default plan

        scope.form.plans = [{
          months: 1,
          cost: 0,
          currency: scope.currency.code,
          editing: true
        }];
        scope.form.services = [];
        scope.form.packages = [];
        $timeout(function () {
          $('.select-membership-services').select2();
          $('.select-membership-packages').select2();
        }, 0);
        scope.editing = false;
        $elem.modal('show');
      };

      scope.addPlan = function (ev) {
        ev.preventDefault();
        scope.form.plans = scope.form.plans.map(function (plan) {
          return _objectSpread({}, plan, {
            editing: false
          });
        });
        scope.form.plans.push({
          months: 1,
          cost: 0,
          currency: scope.currency.code,
          editing: true
        });
      };

      scope.deletePlan = function (ev, index) {
        ev.preventDefault();
        if (scope.form.plans.length < 2) return;
        if (!scope.editing) return scope.form.plans.splice(index, 1);
        if (!confirm('Delete plan? Note this will not affect existing subscribers to this plan. You must save the membership (the blue \'save membership\' button below) to save this action.')) return;
        var plan = scope.form.plans[index];
        throbber.show();
        $http.delete("".concat(API_URL, "/api/v1/membership/").concat(scope.membershipID, "/plan/").concat(plan._id)).then(function (reply) {
          var response = reply.data;
          if (!response.success) return throbber.showError(response.msg);
          scope.form.plans = response.membership.plans;
          scope.parent.init();
          scope.planDeletedSuccess = true;
          $timeout(function () {
            scope.planDeletedSuccess = false;
          }, 3000);
        }).catch(function (err) {
          throbber.showError("Sorry but an error occurred: ".concat(err.message));
        });
      }; // Save a new plan to the DB


      scope.savePlan = function (ev, index) {
        ev.preventDefault();
        var valid = true,
            planObject = {};
        scope.form.plans.forEach(function (plan) {
          if (plan.cost <= 0) return scope.plansError = "All plans must have a non-zero cost";
          if (plan.months <= 0) return scope.plansError = 'Billing cycle must be at least one month';
          if (typeof planObject[plan.months] !== 'undefined') return scope.plansError = "All plans must have a different billing cycle. 'months' must be different for all plans";
          planObject[plan.months] = true;
        });

        if (scope.plansError) {
          $timeout(function () {
            scope.plansError = '';
          }, 3000);
          return;
        }

        scope.form.plans[index].editing = false; // Don't save the plan if the membership has not yet been created

        if (!scope.editing) {
          scope.form.plans.sort(function (planA, planB) {
            return planA.months > planB.months ? 1 : -1;
          });
          return;
        }

        var plan = scope.form.plans[index];
        throbber.show();
        $http.post("".concat(API_URL, "/api/v1/membership/").concat(scope.membershipID, "/plan"), {
          plan: plan
        }).then(function (reply) {
          var response = reply.data;
          if (!response.success) return throbber.showError(response.msg);
          scope.form.plans = response.membership.plans;
          scope.parent.init();
          scope.planCreatedSuccess = true;
          $timeout(function () {
            scope.planCreatedSuccess = false;
          }, 3000);
        }).catch(function (err) {
          throbber.showError("Sorry but an error occurred: ".concat(err.message));
        });
      };

      scope.saveMembership = function (ev) {
        ev.preventDefault();

        var membershipToSave = _objectSpread({}, scope.form); // Validate that the user has selected at least one service or one package


        if ((!membershipToSave.services || !membershipToSave.services.length) && (!membershipToSave.packages || !membershipToSave.packages.length)) {
          scope.serviceValidationError = true;
          $timeout(function () {
            scope.serviceValidationError = false;
          }, 4000);
          return;
        } // Plans must have non-zero cost and different billing cycles


        var plansCostValid = true,
            planMonthsValid = true,
            plansObject = {};

        if (!membershipToSave.plans.length) {
          scope.plansError = 'You must supply at least one plan';
          $timeout(function () {
            scope.plansError = '';
          }, 3000);
        }

        membershipToSave.plans = membershipToSave.plans.map(function (plan) {
          return _objectSpread({}, plan, {
            editing: false
          });
        });
        var plans = !scope.editing ? membershipToSave.plans : []; // Don't save plans if the membership is being edited

        for (var i = 0; i < plans.length; i++) {
          if (typeof plansObject[plans[i].months] !== 'undefined') {
            planMonthsValid = false;
            break;
          }

          plansObject[plans[i].months] = plans[i].months;

          if (!plans[i].cost || !(plans[i].cost > 0)) {
            plansCostValid = false;
            break;
          }
        }

        if (!planMonthsValid) {
          scope.plansError = "All plans must have a different billing cycle. 'months' must be different for all plans";
          $timeout(function () {
            scope.plansError = '';
          }, 3000);
          return;
        }

        if (!plansCostValid) {
          scope.plansError = 'All plans must have a non zero cost';
          $timeout(function () {
            scope.plansError = '';
          }, 3000);
          return;
        }

        throbber.show();
        $http({
          method: scope.editing ? 'PUT' : 'POST',
          url: scope.editing ? API_URL + '/api/v1/membership/' + scope.membershipID : API_URL + '/api/v1/membership',
          data: {
            membership: membershipToSave
          }
        }).then(function (reply) {
          var response = reply.data;

          if (!response.success) {
            throbber.hide();
            throbber.showError('Sorry but there was an error with this query: ' + response.msg);
            return;
          }

          scope.form.plans = response.membership.plans;
          throbber.hide('Membership ' + scope.editing ? 'updated' : 'saved' + ' successfully');
          scope.updateSuccessful = true;
          $timeout(function () {
            scope.updateSuccessful = false;
          }, 2000);
          scope.parent.init();

          if (!scope.editing) {
            $elem.modal('hide');
          }
        });
      };

      scope.parent.editMembershipModal = function (membershipID) {
        throbber.show();
        scope.form = {};
        scope.membershipID = membershipID;
        $http.get(API_URL + '/api/v1/membership/' + membershipID).then(function (reply) {
          var response = reply.data;
          throbber.hide();

          if (!response.success) {
            throbber.showError('Sorry but there was an error with this request: ' + response.msg);
            return;
          }

          var services = [],
              packages = [];
          scope.form = response.membership;
          response.membership.services && response.membership.services.forEach(function (service) {
            services.push(service._id);
          });
          scope.form.services = services;
          $timeout(function () {
            $('.select-membership-services').select2();
          }, 0);
          response.membership.packages && response.membership.packages.forEach(function (thisPackage) {
            packages.push(thisPackage._id);
          });
          scope.form.packages = packages;
          $timeout(function () {
            $('.select-membership-packages').select2();
          }, 0);
        });
        scope.editing = true;
        $elem.modal('show');
      };

      scope.togglePlanEdit = function (ev, index, editValue) {
        ev.preventDefault(); // // Remove unsaved plans from the list if the edit is being cancelled.

        if (!editValue) {
          scope.form.plans = scope.form.plans.filter(function (plan) {
            return typeof plan.stripeplanid !== 'undefined';
          });
          return;
        }

        scope.form.plans[index].editing = editValue;
      };

      scope.$watch('servicesList', function () {
        if (scope.servicesList) {
          scope.services = JSON.parse(scope.servicesList);
          $timeout(function () {
            $('.select-membership-services').select2();
            $('.select-membership-services').on('change', function (ev) {
              scope.form.services = $(this).val();
            });
          }, 0);
        }
      });
      scope.$watch('packagesList', function () {
        if (scope.packagesList) {
          scope.packages = JSON.parse(scope.packagesList);
          scope.packageNoExpiry = !!scope.packages.find(function (testPackage) {
            return !testPackage.expirytype || testPackage.expirytype === 'none';
          });
          $timeout(function () {
            $('.select-membership-packages').select2();
            $('.select-membership-packages').on('change', function (ev) {
              scope.form.packages = $(this).val();
            });
          }, 0);
        }
      });
    }
  };
}