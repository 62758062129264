(function () {
  throbber.$inject = ["$rootScope", "$timeout"];
  angular.module('directive.throbber', []).directive('throbber', throbber);

  function throbber($rootScope, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'throbber.html',
      link: function link(scope, elem, attrs) {
        scope.throbberMessage = "";
        $rootScope.throbber = {};

        $rootScope.throbber.show = function () {
          scope.showThrobber = true;
          scope.loading = true;
        };

        $rootScope.throbber.hide = function (message) {
          scope.loading = false;

          if (message) {
            scope.throbberMessage = message;
            $timeout(function () {
              scope.showThrobber = false;
              scope.throbberMessage = '';
            }, 3000);
          } else {
            scope.showThrobber = false;
          }
        };

        $rootScope.throbber.showError = function (errorMessage) {
          scope.showThrobber = false;
          scope.showError = true;
          scope.throbberMessage = errorMessage;
        };

        scope.hideError = function () {
          scope.showError = false;
          scope.throbberMessage = '';
        };
      }
    };
  }
})();