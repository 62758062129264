(function () {
  viewWaitlist.$inject = ["ADMIN_URL", "API_URL", "$http", "$q", "$rootScope", "$location"];
  angular.module('userApp').directive('viewWaitlist', viewWaitlist);

  function viewWaitlist(ADMIN_URL, API_URL, $http, $q, $rootScope, $location) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/view-waitlist.html',
      replace: true,
      scope: {
        showWaitlist: '=',
        updateParent: '=',
        controllerScope: '='
      },
      link: function link(scope, elem, attrs) {
        scope.selectedAppts = [];
        scope.listScope = scope;
        scope.settings = {
          actionAll: true,
          sendcancellationmeails: true
        };
        $('.appointment-row').removeClass('highlighted');

        scope.showWaitlist = function (timeslot, shift) {
          scope.shift = shift;
          scope.timeslot = timeslot;
          scope.calendarSettings = $rootScope.calendarSettings;
          scope.waitlist = timeslot.waitlist;
          scope.appointments = timeslot.waitlist.waitlistcustomers;
          scope.selectedAppts = [];
          scope.viewingAppointment = false;
          scope.showSavebutton = false;
          scope.showEditButton = false;
          $('.appointment-row').removeClass('highlighted');
          $('#waitlist-modal').modal('show');
        };

        scope.toggleSelection = function (appointment) {
          var found = false,
              index = 0;

          for (var i = 0; i < scope.selectedAppts.length; i++) {
            if (scope.selectedAppts[i]._id === appointment._id) {
              index = i;
              found = true;
              break;
            }
          }

          if (!found) {
            scope.selectedAppts.push(appointment);
            $('.appt-row-' + appointment._id).addClass('highlighted');
          } else {
            scope.selectedAppts.splice(index, 1);
            $('.appt-row-' + appointment._id).removeClass('highlighted');
          }
        };

        scope.removeSelected = function () {
          if (!confirm('Please confirm you would like to remove these reservations from the waitlist')) {
            return;
          }

          var promises = [];
          scope.selectedAppts.forEach(function (appointment) {
            promises.push($http.delete("".concat(API_URL, "/api/v1/waitlist/").concat(scope.waitlist._id, "/").concat(appointment.customerid)));
          });
          $q.all(promises).then(function (results) {
            // Remove selected appointments from the appointments array
            scope.appointments = scope.appointments.filter(function (appointment) {
              for (var i = 0; i < scope.selectedAppts.length; i++) {
                if (scope.selectedAppts[i]._id === appointment._id) return true;
              }

              return false;
            });
            scope.selectedAppts = [];
            scope.reservedAppointment = false;
            $('.view-waitlist-modal').modal('hide');
            scope.updateParent();
          }).catch(function (error) {
            console.error(error);
            $rootScope.throbber.showError("Sorry but an error occurred: ".concat(error.data, ". Please contact support@smoothbook.co if this problem persists"));
            $('.view-appointments-modal').modal('hide');
            scope.updateParent();
          });
        };

        scope.viewCustomer = function (customerID) {
          $('.view-appointments-modal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $location.path('/customer/' + customerID);
        };
      }
    };
  }
})();