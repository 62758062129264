import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
EditWorkshopController.$inject = ["$scope", "$q", "$http", "API_URL", "$routeParams", "$location", "$rootScope", "$timeout"];
angular.module('userApp').controller('EditWorkshopController', EditWorkshopController);

function EditWorkshopController($scope, $q, $http, API_URL, $routeParams, $location, $rootScope, $timeout) {
  var work = this;
  var throbber = $rootScope.throbber;
  work.workshopID = $routeParams.workshopid;
  work.workshopDates = [];
  work.workshopSettings = {};

  var init = function init() {
    var user = $rootScope.userInfo,
        orgID = user.orgid;
    work.userIsResource = user.roles[0].type === 'RESOURCE';
    work.editingWorkshop = true;
    work.hideAddSchedule = false;
    work.services = [];
    work.resources = [];
    var promises = [$http.get("".concat(API_URL, "/api/v1/shift/workshop/").concat(work.workshopID)), $http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/services/' + orgID)];
    $scope.throbber.show();
    $q.all(promises).then(function (results) {
      if (!results[0].data.workshop) {
        return throbber.showError('Workshop shift not found');
      }

      var _results = _slicedToArray(results, 3),
          shift = _results[0],
          resourceData = _results[1],
          serviceData = _results[2];

      var _shift$data = shift.data,
          workshop = _shift$data.workshop,
          shiftSuccess = _shift$data.success;
      var _resourceData$data = resourceData.data,
          resources = _resourceData$data.resources,
          resourceSuccess = _resourceData$data.success;
      var _serviceData$data = serviceData.data,
          services = _serviceData$data.services,
          serviceSuccess = _serviceData$data.success;
      work.workshopName = workshop.serviceid.name;
      if (!shiftSuccess || !resourceSuccess || !serviceSuccess) return $scope.throbber.showError('Sorry but an error occurred on this page. Please contact support@smoothbook.co if this problem re-occurs');
      work.workshopID = workshop._id;
      work.workshopSettings = _objectSpread({}, workshop, {
        valid: true
      }); // Assume that workshop settings are valid until they're changed.

      work.workshopSettings.serviceid = workshop.serviceid._id;
      work.workshopSettings.resourceid = workshop.resourceid ? workshop.resourceid._id : null;
      work.workshopSettings.hasresource = !!work.workshopSettings.resourceid;
      work.workshopDates = workshop.dates;
      work.resources = resources;

      if (resources && resources.length && work.workshopSettings.resourceid) {
        work.currentResouce = work.resources.find(function (resource) {
          return resource._id === work.workshopSettings.resourceid;
        });
      }

      work.services = services;
      work.currentService = work.services.find(function (service) {
        return service._id === work.workshopSettings.serviceid;
      });
      $scope.throbber.hide();
    }, function (error) {
      $scope.throbber.showError('Sorry but an error occurred. Please contact support@smoothbook.co if this problem re-occurs');
    });
  }; // End init


  init();

  work.updateWorkshopShift = function () {
    var newShift = {
      type: 'WORKSHOP',
      serviceid: work.workshopSettings.serviceid,
      workshopstart: work.workshopSettings.workshopstart,
      workshopend: work.workshopSettings.workshopend,
      resourceid: work.workshopSettings.resourceid && work.workshopSettings.resourceid !== '0' ? work.workshopSettings.resourceid : null,
      hasresource: !!(work.workshopSettings.resourceid && work.workshopSettings.resourceid !== '0'),
      openings: work.workshopSettings.openings,
      bookmulti: work.workshopSettings.bookmulti,
      bookremaining: work.workshopSettings.bookremaining,
      bookall: work.workshopSettings.bookall,
      joinmidsession: work.workshopSettings.joinmidsession,
      dates: work.workshopDates
    };
    throbber.show();
    $http.put("".concat(API_URL, "/api/v1/shift/workshop/").concat(work.workshopID), {
      newshift: newShift
    }).then(function (response) {
      var data = response.data;

      if (!data.success && data.conflictdate) {
        return throbber.showError("Sorry but this action creates a shift that conflicts with an existing shift on ".concat(result.data.conflictdate));
      }

      if (!data.success) {
        return throbber.showError("Sorry but an error occurred: ".concat(data.msg));
      }

      throbber.hide('Workshop shift updated');
    }).catch(function (error) {
      console.error(error);
      throbber.showError('Sorry but an error occurred. Please contact support@smoothbook.co if this problem re-occurs');
    });
  };

  work.dateChosen = function (dates) {
    work.workshopDates = dates;

    if (!work.workshopSettings.valid || !work.workshopDates.length) {
      return;
    }

    work.updateWorkshopShift();
  };

  work.workshopSettingsUpdated = function (settings) {
    work.workshopSettings = settings;

    if (!work.workshopSettings.valid || !work.workshopDates.length) {
      return;
    }

    work.updateWorkshopShift();
  };

  work.changeResource = function (resourceID) {
    if (!resourceID) {
      return;
    }

    work.resourceID = resourceID;
    work.getShifts();
  };

  work.getShifts = function () {
    var params = {
      isworkshop: true
    };

    if (work.resourceID) {
      params.resourceid = work.resourceID;
    } else {
      params.hasresource = false;
    }

    $scope.throbber.show();
    $http.get(API_URL + '/api/v1/shift', {
      params: params
    }).then(function (reply) {
      $scope.throbber.hide();
      var response = reply.data;

      if (!response.success) {
        $scope.throbber.showError('Sorry but there was an error with this operation: ' + response.msg);
        return;
      }

      work.shifts = response.shifts;
    });
  };

  work.toggleAddShift = function () {
    $('.add-schedule-container').slideToggle();
    work.addingShift = !work.addingShift;
  };

  work.serviceAdded = function (service) {
    work.services.push(service);
    work.updateChosenService();
  };
}