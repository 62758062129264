disableDiv.$inject = ["$rootScope", "jwtHelper"];
angular.module('userApp').directive('disableDiv', disableDiv); // https://stackoverflow.com/questions/25561144/disabling-an-entire-div-in-angularjs/38011761

function disableDiv($rootScope, jwtHelper) {
  return {
    link: function link(scope, element) {
      // Get user info from the token and store globally.
      var token = localStorage.getItem('jwt-token'),
          payload = jwtHelper.decodeToken(token); // Disable functionality in child components of the

      var premiumSubRequired = window.smooth.charging && payload.subscription && payload.subscription.id === 'BASIC';
      if (!premiumSubRequired) return;
      scope.$watch(function () {
        var buttons = angular.element('button', element);
        if (buttons.length) buttons.attr('disabled', 'disabled');
        var inputs = angular.element('input', element);
        if (inputs.length) inputs.attr('disabled', 'disabled');
        var selects = angular.element('select', element);
        if (selects.length) selects.attr('disabled', 'disabled');
      });
    }
  };
}