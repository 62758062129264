(function () {
  customerViewer.$inject = ["ADMIN_URL", "API_URL", "$http", "$rootScope", "$routeParams"];
  angular.module('userApp').directive('customerViewer', customerViewer);

  function customerViewer(ADMIN_URL, API_URL, $http, $rootScope, $routeParams) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/customer/customer-viewer.html',
      scope: {
        customer: '=',
        regoForm: '='
      },
      link: function link(scope, elem, attrs) {
        var customerID = $routeParams.customerid;
        scope.viewing = true;

        scope.saveRego = function () {
          scope.viewing = true;
          $rootScope.throbber.show();
          $http.put(API_URL + '/api/v1/customer/' + customerID, {
            customer: scope.customer
          }).then(function (reply) {
            $rootScope.throbber.hide();
            var response = reply.data;

            if (!response.success) {
              $rootScope.throbber.showError('Sorry but an error occurred: ' + response.msg);
              return;
            }

            scope.customer = response.customer;
          });
        };

        scope.editCustomer = function () {
          scope.viewing = false;
        };

        scope.cancelEdit = function () {
          scope.viewing = true;
        };
      }
    };
  }
})();