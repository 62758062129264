listWorkshops.$inject = ["ADMIN_URL", "$rootScope"];
import './list-workshops.less';
angular.module('userApp').directive('listWorkshops', listWorkshops);

function listWorkshops(ADMIN_URL, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: ADMIN_URL + '/templates/directives/scheduling/workshops/list-workshops.html',
    replace: true,
    scope: {
      workshops: '='
    },
    link: function link(scope, elem, attrs) {
      scope.editDays = function (workshopID) {
        console.log("editing ".concat(workshopID));
      };
    }
  };
}