angular.module('directive.tooltip', []).directive('tooltip', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      title: '@',
      placement: '@'
    },
    template: '<span data-toggle="tooltip" class="glyphicon glyphicon-question-sign"></span>',
    link: function link(scope, elem, attrs) {
      $(elem).tooltip({
        'title': scope.title,
        'placement': scope.placement || 'auto'
      });
    }
  };
}); // End directive