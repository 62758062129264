import _objectSpread from "@babel/runtime/helpers/objectSpread";
CalendarSettingsController.$inject = ["BASE_URL", "API_URL", "$http", "$rootScope", "$scope", "$timeout"];

require('bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css');

require('bootstrap-colorpicker');

angular.module('userApp').controller('CalendarSettingsController', CalendarSettingsController);
var codeMirror = null;

var doCodeMirror = function doCodeMirror() {
  var initialValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  require(["codemirror/lib/codemirror", "codemirror/lib/codemirror.css", "codemirror/theme/monokai.css", "codemirror/mode/javascript/javascript", "codemirror/mode/css/css", "codemirror/addon/edit/closebrackets", "codemirror/addon/hint/css-hint", "codemirror/addon/hint/show-hint", "codemirror/addon/hint/show-hint.css"], function (CodeMirror) {
    var textArea = document.getElementById("css-editor-2");
    if (!textArea) return;
    var cm = CodeMirror.fromTextArea(textArea, {
      lineNumbers: true,
      mode: "css",
      theme: 'monokai',
      autoCloseBrackets: true,
      lint: true,
      hint: true,
      autohint: true,
      gutters: ['error']
    });
    initialValue && cm.setValue(initialValue);
    codeMirror = cm;
  });
};

function CalendarSettingsController(BASE_URL, API_URL, $http, $rootScope, $scope, $timeout) {
  var cal = this,
      _settingsID;

  cal.orgID = $rootScope.userInfo.orgid;
  cal.base_url = smooth.base_url;
  cal.calendar_url = smooth.calendar_url;

  var init = function init() {
    $rootScope.throbber.show();
    cal.summernoteOptions = {
      height: 200,
      toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'clear']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['insert', ['link']], ['view', ['fullscreen', 'codeview']], ['help', ['help']]]
    };
    cal.slugExists = false;
    $http.get(BASE_URL + '/api/v1/settings/calendar').then(function (reply) {
      $rootScope.throbber.hide();
      var response = reply.data;

      if (!response.success) {
        $rootScope.throbber.showError('Sorry but there was a problem with this request: ' + response.msg);
        return;
      } // Set up the dropdown for how many future days they can choose


      cal.futureDays = [];

      for (var i = 0; i < 20; i++) {
        cal.futureDays.push(i);
      }

      cal.calendarDays = [];

      for (var j = 1; j <= 365; j++) {
        cal.calendarDays.push(j);
      }

      cal.form = response.calendarsettings;
      cal.tsandcsinit = cal.form.tsandcs;
      _settingsID = cal.form._id;
      cal.bookFutureDays = cal.form.bookfuturedays > 0;
      cal.colours = cal.form.colours;
      cal.logo = cal.form.logo;
      cal.form.logowidth = typeof cal.form.logowidth !== 'undefined' ? cal.form.logowidth : 0;
      cal.form.furthestdaystarthour = cal.form.furthestdaystarthour ? cal.form.furthestdaystarthour : '00';
      cal.form.furthestdaystartminute = cal.form.furthestdaystartminute ? cal.form.furthestdaystartminute : '00';
      doCodeMirror(cal.form.customcss);
      $timeout(function () {
        $('#color-picker-background').colorpicker({
          color: cal.colours.background
        });
        $('#color-picker-body-text').colorpicker({
          color: cal.colours.bodytext
        });
        $('#color-picker-primary').colorpicker({
          color: cal.colours.primary
        });
        $('#color-picker-text1').colorpicker({
          color: cal.colours.text1
        });
        $('#color-picker-text2').colorpicker({
          color: cal.colours.text2
        });
        $('#color-picker-success').colorpicker({
          color: cal.colours.success
        });
        $('#color-picker-info').colorpicker({
          color: cal.colours.info
        });
        $('#color-picker-danger').colorpicker({
          color: cal.colours.danger
        });
        $('#color-picker-warning').colorpicker({
          color: cal.colours.warning
        });
        $('#color-picker-highlight').colorpicker({
          color: cal.colours.highlight
        });
      }, 0);
      closestToDays();
    });
  };

  init();

  function closestToDays() {
    cal.closestcanbookdays = Math.floor(cal.form.closestcanbook / 24);
    cal.closestcanbookhours = Math.floor(cal.form.closestcanbook % 24);
    cal.furthestcanbookdays = Math.floor(cal.form.furthestcanbook / 24);
    cal.furthestcanbookhours = Math.floor(cal.form.furthestcanbook % 24);
  }

  function closestFromDays() {
    cal.form.closestcanbook = cal.closestcanbookdays * 24 + cal.closestcanbookhours;
    cal.form.furthestcanbook = cal.furthestcanbookdays * 24 + cal.furthestcanbookhours;
  }

  function validate() {
    if (cal.form.furthestcanbook < cal.form.closestcanbook + 1) {
      cal.furthestValidationError = true;
      $timeout(function () {
        cal.furthestValidationError = false;
      }, 3000);
      cal.form.furthestcanbook = cal.form.closestcanbook + 1;
      closestToDays();
      return false;
    }

    return true;
  }

  cal.checkSlug = function () {
    cal.checkingSlug = true;
    cal.slugExists = false; // 24 character names will be confused with orgids. Add a character at the end.

    if (cal.form.slug.length === 24) {
      cal.form.slug = cal.form.slug + '-';
    }

    cal.form.slug = encodeURI(cal.form.slug.toLowerCase());
    $http.get(API_URL + '/api/v1/settings/calendar/slug', {
      params: {
        slug: cal.form.slug
      }
    }).then(function (reply) {
      cal.checkingSlug = false;
      var response = reply.data;

      if (!response.success) {
        throbber.showError();
        return;
      }

      if (response.slugExists) {
        cal.slugExists = true;
      }
    });
  };

  var updatePickers = function updatePickers() {
    console.log(cal.colours);
    $('#color-picker-background').colorpicker('setValue', cal.colours.background);
    $('#color-picker-primary').colorpicker('setValue', cal.colours.primary);
    $('#color-picker-default').colorpicker('setValue', cal.colours.default);
    $('#color-picker-body-text').colorpicker('setValue', cal.colours.bodytext);
    $('#color-picker-text1').colorpicker('setValue', cal.colours.text1);
    $('#color-picker-text2').colorpicker('setValue', cal.colours.text2);
    $('#color-picker-success').colorpicker('setValue', cal.colours.success);
    $('#color-picker-info').colorpicker('setValue', cal.colours.info);
    $('#color-picker-danger').colorpicker('setValue', cal.colours.danger);
    $('#color-picker-warning').colorpicker('setValue', cal.colours.warning);
    $('#color-picker-highlight').colorpicker('setValue', cal.colours.highlight);
  };

  cal.chooseTheme = function (theme) {
    cal.colours = _objectSpread({}, theme.colours);
    updatePickers();
  };

  cal.saveSettings = function () {
    $rootScope.throbber.show();
    closestFromDays();

    if (!validate()) {
      $rootScope.throbber.hide();
      return;
    }

    cal.form.colours = cal.colours;
    var css = codeMirror && codeMirror.getValue();

    if (css && css.length > 2000) {
      cal.cssLengthError = true;
      $timeout(function () {
        cal.cssLengthError = false;
      }, 3000);
    }

    cal.form.customcss = css;
    $http.put(API_URL + '/api/v1/settings/calendar/' + _settingsID, {
      settings: cal.form
    }).then(function (reply) {
      var response = reply.data;

      if (!response.success) {
        $rootScope.throbber.showError('Sorry but there was an error with this request: ' + response.msg);
        return;
      }

      $rootScope.throbber.hide('Settings updated');
      cal.form = response.calendarsettings;
      if (cal.form.customcss) codeMirror && codeMirror.setValue(cal.form.customcss);
      closestToDays();
    }, function (error) {
      $rootScope.throbber.showError('Sorry but there was an error with this request: ' + error.errorMessage);
    });
  };

  cal.updateBookFutureDays = function () {
    cal.form.bookfuturedays = cal.bookFutureDays ? 10 : 0;
  };
}