(function () {
  angular.module('filter.currencyFilter', []).filter('currencyFilter', currencyFilter);

  function currencyFilter() {
    // All amounts must be stored as integers
    return function (amount, currency) {
      if (!parseInt(amount)) {
        return '';
      }

      amount = amount.toFixed(currency.decimal_digits);
      return currency.symbol_native + ' ' + amount;
    };
  }
})();