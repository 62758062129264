(function () {
  AddService.$inject = ["ADMIN_URL", "API_URL", "$http", "$location"];
  angular.module('userApp').directive('addService', AddService);

  function AddService(ADMIN_URL, API_URL, $http, $location) {
    return {
      restrict: 'EA',
      templateUrl: ADMIN_URL + '/templates/directives/add-service.html',
      replace: true,
      scope: {
        services: '=',
        showModal: '=',
        updateParent: '='
      },
      link: function link(scope, elem, attrs) {
        scope.form = {};
        scope.listServices = true;
        scope.tooManyServices = false;

        scope.toggleDisplay = function (listServices) {
          scope.listServices = listServices;
        };

        scope.saveService = function () {
          $http.post(API_URL + '/api/v1/service', {
            newservice: scope.form
          }).then(function (response) {
            response = response.data;
            if (response.toomanyservices) return scope.tooManyServices = true;

            if (response.success) {
              $('#add-service-modal', elem).modal('hide');
              scope.updateParent(response.service);
            }
          }, function (response) {
            console.log(response);
          });
        }; // End saveService


        scope.changeLocation = function (path) {
          $('#add-service-modal', elem).modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $location.path(path);
        };

        scope.showModal = function () {
          $('#add-service-modal', elem).modal('show');
        };
      }
    };
  }
})();