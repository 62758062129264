ngSummernote.$inject = ["$rootScope"];
angular.module('directive.ngSummernote', []).directive('ngSummernote', ngSummernote);

function ngSummernote($rootScope) {
  return {
    restrict: 'EA',
    replace: true,
    scope: {
      options: '=',
      model: '=',
      initText: '='
    },
    transclude: 'element',
    template: '<div class="ng-summernote"></div>',
    link: function link(scope, elem, attrs) {
      var defaultOptions = {
        height: 200,
        toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'clear']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['insert', ['link']], ['view', ['fullscreen', 'codeview']], ['help', ['help']]]
      };
      var callbacks = {
        onChange: function onChange(contents, $editable) {
          scope.model = contents;
          $rootScope.safeApply();
        }
      }; // Update initial text if this has been supplied asynchronously

      scope.$watch('initText', function () {
        if (scope.initText) {
          $(elem).summernote('code', scope.initText);
        }
      });
      var summerOptions = Object.assign({}, defaultOptions, scope.options),
          summerConfig = Object.assign({}, {
        callbacks: callbacks
      }, summerOptions, {
        code: scope.model
      });
      $(elem).summernote(summerConfig);
    }
  };
}