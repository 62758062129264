angular.module('authApp', ['ngRoute', 'angular-jwt', 'ngCookies', 'MainController']).config(["$routeProvider", function ($routeProvider) {
  $routeProvider // Auth 
  .when('/register', {
    templateUrl: '/templates/auth/register.html',
    controller: 'RegisterController',
    controllerAs: 'reg'
  }).when('/login', {
    templateUrl: '/templates/auth/login.html',
    controller: 'LoginController',
    controllerAs: 'log'
  }).otherwise({
    redirectTo: '/login'
  });
}]).constant('BASE_URL', smooth.base_url) // Monitor all requests and responses, check for and assign JWT token.
.factory('AuthIntercept', ["$window", "$rootScope", "jwtHelper", "$cookies", function ($window, $rootScope, jwtHelper, $cookies) {
  var authInterceptor = {
    request: function request(req) {
      var token = localStorage.getItem('jwt-token'); // Append token to header if it exists. Currently occurs on
      // every request.

      if (token) {
        req.headers['x-access-token'] = token; // Get user info from the token and store globally.

        var payload = jwtHelper.decodeToken(token);
        $rootScope.userInfo = payload.user;
      }

      return req;
    },
    response: function response(res) {
      // Token sent, store it in header for API and as cookie
      // for page requests.
      if (res.data.success && res.data.token) {
        localStorage.setItem('jwt-token', res.data.token);
        $cookies.put('x-access-token', res.data.token);
      }

      return res;
    },
    responseError: function responseError(rejection) {
      console.error(rejection);
      return rejection;
    }
  };
  return authInterceptor;
}]).config(["$httpProvider", function ($httpProvider) {
  $httpProvider.interceptors.push("AuthIntercept");
}]);