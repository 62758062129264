(function () {
  ListSchedulesController.$inject = ["$scope", "$q", "$http", "API_URL", "$location"];
  angular.module('userApp').controller('ListSchedulesController', ListSchedulesController); // *** This page only makes sense if the org has resources. If they don't go straight to the schedules

  function ListSchedulesController($scope, $q, $http, API_URL, $location) {
    var shed = this;

    shed.init = function () {
      // TODO: Determine what shift types are available and display buttons accordingly
      shed.shiftType = 'weekly'; // Get a list of resources and services

      var promises = [$http.get(API_URL + '/api/v1/resource'), $http.get(API_URL + '/api/v1/service'), $http.get(API_URL + '/api/v1/shift')];
      $scope.throbber.show();
      $q.all(promises).then(function (responses) {
        var error = ''; // Check if there are any resources. If not, go straight to scheduling page.

        var resourceReply = responses[0].data;

        if (!resourceReply.resources || !resourceReply.resources.length) {
          $location.path('/scheduling/weekly');
          return;
        }

        responses.forEach(function (reply) {
          var response = reply.data;

          if (!response.success) {
            error = response.msg;
          } else {
            if (response.resources) {
              shed.resources = response.resources;
            }

            if (response.services) {
              shed.services = response.services;
            }
          }
        }); // End forEach

        if (error) {
          $scope.throbber.showError(error);
        } else {
          $scope.throbber.hide();
        }
      });
    };

    shed.init();
  }
})();