(function () {
  paginationWidget.$inject = ["ADMIN_URL"];
  angular.module('userApp').directive('paginationWidget', paginationWidget);

  function paginationWidget(ADMIN_URL) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: ADMIN_URL + '/templates/directives/pagination-widget.html',
      scope: {
        paginationObject: '=',
        changePage: '=',
        widgetSize: '@?'
      },
      link: function link(scope, elem, attrs) {
        scope.$watch('paginationObject', function () {
          scope.currentPage = scope.paginationObject.page;
          var pages = Math.ceil(scope.paginationObject.count / scope.paginationObject.limit);
          scope.pages = [];

          for (var i = 1; i <= pages; i++) {
            scope.pages.push(i);
          }
        });

        scope.next = function () {
          if (scope.currentPage + 1 >= scope.pages.length) {
            return;
          }

          scope.changePage(scope.currentPage + 1);
        };

        scope.previous = function () {
          if (scope.currentPage == 0) {
            return;
          }

          scope.changePage(scope.currentPage - 1);
        };
      }
    };
  }
})();